import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  Stack,
  Chip,
  Divider,
  CircularProgress,
  Alert,
  useTheme,
  Grid,
  Pagination,
} from "@mui/material";
import {
  AccountBalance,
  Warning,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import dayjs from "dayjs";

const LoanCard = ({ loan }) => {
  const theme = useTheme();
  const isOverdue = loan.overdueDays > 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        bgcolor: isOverdue ? theme.palette.error.lighter : "background.paper",
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <AccountBalance sx={{ color: "success.main" }} />
            <Typography variant="subtitle1" color="success.main" fontWeight="bold">
              KES{" "}
              {(loan.paymentAmount - 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
          <Chip
            size="small"
            label={loan.status}
            color={
              loan.status === "Approved"
                ? "success"
                : loan.status === "Pending"
                ? "warning"
                : loan.status === "Rejected"
                ? "error"
                : loan.status === "Paid"
                ? "info"
                : "default"
            }
          />
        </Stack>

        <Stack
          direction="row"
          wrap="wrap"
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              Principal
            </Typography>
            <Typography variant="body2">
              KES{" "}
              {(loan.amount - 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Interest
            </Typography>
            <Typography variant="body2">
              KES{" "}
              {(loan.interest - 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Term
            </Typography>
            <Typography variant="body2">{loan.term} days</Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Applied
            </Typography>
            <Typography variant="body2">
              {dayjs(loan.createdAt).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Due Date
            </Typography>
            <Typography variant="body2">
              {dayjs(loan.repaymentDate).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Overdue
            </Typography>
            <Typography
              variant="body2"
              color={isOverdue ? "error.main" : "success.main"}
            >
              {loan.overdueDays} days
            </Typography>
          </Box>
        </Stack>

        {loan.status === "Rejected" && loan.rejectionReason && (
          <>
            <Divider />
            <Box>
              <Typography variant="caption" color="error" gutterBottom>
                Rejection Reason:
              </Typography>
              <Typography variant="body2" color="error.main">
                {loan.rejectionReason}
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    </Card>
  );
};

const Loans = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [loans, setLoans] = useState([]);
  const [error, setError] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  }); 
  const [totalLoans, setTotalLoans] = useState(0);

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await fetch(
          `/api/loans?userId=${user.id}&offset=${paginationModel.page * paginationModel.pageSize}&limit=${paginationModel.pageSize}`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch loans");
        }

        const data = await response.json();
        setLoans(data.data || []);
        setTotalLoans(data.total || 0);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchLoans();
    }
  }, [user, paginationModel]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom mb={3}>
        Loan History
      </Typography>
      <Grid container spacing={2}>
        {loans.length === 0 ? (
          <Grid item xs={12}>
            <Alert severity="info">No loan history found</Alert>
          </Grid>
        ) : (
          loans.map((loan) => (
            <Grid item xs={12} md={12} key={loan.id}>
              <LoanCard loan={loan} />
            </Grid>
          ))
        )}
      </Grid> 
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(totalLoans / paginationModel.pageSize)}
          page={paginationModel.page + 1}
          onChange={(event, value) =>
            setPaginationModel({ ...paginationModel, page: value - 1 })
          }
        />
      </Box>
    </Box>
  );
};

export default Loans;
