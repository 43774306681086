import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardContent,
  Stack,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  TrendingUp,
  Person,
  Assessment,
  DateRange,
  Download,
} from "@mui/icons-material";

import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const Performance = () => {
  const [topAffiliates, setTopAffiliates] = useState([]);
  const [stats, setStats] = useState({
    totalReferrals: 0,
    conversionRate: 0,
    totalCommission: 0,
    activeAffiliates: 0,
    referralDistribution: [],
    referralType: [],
  });
  const [loading, setLoading] = useState({
    stats: false,
    trends: false,
    topAffiliates: false,
  });
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const columns = [
    { field: "name", headerName: "Affiliate Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    {
      field: "totalCommission",
      headerName: "Total Commission (KSH)",
      flex: 1,
      renderCell: (params) => {
        return parseFloat(params.value).toLocaleString();
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return params.value === "active" ? (
          <Chip label={params.value} color="success" />
        ) : (
          <Chip label={params.value} color="error" />
        );
      },
    },
  ];

  const fetchStats = async () => {
    setLoading((prev) => ({ ...prev, stats: true }));
    try {
      const response = await fetch("/api/affiliates/performance-metrics");
      const data = await response.json();
      if (response.ok) {
        setStats({
          totalReferrals: data.referrals || 0,
          conversionRate: (data.activeReferrals / data.referrals) * 100 || 0,
          totalCommission: data.totalCommission || 0,
          activeAffiliates: data.affiliates || 0,
          referralDistribution: data.referralDistribution || 0,
          referralType: data.referralType || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
    } finally {
      setLoading((prev) => ({ ...prev, stats: false }));
    }
  };

  const fetchTopAffiliates = async () => {
    setLoading((prev) => ({ ...prev, topAffiliates: true }));
    try {
      const response = await fetch("/api/affiliates/top-affiliates");
      const data = await response.json();
      if (response.ok) {
        const rows = data.data.map((item) => ({
          id: item.affiliate.AffiliateID,
          name: item.affiliate.Name,
          email: item.affiliate.Email,
          phone: item.affiliate.Phone,
          totalCommission: item.totalCommission,
          status: item.affiliate.Status,
          totalReferrals: item.affiliate.TotalReferrals,
        }));
        setTopAffiliates(rows);
      }
    } catch (error) {
      console.error("Error fetching top affiliates:", error);
    } finally {
      setLoading((prev) => ({ ...prev, topAffiliates: false }));
    }
  };

  useEffect(() => {
    fetchStats();
    fetchTopAffiliates();
  }, []);

  const StatCard = ({ title, value, icon, color }) => (
    <Card>
      <CardContent sx={{ px: 0, py: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{ color, p: 1, borderRadius: 1, bgcolor: `${color}.lighter` }}
          >
            {icon}
          </Box>
          <Box>
            <Typography color="textSecondary" variant="body2" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h6">
              {typeof value === "number" && title.includes("KSH")
                ? `KSH ${(value - 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}`
                : value.toLocaleString() + (title.includes("Rate") ? "%" : "")}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box mt={9}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5">Performance Analytics</Typography>
      </Stack>

      {/* Stats Cards */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Referrals"
            value={stats.totalReferrals}
            icon={<Person />}
            color="#0088FE"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Conversion Rate"
            value={stats.conversionRate}
            icon={<TrendingUp />}
            color="#00C49F"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Commission (KSH)"
            value={stats.totalCommission}
            icon={<Assessment />}
            color="#FFBB28"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Affiliates"
            value={stats.activeAffiliates}
            icon={<Person />}
            color="#00C49F"
          />
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="title" gutterBottom>
              Referral Distribution
            </Typography>
            <Box height={300}>
              {loading.trends ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={stats.referralDistribution}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <ChartTooltip />
                    <Legend />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      name="Referrals"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="title" gutterBottom>
              Referral Type
            </Typography>
            <Box height={300}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={stats.referralType}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {stats.referralType.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <ChartTooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Top Performing Affiliates Table */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="title" gutterBottom>
          Top Performing Affiliates
        </Typography>
        <Box>
          {loading.topAffiliates ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={topAffiliates}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              autoHeight
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Performance;
