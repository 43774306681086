import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Stack,
  Card,
  useTheme,
  Button,
  Divider,
  Switch,
} from "@mui/material";
import { CheckCircle, Block } from "@mui/icons-material";

const ScoreItem = ({ label, value, isPercentage = true }) => (
  <Stack spacing={0.5}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body2" fontWeight="medium">
      {isPercentage ? `${value}%` : value}
    </Typography>
  </Stack>
);

export default function CreditScore({
  userId,
  loanId,
  onUpdateStatus,
  loading: statusLoading,
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [creditScore, setCreditScore] = useState(null);
  const [error, setError] = useState(null);

  const fetchCreditScore = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/creditscores/${userId}`, {
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to fetch credit score");
      const data = await response.json();
      setCreditScore(data);
    } catch (error) {
      setError("Could not load credit score");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchCreditScore();
    }
  }, [userId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error || !creditScore) {
    return (
      <Box p={2} textAlign="center">
        <Typography color="text.secondary">
          {error || "No credit score available"}
        </Typography>
      </Box>
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
      }}
    >
      <Stack spacing={3}>
        <Stack
          direction="row"
          spacing={2}
          divider={
            <Box
              sx={{
                borderLeft: "1px solid",
                borderColor: "divider",
              }}
            />
          }
        >
          <ScoreItem
            label="Total Score"
            value={creditScore.score.toFixed(0) || 0}
          />
          <ScoreItem
            label="Repayment History"
            value={creditScore.repaymentHistoryScore.toFixed(0) || 0}
          />
          <ScoreItem
            label="Overdue Score"
            value={creditScore.overdueScore.toFixed(0) || 0}
          />
          <ScoreItem
            label="Default Score"
            value={creditScore.defaultScore.toFixed(0) || 0}
          />{" "}
          <ScoreItem
            label="On-Time Repayment"
            value={creditScore.onTimeRepaymentScore.toFixed(0) || 0}
          />
          <ScoreItem
            label="Agent Feedback"
            value={creditScore.agentFeedbackScore.toFixed(0) || 0}
          />
          <ScoreItem
            label="Income Score"
            value={creditScore.incomeScore.toFixed(0) || 0}
          />
        </Stack>

        <Divider />

        <Stack direction="row" spacing={2} alignItems="center">
          <ScoreItem
            label="Credit Limit"
            value={creditScore.creditLimit?.toLocaleString() || "Not set"}
            isPercentage={false}
          />
          <ScoreItem
            label="Manual Limit"
            value={creditScore.manualCreditLimit?.toLocaleString() || "Not set"}
            isPercentage={false}
          />
        </Stack>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Manual Override
          </Typography>
          <Typography
            variant="body2"
            color={creditScore.manualOverride ? "success.main" : "error.main"}
            fontWeight="medium"
          >
            {creditScore.manualOverride ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Credit Rating
          </Typography>
          <Typography
            variant="h6"
            color={
              creditScore.score >= 90
                ? "success.main"
                : creditScore.score >= 80
                ? "warning.main"
                : "error.main"
            }
          >
            {creditScore.score >= 90
              ? "Excellent"
              : creditScore.score >= 80
              ? "Good"
              : creditScore.score >= 70
              ? "Fair"
              : "Poor"}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
