import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CircularProgress,
  IconButton,
  Divider,
  Chip,
} from "@mui/material";
import UserDetails from "./Tabs/UserDetails";
import Loans from "./Tabs/Loans";
import Payments from "./Tabs/Payments";
import Support from "./Tabs/Support";
import Activity from "./Tabs/Activities";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Application from "./Tabs/Application";

export default function LoanManagementPage(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loanData, setLoanData] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const loanId = window.location.pathname.split("/")[3];

  const fetchLoanData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `/api/loans?id=${loanId}&includeFollowUps=true&includePayments=true`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setLoanData(data?.data[0]);
        fetchUserDetails(data?.data[0].userId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await fetch(
        `/api/users?id=${userId}&includeLoans=true&includeCreditScore=true&includePayments=true`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch user details");
      const data = await response.json();
      setUser(data?.data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanData();
  }, [loanId, refresh]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mt: 9,
        p: 3,
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        position: "relative",
      }}
      component={Card}
    >
      <Chip
        sx={{ position: "absolute", top: 0, right: 0 }}
        label={loanData?.status}
        color={
          loanData?.status === "pending"
            ? "warning"
            : loanData?.status === "approved"
            ? "success"
            : "error"
        }
      />
      <Box display="flex" gap={1}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
          sx={{
            display: "block",
            width: "fit-content",
            textTransform: "capitalize",
          }}
        >
          <Tab label="Details" />
          <Tab label="Application" />
          <Tab label="Past Loans" />
          <Tab label="Payments" />
          <Tab label="Support" />
          <Tab label="Activity" />

        </Tabs>
        <Box flexGrow={1}></Box>
        <IconButton
          onClick={() => {
            navigate("/rm/applications");
          }}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      <Divider sx={{ mt: -1 }} />

      <Box mt={2}>
        {selectedTab === 0 && (
          <UserDetails
            loanData={loanData}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        {selectedTab === 1 && (
          <Application
            loanData={loanData}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        {selectedTab === 2 && <Loans user={user} />}
        {selectedTab === 3 && <Payments user={user} />}
        {selectedTab === 4 && <Support user={user} />}
        {selectedTab === 5 && <Activity user={user} />}
      </Box>
    </Box>
  );
}
