import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home,
  People,
  Settings,
  Logout,
  ExpandLess,
  ExpandMore,
  Dashboard,
  Message,
  Commute,
  AccountCircle,
  EventNote,
  PeopleAlt,
  Euro,
  MoneyOff,
  CurrencyBitcoin,
  CreditCard,
  Person,
  NotificationAdd,
  Call,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, SwipeableDrawer, useMediaQuery } from "@mui/material";
import Header from "./Header/Header";
import { Gear } from "@phosphor-icons/react";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "100%",
  marginLeft: 0,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = (props) => {
  const { user } = props; // Expecting user role from props
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isMobile);

  const [openSections, setOpenSections] = useState({
    customerService: false,
    riskManagement: false,
    debtCollection: false,
    affiliateMarketing: false,
  });
  const [menuItems, setMenuItems] = useState([]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch(`/api/admin/logout`).then((res) => {
      navigate("/");
    });
  };

  // Define menu items based on user role
  const adminItems = [
    { text: "Home", icon: <Home />, path: "/home" },
    {
      text: "Risk Management",
      icon: <CurrencyBitcoin />,
      subItems: [
        { text: "Dashboard", path: "/rm/dashboard", icon: <Dashboard /> },
        { text: "Applications", path: "/rm/applications", icon: <EventNote /> },
        { text: "Credit Limit", path: "/rm/limits", icon: <CreditCard /> },
        { text: "Customers", path: "/rm/customers", icon: <AccountCircle /> },
        { text: "Users", path: "/rm/users", icon: <People /> },
      ],
    },
    {
      text: "Debt Collection",
      icon: <MoneyOff />,
      subItems: [
        { text: "Dashboard", path: "/dc/dashboard", icon: <Dashboard /> },
        { text: "Loans", path: "/dc/loans", icon: <Euro /> },
        { text: "Payments", path: "/dc/payments", icon: <CreditCard /> },
        { text: "Follow Up", path: "/dc/agents", icon: <Call /> },
        { text: "Customers", path: "/dc/customers", icon: <AccountCircle /> },
        props?.user?.Role == "Admin"
          ? {
              text: "Users",
              path: "/dc/users",
              icon: <People />,
            }
          : {},
      ],
    },
    {
      text: "Affiliate Marketing",
      icon: <PeopleAlt />,
      subItems: [
        {
          text: "Dashboard",
          path: "/affiliate/dashboard",
          icon: <Dashboard />,
        },
        { text: "Affiliates", path: "/affiliate/list", icon: <People /> },
        {
          text: "Performance",
          path: "/affiliate/performance",
          icon: <EventNote />,
        },
        { text: "Commissions", path: "/affiliate/commissions", icon: <Euro /> },
        { text: "Payments", path: "/affiliate/payments", icon: <Euro /> },
      ],
    },
    {
      text: "Customer Service",
      icon: <People />,
      subItems: [
        { text: "Dashboard", path: "/cs/dashboard", icon: <Dashboard /> },
        {
          text: "Customer Support",
          path: "/cs/issues",
          icon: <NotificationAdd />,
        },
        { text: "Activity", path: "/cs/activity", icon: <Message /> },
        { text: "Customers", path: "/cs/customers", icon: <AccountCircle /> },
        { text: "Users", path: "/cs/users", icon: <People /> },
      ],
    },
  ];

  const debtCollectionItems = [
    { text: "Dashboard", path: "/dc/dashboard", icon: <Dashboard /> },
    { text: "Loans", path: "/dc/loans", icon: <Euro /> },
    { text: "Payments", path: "/dc/payments", icon: <CreditCard /> },
    { text: "Follow Up", path: "/dc/agents", icon: <Call /> },
    { text: "Customers", path: "/dc/customers", icon: <AccountCircle /> },
    props?.user?.Role == "Admin"
      ? {
          text: "Users",
          path: "/dc/users",
          icon: <People />,
        }
      : {},
  ];

  const riskManagement = [
    { text: "Dashboard", path: "/rm/dashboard", icon: <Dashboard /> },
    { text: "Applications", path: "/rm/applications", icon: <EventNote /> },
    { text: "Credit Limit", path: "/rm/limits", icon: <CreditCard /> },
    { text: "Customers", path: "/rm/customers", icon: <AccountCircle /> },
    { text: "Users", path: "/rm/users", icon: <People /> },
  ];

  const customerService = [
    { text: "Dashboard", path: "/cs/dashboard", icon: <Dashboard /> },
    {
      text: "Customer Support",
      path: "/cs/issues",
      icon: <NotificationAdd />,
    },
    { text: "Activity", path: "/cs/activity", icon: <Message /> },
    { text: "Customers", path: "/cs/customers", icon: <AccountCircle /> },
    { text: "Users", path: "/cs/users", icon: <People /> },
  ];

  const affiliateMarketing = [
    { text: "Dashboard", path: "/affiliate/dashboard", icon: <Dashboard /> },
    { text: "Affiliates", path: "/affiliate/list", icon: <People /> },
    { text: "Commissions", path: "/affiliate/commissions", icon: <Euro /> },
    {
      text: "Performance",
      path: "/affiliate/performance",
      icon: <EventNote />,
    },
  ];

  useEffect(() => {
    if (user) {
      if (user.Department === "Admin") {
        setMenuItems(adminItems);
      } else if (user.Department === "Debt Collection") {
        setMenuItems(debtCollectionItems);
      } else if (user.Department === "Risk Management") {
        setMenuItems(riskManagement);
      } else if (user.Department === "Customer Service") {
        setMenuItems(customerService);
      } else if (user.Department === "Affiliate Marketing") {
        setMenuItems(affiliateMarketing);
      }
    }
  }, [user]);

  const drawerContent = (
    <Box>
      <DrawerHeader>
        <Box></Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Fragment key={item.text}>
            {item.subItems ? (
              <>
                <ListItem button onClick={() => handleToggle(item.text)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "small",
                      color: location.pathname.includes(item.path)
                        ? "primary"
                        : "textSecondary",
                      fontWeight: location.pathname.includes(item.path)
                        ? "bold"
                        : "normal",
                    }}
                    primary={item.text}
                  />
                  {openSections[item.text] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {openSections[item.text] && (
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.text}
                        button
                        onClick={() => navigate(subItem.path)}
                        selected={location.pathname.includes(subItem.path)}
                        sx={{
                          fontSize: "x-small",
                          pl: 4,
                          typography: "body2",
                          fontStyle: "italic",
                          bgcolor: location.pathname.includes(subItem.path)
                            ? "action.selected"
                            : "transparent",
                        }}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={subItem.text}
                          sx={{
                            fontSize: "x-small",
                            color: location.pathname.includes(item.path)
                              ? "primary"
                              : "textSecondary",
                            fontWeight: location.pathname.includes(item.path)
                              ? "bold"
                              : "normal",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            ) : (
              <ListItem
                key={item.text}
                button
                onClick={() => navigate(item.path)}
                selected={location.pathname.includes(item.path)}
                sx={{
                  bgcolor: location.pathname.includes(item.path)
                    ? "action.selected"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  {cloneElement(item.icon, {
                    color: location.pathname.includes(item.path)
                      ? "primary"
                      : "textSecondary",
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: location.pathname.includes(item.path)
                      ? "primary"
                      : "textSecondary",
                    fontWeight: location.pathname.includes(item.path)
                      ? "bold"
                      : "normal",
                  }}
                />
              </ListItem>
            )}
          </Fragment>
        ))}
      </List>
      <Divider />
      <List>
        {user && user.Department === "Admin" && (
          <ListItem
            button
            selected={location.pathname.includes("/customerlist")}
            sx={{
              bgcolor: location.pathname.includes("/customerlist")
                ? "action.selected"
                : "transparent",
            }}
            onClick={() => navigate("/customerlist")}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        )}
        {user && user.Department === "Admin" && (
          <ListItem
            button
            selected={location.pathname.includes("/disbursements")}
            sx={{
              bgcolor: location.pathname.includes("/disbursements")
                ? "action.selected"
                : "transparent",
            }}
            onClick={() => navigate("/disbursements")}
          >
            <ListItemIcon>
              <Euro />
            </ListItemIcon>
            <ListItemText primary="Disbursements" />
          </ListItem>
        )}
        {user && user.Department === "Admin" && (
          <ListItem
            button
            selected={location.pathname.includes("/userslist")}
            sx={{
              bgcolor: location.pathname.includes("/userslist")
                ? "action.selected"
                : "transparent",
            }}
            onClick={() => navigate("/userslist")}
          >
            <ListItemIcon>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        )}
        {user && user.Department === "Admin" && (
          <ListItem
            button
            selected={location.pathname.includes("/settings")}
            sx={{
              bgcolor: location.pathname.includes("/settings")
                ? "action.selected"
                : "transparent",
            }}
            onClick={() => navigate("/settings")}
          >
            <ListItemIcon>
              <Gear size={24} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        )}
        <ListItem
          button
          selected={location.pathname.includes("/account")}
          sx={{
            bgcolor: location.pathname.includes("/account")
              ? "action.selected"
              : "transparent",
          }}
          onClick={() => navigate("/account")}
        >
          <ListItemIcon>
            <Gear size={24} />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Header
            setUser={props.setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      {isMobile ? (
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          {drawerContent}
        </SwipeableDrawer>
      ) : (
        <Drawer variant="permanent" open={open}>
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
