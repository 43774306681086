import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Alert,
  Grid,
  useTheme,
  CircularProgress,
} from "@mui/material";
import {
  Message,
  Send,
  Search,
  Person,
  AccountBalance,
  CreditScore,
  Refresh,
} from "@mui/icons-material";
import dayjs from "dayjs";

const FilterSection = ({
  status,
  onStatusChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onBulkMessage,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Customers
          </Typography>
          <Button
            variant="contained"
            startIcon={<Send />}
            size="small"
            onClick={onBulkMessage}
          >
            Send Bulk Message
          </Button>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Customer Type</InputLabel>
              <Select
                value={status}
                onChange={onStatusChange}
                label="Customer Type"
              >
                <MenuItem value="All">All Customers</MenuItem>
                <MenuItem value="true">Premium</MenuItem>
                <MenuItem value="false">Regular</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID Number</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

const MessageDialog = ({
  open,
  onClose,
  title,
  onSend,
  bulkSend,
  selectedUser,
}) => {
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [sendSMS, setSendSMS] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const handleSend = () => {
    onSend({
      title: messageTitle,
      content: messageContent,
      sms: sendSMS,
      email: sendEmail,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Message color="primary" />
          <Typography variant="h6">
            {bulkSend
              ? "Send Bulk Message"
              : `Message to ${selectedUser?.firstName}`}
          </Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <TextField
            label="Message Title"
            fullWidth
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
            required
          />
          <TextField
            label="Message Content"
            fullWidth
            multiline
            rows={4}
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            required
          />
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendSMS}
                  onChange={(e) => setSendSMS(e.target.checked)}
                />
              }
              label="Send via SMS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                />
              }
              label="Send via Email"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          variant="contained"
          disabled={
            !messageTitle || !messageContent || (!sendSMS && !sendEmail)
          }
        >
          Send Message
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function CSCustomers() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [bulkSend, setBulkSend] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, [offset, refresh, status]);

  const fetchCustomers = () => {
    setLoading(true);
    const url = `/api/users?offset=${
      offset * 10
    }&includeLoans=true&includeCreditScore=true${
      status === "All" ? "" : `&status=${status}`
    }`;

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => setData(data))
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const handleOpenMessageDialog = (user = null) => {
    setSelectedUser(user);
    setBulkSend(!user);
    setOpenMessageDialog(true);
  };

  const handleSendMessage = (messageData) => {
    const payload = {
      Subject: messageData.title,
      Message: messageData.content,
      SMS: messageData.sms,
      Email: messageData.email,
    };

    if (bulkSend) {
      data.data.forEach((user) => sendMessageToUser(user.id, payload));
    } else {
      sendMessageToUser(selectedUser.id, payload);
    }
  };

  const sendMessageToUser = (userId, messageData) => {
    fetch(`/api/messages`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...messageData, userId }),
    })
      .then(async (res) => {
        if (res.ok) {
          setSnackbarMessage("Message sent successfully!");
          setSnackbarOpen(true);
          setOpenMessageDialog(false);
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
      .catch((error) => {
        setSnackbarMessage(error.message || "Failed to send message.");
        setSnackbarOpen(true);
      });
  };

  return (
    <Box sx={{ mt: 9, px: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Customer Management
      </Typography>

      <FilterSection
        status={status}
        onStatusChange={(e) => {
          setOffset(0);
          setStatus(e.target.value);
        }}
        column={column}
        onColumnChange={(e) => setColumn(e.target.value)}
        searchValue={searchValue}
        onSearch={handleSearch}
        onBulkMessage={() => handleOpenMessageDialog()}
      />

      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>ID Number</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Credit Limit</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : data?.data?.length > 0 ? (
                  data.data.map((customer, index) => (
                    <TableRow
                      key={customer.id}
                      hover
                      onClick={() => {
                        window.location.href = "/cs/customers/" + customer.id;
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <Chip
                          label={offset * 10 + index + 1}
                          size="small"
                          sx={{ minWidth: 40 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={customer.isVerified ? "Premium" : "Regular"}
                          size="small"
                          color={customer.isVerified ? "success" : "warning"}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Person sx={{ color: "text.secondary" }} />
                          <Box>
                            <Typography variant="body2">
                              {customer.firstName} {customer.lastName}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {customer.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{customer.idCardNumber}</TableCell>
                      <TableCell>
                        <Chip
                          icon={<AccountBalance sx={{ fontSize: "1rem" }} />}
                          label={customer.loans.length}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={<CreditScore sx={{ fontSize: "1rem" }} />}
                          label={`KES ${(
                            customer.CreditScore?.creditLimit || 0
                          ).toLocaleString()}`}
                          size="small"
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenMessageDialog(customer);
                          }}
                          sx={{
                            color: theme.palette.primary.main,
                            "&:hover": {
                              bgcolor: theme.palette.primary.lighter,
                            },
                          }}
                        >
                          <Message fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No customers found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <MessageDialog
        open={openMessageDialog}
        onClose={() => setOpenMessageDialog(false)}
        onSend={handleSendMessage}
        bulkSend={bulkSend}
        selectedUser={selectedUser}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
