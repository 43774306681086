import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  SupportAgent,
  Email,
  Message,
  Groups,
  CheckCircleOutline,
  PendingActions,
  TrendingUp,
} from "@mui/icons-material";
import MyPieChart from "../../Pages/Home/MyPieChart";
import MyLineChart from "../../Pages/Home/MyLineChart";

const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

const ProgressCard = ({ label, total, data, color }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="title" color="text.secondary">
          {label}
        </Typography>

        <Typography variant="h5" sx={{ color: color, fontWeight: 600 }}>
          {total.toLocaleString()}
        </Typography>

        <Stack spacing={2}>
          {data.map((item, index) => (
            <Box key={index}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="text.secondary">
                  {item.label}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {item.value.toLocaleString()} (
                  {((item.value / total) * 100).toFixed(1)}%)
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={(item.value / total) * 100}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  bgcolor: `${item.color}15`,
                  "& .MuiLinearProgress-bar": {
                    bgcolor: item.color,
                    borderRadius: 4,
                  },
                }}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default function CSDashboard() {
  const [data, setData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [overviewRes, usersRes] = await Promise.all([
          fetch("/api/loans/overview"),
          fetch("/api/users/summary"),
        ]);

        const [overview, users] = await Promise.all([
          overviewRes.json(),
          usersRes.json(),
        ]);

        setData({ overview, users });
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  const { overview, users } = data;

  const stats = [
    {
      icon: <SupportAgent />,
      label: "Total Tickets",
      value: overview.cs_support.total,
      current: overview.cs_support.current,
      last: overview.cs_support.last,
      color: "#2196F3",
    },
    {
      icon: <Groups />,
      label: "Active Customers",
      value: users.users.total,
      current: users.users.current,
      last: users.users.last,
      color: "#4CAF50",
    },
    {
      icon: <Email />,
      label: "Email Communications",
      value: overview.com.email,
      current: overview.com.current,
      last: overview.com.last,
      color: "#FF9800",
    },
    {
      icon: <Message />,
      label: "SMS Communications",
      value: overview.com.sms,
      current: overview.com.current,
      last: overview.com.last,
      color: "#9C27B0",
    },
  ];

  return (
    <Box sx={{ mt: 8, px: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Customer Support Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Stats Cards */}
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatCard {...stat} />
          </Grid>
        ))}

        {/* Distribution Charts */}
        <Grid item xs={12} md={4}>
            <MyPieChart
              title="User Types"
              data={[
                { name: "Premium", value: users.premium.total },
                { name: "Regular", value: users.regular.total },
              ]}
              height={200}
            />
         
        </Grid>

        <Grid item xs={12} md={4}>
            <MyPieChart
              title="Gender Distribution"
              data={users.genderDistribution}
              height={200}
            />

        </Grid>

        <Grid item xs={12} md={4}>
  
            <MyPieChart
              title="Employment Status"
              data={users.employmentDistribution}
              height={200}
            />
         
        </Grid>

        {/* Progress Cards */}
        <Grid item xs={12} md={4}>
          <ProgressCard
            label="Support Tickets"
            total={overview.cs_support.total}
            color="#2196F3"
            data={[
              {
                label: "Open",
                value: overview.cs_support.open,
                color: "#FF9800",
              },
              {
                label: "Resolved",
                value: overview.cs_support.resolved,
                color: "#4CAF50",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ProgressCard
            label="Loan Processing"
            total={overview.lnp.total}
            color="#FF9800"
            data={[
              {
                label: "Approved",
                value: overview.lnp.approved,
                color: "#4CAF50",
              },
              {
                label: "Rejected",
                value: overview.lnp.rejected,
                color: "#F44336",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ProgressCard
            label="Communication Channels"
            total={overview.com.total}
            color="#9C27B0"
            data={[
              { label: "Email", value: overview.com.email, color: "#2196F3" },
              { label: "SMS", value: overview.com.sms, color: "#FF9800" },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
