import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  AccountBalance,
  MonetizationOn,
  Receipt,
  Payment,
  AccountBalanceWallet,
  Person,
  Phone,
  CalendarToday,
  Info,
} from "@mui/icons-material";
import Swal from "sweetalert2";

const Payments = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawError, setWithdrawError] = useState("");
  const [stats, setStats] = useState({
    paidCommission: 0,
    pendingCommission: 0,
    potentialCommission: 0,
    failedCommission: 0,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  useEffect(() => {
    fetchPayments();
    fetchStats();
  }, [affiliate, paginationModel]);

  const fetchStats = async () => {
    try {
      const response = await fetch(`/api/affiliates/referral-stats`, {
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        setStats({
          paidCommission: data.paidCommission || 0,
          pendingCommission: data.pendingCommission || 0,
          potentialCommission: data.potentialCommission || 0,
          failedCommission: data.failedCommission || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const offset = paginationModel.page * paginationModel.pageSize;
      const response = await fetch(
        `/api/affiliates/payments?limit=${paginationModel.pageSize}&offset=${offset}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPayments(data);
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleWithdrawRequest = async () => {
    try {
      const response = await fetch("/api/affiliates/payments/request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          amount: Number(withdrawAmount),
          phoneNumber: phoneNumber,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error);
      }

      setOpenWithdraw(false);
      setWithdrawAmount("");
      setPhoneNumber("");
      fetchPayments();
      fetchStats();
    } catch (error) {
      setWithdrawError(error.message);
    }
  };

  const handleRowClick = (params) => {
    setSelectedPayment(params.row);
    setOpenDetailsDialog(true);
  };

  const handleApprovePayment = async (payment) => {
    try {
      const result = await Swal.fire({
        title: "Confirm Approval",
        html: `
          <p>Amount: KSH ${Number(payment.Amount).toLocaleString()}</p>
          <p>Phone: ${payment.PhoneNumber}</p>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve",
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing...",
          html: "Please wait while we process the payment",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(
          `/api/affiliates/payments/${payment.PaymentID}/process`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              status: "completed",
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to process payment");
        }

        await Swal.fire({
          title: "Success!",
          text: "Payment has been approved and processed",
          icon: "success",
        });

        setOpenDetailsDialog(false);
        fetchPayments();
        fetchStats();
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleRejectPayment = async (payment) => {
    try {
      const result = await Swal.fire({
        title: "Confirm Rejection",
        input: "text",
        inputLabel: "Rejection Reason",
        inputPlaceholder: "Enter reason for rejection",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Reject Payment",
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason for rejection";
          }
        },
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing...",
          html: "Please wait while we process the rejection",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(
          `/api/affiliates/payments/${payment.PaymentID}/process`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              status: "failed",
              reason: result.value,
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to process rejection");
        }

        await Swal.fire({
          title: "Success!",
          text: "Payment has been rejected",
          icon: "success",
        });

        setOpenDetailsDialog(false);
        fetchPayments();
        fetchStats();
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
      });
    }
  };

  const StatCard = ({ title, value, icon, color }) => (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              color: `${color}.main`,
              bgcolor: `${color}.lighter`,
              p: 2,
              borderRadius: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography color="text.secondary" variant="body2" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5">
              KSH {value?.toLocaleString("en-US", { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return "success";
      case "pending":
        return "warning";
      case "processing":
        return "info";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const columns = [
    {
      field: "No",
      headerName: "No",
      width: 60,
      renderCell: (params) => {
        return (
          paginationModel.page * paginationModel.pageSize +
          payments.data.indexOf(params.row) +
          1
        );
      },
    },
    {
      field: "Name",
      headerName: "Affiliate",
      flex: 1,
      renderCell: (params) => params.row.affiliate.Name,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => params.value.replace(/^254/, "0"),
    },
    {
      field: "Amount",
      headerName: "Amount (KSH)",
      flex: 1,
      renderCell: (params) => Number(params.value)?.toLocaleString(),
    },
    {
      field: "createdAt",
      headerName: "Requested Date",
      flex: 1,
      renderCell: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "ProcessedAt",
      headerName: "Processed Date",
      flex: 1,
      renderCell: (params) =>
        params.value ? new Date(params.value).toLocaleDateString() : "Pending",
    },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={getStatusColor(params.value)}
          size="small"
        />
      ),
    },
  ];

  return (
    <Box mt={9}>
      <Typography variant="h5" gutterBottom>
        Payments
      </Typography>

      {/* Stats Cards */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Available Balance"
            value={stats.pendingCommission}
            icon={<AccountBalanceWallet />}
            color="success"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Potential Payments"
            value={stats.potentialCommission}
            icon={<Payment />}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Failed Payments"
            value={stats.failedCommission}
            icon={<MonetizationOn />}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Withdrawn"
            value={stats.paidCommission}
            icon={<MonetizationOn />}
            color="info"
          />
        </Grid>
      </Grid>

      {/* DataGrid */}
      <Paper sx={{ p: 3 }}>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            rows={payments.data || []}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 25, 50]}
            getRowId={(row) => row.PaymentID}
            rowCount={payments.total || 0}
            loading={loading}
            paginationMode="server"
            autoHeight
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                columnsButton: true,
                filterButton: true,
                densitySelector: true,
                exportButton: true,
              },
            }}
            onRowClick={handleRowClick}
          />
        </Box>
      </Paper>

      {/* Withdraw Dialog */}
      <Dialog
        open={openWithdraw}
        onClose={() => {
          setOpenWithdraw(false);
          setWithdrawError("");
          setWithdrawAmount("");
          setPhoneNumber("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Withdraw Funds</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            {withdrawError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {withdrawError}
              </Alert>
            )}
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Available Balance: KSH {stats.availableBalance?.toLocaleString()}
            </Typography>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Amount to Withdraw"
                type="number"
                value={withdrawAmount}
                onChange={(e) => {
                  setWithdrawAmount(e.target.value);
                  setWithdrawError("");
                }}
                error={!!withdrawError}
                helperText="Minimum withdrawal amount: KSH 1,000"
              />
              <TextField
                fullWidth
                label="M-Pesa Phone Number"
                type="tel"
                placeholder="07XX XXX XXX"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setWithdrawError("");
                }}
                error={!!withdrawError}
                helperText="Enter Safaricom number to receive payment"
              />
            </Stack>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block", mt: 1 }}
            >
              Funds will be sent to the provided M-Pesa number
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenWithdraw(false);
              setWithdrawError("");
              setWithdrawAmount("");
              setPhoneNumber("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleWithdrawRequest}
            disabled={!withdrawAmount || withdrawError}
          >
            Withdraw
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Payment Details Dialog */}
      <Dialog
        open={openDetailsDialog}
        onClose={() => setOpenDetailsDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        {selectedPayment && (
          <>
            <DialogTitle>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Payment />
                </Box>
                <Typography variant="h6">Payment Details</Typography>
              </Stack>
            </DialogTitle>
            <DialogContent dividers>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Person color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Affiliate"
                    secondary={selectedPayment.affiliate?.Name}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Phone color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Phone Number"
                    secondary={selectedPayment.PhoneNumber}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MonetizationOn color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Amount"
                    secondary={`KSH ${Number(
                      selectedPayment.Amount
                    )?.toLocaleString()}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Info color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Status"
                    secondary={
                      <Chip
                        label={selectedPayment.Status}
                        color={getStatusColor(selectedPayment.Status)}
                        size="small"
                      />
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CalendarToday color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Requested Date"
                    secondary={new Date(
                      selectedPayment.createdAt
                    ).toLocaleString()}
                  />
                </ListItem>
                {selectedPayment.ProcessedAt && (
                  <ListItem>
                    <ListItemIcon>
                      <CalendarToday color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Processed Date"
                      secondary={new Date(
                        selectedPayment.ProcessedAt
                      ).toLocaleString()}
                    />
                  </ListItem>
                )}
                {selectedPayment.MpesaReceiptNumber && (
                  <ListItem>
                    <ListItemIcon>
                      <Receipt color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="M-Pesa Receipt"
                      secondary={selectedPayment.MpesaReceiptNumber}
                    />
                  </ListItem>
                )}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDetailsDialog(false)}>Close</Button>
              {selectedPayment.Status === "pending" && (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRejectPayment(selectedPayment)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleApprovePayment(selectedPayment)}
                  >
                    Approve
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Payments;
