import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Stack,
  Tabs,
  Tab,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ContactEmergency,
  DateRange,
  Emergency,
  Money,
  Person,
  SupportAgent,
  Refresh,
  Search,
  CalendarToday,
  AccountBalance,
  Warning,
  CheckCircle,
  Block,
  HourglassEmpty,
  AttachMoney,
  Schedule,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const StatusChip = ({ status }) => {
  const getStatusConfig = (status) => {
    switch (status) {
      case "Pending":
        return {
          icon: <HourglassEmpty />,
          color: "warning",
        };
      case "Approved":
        return {
          icon: <CheckCircle />,
          color: "success",
        };
      case "Rejected":
        return { icon: <Block />, color: "error" };
      case "Waiting":
        return { icon: <Schedule />, color: "info" };
      default:
        return {
          icon: <Warning />,
          color: "default",
        };
    }
  };

  const config = getStatusConfig(status);
  return (
    <IconButton size="small" color={config.color}>
      {config.icon}
    </IconButton>
  );
};

const FilterCard = ({
  column,
  onColumnChange,
  searchValue,
  onSearch,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 1,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Applications
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh
          </Button>
        </Box>

        <Grid2 container spacing={2}>
          <Grid2 item size={{ xs: 12, md: 3 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={onStartDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 3 }}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={onEndDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
          </Grid2>

          <Grid2 item size={{ xs: 12, md: 3 }}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID No.</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 3 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid2>
        </Grid2>
      </Stack>
    </Card>
  );
};

const ApplicationStatusTabs = ({ value, onChange }) => {
  return (
    <Stack direction="row" spacing={2}>
      {["Pending", "Waiting", "Rejected", "Approved"].map((status) => (
        <Button
          key={status}
          variant={value === status ? "contained" : "outlined"}
          onClick={() => onChange(status)}
          startIcon={<StatusChip status={status} />}
          size="small"
          sx={{
            textTransform: "capitalize",
            minWidth: 130,
          }}
        >
          {status}
        </Button>
      ))}
    </Stack>
  );
};

const ApplicationRow = ({ item, index, offset, onClick }) => {
  return (
    <TableRow hover onClick={onClick} sx={{ cursor: "pointer" }}>
      <TableCell>
        <Chip
          label={offset * 10 + index + 1}
          size="small"
          sx={{ minWidth: 40 }}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Person sx={{ color: "text.secondary" }} />
          <Box>
            <Typography variant="body2">
              {item.user?.firstName} {item.user?.lastName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {item.user?.phoneNumber?.replace("+", "").replace("254", "0")}
            </Typography>
          </Box>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AccountBalance sx={{ color: "text.secondary", fontSize: "1rem" }} />
          <Typography variant="body2">{item.orderNumber}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Chip label={`${item.term} days`} size="small" variant="outlined" />
      </TableCell>
      <TableCell>
        <Stack spacing={0.5}>
          <Typography variant="caption" color="text.secondary">
            Applied: {dayjs(item.createdAt).format("DD MMM YYYY")}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Due: {dayjs(item.repaymentDate).format("DD MMM YYYY")}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AttachMoney sx={{ color: "primary.main", fontSize: "1.2rem" }} />
          <Typography variant="body2" fontWeight="medium">
            KES {item.remainingAmount.toLocaleString()}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <StatusChip status={item.status} />
      </TableCell>
    </TableRow>
  );
};

export default function RMApplications(props) {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Pending");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    let url = `/api/loans?offset=${
      offset * 10
    }&includeUsers=true&includePayments=true&includeFollowUps=true&loanStatus=${selected}`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setData(null);
      setLoading(true);
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=trueincludeFollowUps=true&loanStatus=${selected}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null))
        .finally(() => setLoading(false));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box mt={9}>
        <Stack spacing={3}>
          {/* Status Tabs Card */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "primary.main",
              background: "linear-gradient(45deg, #2196F3, #3f51b5)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Loan Applications
          </Typography>
          <Card
            elevation={0}
            sx={{
              p: 1,
              borderRadius: 3,
              border: "1px solid",
              borderColor: "divider",
              background: (theme) => theme.palette.background.neutral,
            }}
          >
            <ApplicationStatusTabs
              value={selected}
              onChange={(newValue) => {
                setOffset(0);
                setSelected(newValue);
              }}
            />
          </Card>

          {/* Combined Filter Card */}
          <FilterCard
            column={column}
            onColumnChange={(e) => setColumn(e.target.value)}
            searchValue={searchValue}
            onSearch={handleSearch}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(newValue) => setStartDate(newValue)}
            onEndDateChange={(newValue) => setEndDate(newValue)}
            onRefresh={() => setRefresh(!refresh)}
          />

          {/* Data Table Card */}
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <Box sx={{ p: 3 }}>
              {loading ? (
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Order No</TableCell>
                        <TableCell>Term</TableCell>
                        <TableCell>Dates</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.data?.length > 0 ? (
                        data.data.map((item, index) => (
                          <ApplicationRow
                            key={index}
                            item={item}
                            index={index}
                            offset={offset}
                            onClick={() =>
                              navigate(`/rm/applications/${item.id}`)
                            }
                          />
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            <Typography color="text.secondary">
                              No applications found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <Box display="flex" justifyContent="center" mt={3}>
                {data && (
                  <Pagination
                    count={Math.ceil(data.total / 10)}
                    page={offset + 1}
                    onChange={(e, value) => setOffset(value - 1)}
                    color="primary"
                  />
                )}
              </Box>
            </Box>
          </Card>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
