import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import { GeoJSON } from "ol/format";
import { Fill, Stroke, Style, Text } from "ol/style";
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Popover,
} from "@mui/material";
import { defaults as defaultControls, FullScreen, Rotate } from "ol/control";
import { MultiPolygon } from "ol/geom";
import Feature from "ol/Feature";

const CountyMap = ({ url, height, title, color }) => {
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [popupContent, setPopupContent] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current,
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        projection: "EPSG:4326",
        center: [37.9062, 0.5142], // Center of Kenya
        zoom: 6.5,
        maxZoom: 32,
      }),
      controls: defaultControls().extend([new FullScreen(), new Rotate()]),
    });

    setMap(initialMap);
    return () => initialMap.setTarget(null);
  }, []);

  useEffect(() => {
    if (map) {
      // Add click handler
      map.on("click", (evt) => {
        const feature = map.forEachFeatureAtPixel(
          evt.pixel,
          (feature) => feature
        );

        if (feature) {
          const name = feature.get("name");
          const value = feature.get("value");

          // Get click coordinates in pixels
          const clickPixel = evt.pixel;
          const element = map.getTargetElement();
          const rect = element.getBoundingClientRect();

          setPopupContent({ name, value });
          setPopupAnchor({
            left: rect.left + clickPixel[0],
            top: rect.top + clickPixel[1],
          });
        } else {
          setPopupContent(null);
          setPopupAnchor(null);
        }
      });
    }
  }, [map]);

  useEffect(() => {
    if (map && !data) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(url, {
            credentials: "include",
          });
          const result = await response.json();
          if (response.ok) {
            setData(result.data || []);
          } else {
            console.error("Error fetching data:", result.message);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [url, map, data]);

  useEffect(() => {
    if (!data || !data.length || !Array.isArray(data)) return;

    map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer)
      .forEach((layer) => map.removeLayer(layer));

    const maxValue = Math.max(...data.map((county) => county.value));

    const features = data
      .map((item) => {
        if (item.geom && item.geom.coordinates) {
          const geometry = new MultiPolygon(item.geom.coordinates);
          return new Feature({
            geometry: geometry,
            name: item.name,
            value: item.value,
          });
        }
        return null;
      })
      .filter(Boolean);

    const vectorSource = new VectorSource({ features });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        const value = feature.get("value");
        const intensity = maxValue > 0 ? value / maxValue : 0;
        const hex = color.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        const adjustedColor = `rgba(${r}, ${g}, ${b}, ${intensity})`;

        return new Style({
          fill: new Fill({ color: adjustedColor }),
          stroke: new Stroke({
            color: "#0033A050",
            width: 1,
          }),
          text: new Text({
            text:
              value > 0
                ? feature.get("name") +
                  ": " +
                  feature.get("value").toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                : feature.get("name"),
            fill: new Fill({
              color: "#0033A0",
            }),
            fontSize: 18,
          }),
        });
      },
    });

    map.addLayer(vectorLayer);
    const extent = vectorSource.getExtent();
    map.getView().fit(extent, {
      padding: [10, 10, 10, 10],
      duration: 1000,
    });
  }, [data, map, color]);

  return (
    <Box
      sx={{
        width: "100%",
        height: height || 400,
        borderRadius: 3,
        overflow: "hidden",
        border: "1px solid",
        borderColor: "divider",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      component={Card}
    >
      <Typography variant="title" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <div
        ref={mapElement}
        style={{ width: "100%", height: "100%", flexGrow: 1 }}
      />
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Popover
        open={Boolean(popupAnchor)}
        anchorReference="anchorPosition"
        anchorPosition={popupAnchor}
        onClose={() => {
          setPopupAnchor(null);
          setPopupContent(null);
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {popupContent && (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1">{popupContent.name}</Typography>
            <Typography variant="body2">Value: {popupContent.value}</Typography>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export default CountyMap;
