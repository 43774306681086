import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Card,
  Stack,
  Grid,
  useTheme,
} from "@mui/material";
import {
  Search,
  Refresh,
  CalendarToday,
  Person,
  Email as EmailIcon,
  Message as MessageIcon,
  CheckCircle,
  Schedule,
} from "@mui/icons-material";
import { debounce } from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const FilterSection = ({
  startDate,
  endDate,
  onDateChange,
  searchColumn,
  onColumnChange,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Messages
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => onDateChange("start", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => onDateChange("end", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={searchColumn}
                onChange={(e) => onColumnChange(e.target.value)}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="subject">Subject</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="message">Content</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            * Filter and search through messages
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh Results
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

const MessageDetailsDialog = ({ message, open, onClose }) => {
  if (!message) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <MessageIcon color="primary" />
          <Typography variant="h6">Message Details</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Message Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Subject
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {message.Subject}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Message Content
                  </Typography>
                  <Typography variant="body2">{message.Message}</Typography>
                </Box>
                <Box display="flex" gap={1}>
                  {message.SMS && (
                    <Chip label="SMS" size="small" color="primary" />
                  )}
                  {message.Email && (
                    <Chip label="Email" size="small" color="primary" />
                  )}
                  <Chip
                    label={message.Status ? "Read" : "Unread"}
                    size="small"
                    color={message.Status ? "success" : "warning"}
                    icon={message.Status ? <CheckCircle /> : <Schedule />}
                  />
                </Box>
              </Stack>
            </Card>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              User Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {message.User?.firstName} {message.User?.lastName}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {message.User?.email}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {message.User?.phoneNumber}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Date
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {dayjs(message.createdAt).format("MMM D, YYYY HH:mm")}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function CSMessages() {
  const theme = useTheme();
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("subject");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, [page, searchText, searchColumn, startDate, endDate, refresh]);

  const fetchMessages = () => {
    setLoading(true);
    let url = `/api/messages?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;
    if (searchText) url += `&${searchColumn}=${searchText}`;
    if (startDate) url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    if (endDate) url += `&endDate=${endDate.format("YYYY-MM-DD")}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Error fetching messages");
      })
      .then((data) => {
        setMessages(data?.data);
        setCount(data?.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = debounce((value) => {
    setSearchText(value);
    setPage(0);
  }, 500);

  const handleDateChange = (type, value) => {
    if (type === "start") setStartDate(value);
    else setEndDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9, px: 2 }}>
        <Typography variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}>
          Message History
        </Typography>

        <FilterSection
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          searchColumn={searchColumn}
          onColumnChange={setSearchColumn}
          onSearch={handleSearch}
          onRefresh={() => setRefresh(!refresh)}
        />

        <Card
          elevation={0}
          sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
        >
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : messages.length > 0 ? (
                    messages.map((message) => (
                      <TableRow
                        key={message.ID}
                        hover
                        onClick={() => {
                          setSelectedMessage(message);
                          setOpenDialog(true);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Person sx={{ color: "text.secondary" }} />
                            <Box>
                              <Typography variant="body2">
                                {message.User?.firstName}{" "}
                                {message.User?.lastName}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {message.User?.email}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>{message.Subject}</TableCell>
                        <TableCell sx={{ maxWidth: 200 }}>
                          <Typography noWrap variant="body2">
                            {message.Message}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            {message.Email && (
                              <Chip
                                icon={<EmailIcon sx={{ fontSize: "1rem" }} />}
                                label="Email"
                                size="small"
                                color="primary"
                              />
                            )}
                            {message.SMS && (
                              <Chip
                                icon={<MessageIcon sx={{ fontSize: "1rem" }} />}
                                label="SMS"
                                size="small"
                                color="primary"
                              />
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={message.Status ? "Read" : "Unread"}
                            size="small"
                            color={message.Status ? "success" : "warning"}
                            icon={
                              message.Status ? (
                                <CheckCircle sx={{ fontSize: "1rem" }} />
                              ) : (
                                <Schedule sx={{ fontSize: "1rem" }} />
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {dayjs(message.createdAt).format("MMM D, YYYY")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No messages found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={Math.ceil(count / rowsPerPage)}
                page={page + 1}
                onChange={(e, value) => setPage(value - 1)}
                color="primary"
              />
            </Box>
          </Box>
        </Card>

        <MessageDetailsDialog
          message={selectedMessage}
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedMessage(null);
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}
