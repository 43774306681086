import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Pagination,
  Card,
  Typography,
  Stack,
  Grid,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Add as AddIcon,
  Search,
  Refresh,
  Person,
  Email,
  Phone,
  Work,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";

const FilterSection = ({
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Users
        </Typography>

        <Box display="flex" justifyContent="right" gap={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={(e) => onColumnChange(e.target.value)}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            * Search through customer service agents
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefresh}
              size="small"
            >
              Refresh
            </Button>
            <Button variant="contained" startIcon={<AddIcon />} size="small">
              Add Agent
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

const AgentDetailsDialog = ({ agent, open, onClose }) => {
  if (!agent) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Person color="primary" />
          <Typography variant="h6">Agent Details</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Card variant="outlined" sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <Person sx={{ color: "text.secondary" }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {agent.Name}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Email sx={{ color: "text.secondary" }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body1">{agent.Email}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Phone sx={{ color: "text.secondary" }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body1">{agent.Phone}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Work sx={{ color: "text.secondary" }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Position
                  </Typography>
                  <Typography variant="body1">{agent.Position}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Chip
                  label={agent.Status ? "Active" : "Inactive"}
                  color={agent.Status ? "success" : "warning"}
                  icon={agent.Status ? <CheckCircle /> : <Cancel />}
                />
              </Box>
            </Stack>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function CSUsers() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchAgents();
  }, [offset, refresh]);

  const fetchAgents = () => {
    setLoading(true);
    fetch(`/api/admin?offset=${offset * 10}&department=Customer Service`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/admin?${column}=${value}&department=Customer Service`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box sx={{ mt: 9, px: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Customer Service Users
      </Typography>
      <FilterSection
        column={column}
        onColumnChange={setColumn}
        searchValue={searchValue}
        onSearch={handleSearch}
        onRefresh={() => setRefresh(!refresh)}
      />

      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : data?.data?.length > 0 ? (
                  data.data.map((agent, index) => (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => {
                        setSelectedAgent(agent);
                        setOpenDetailDialog(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <Chip
                          label={offset * 10 + index + 1}
                          size="small"
                          sx={{ minWidth: 40 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Person sx={{ color: "text.secondary" }} />
                          <Typography variant="body2">{agent.Name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{agent.Email}</TableCell>
                      <TableCell>{agent.Phone}</TableCell>
                      <TableCell>{agent.Position}</TableCell>
                      <TableCell>
                        <Chip
                          label={agent.Status ? "Active" : "Inactive"}
                          size="small"
                          color={agent.Status ? "success" : "warning"}
                          icon={
                            agent.Status ? (
                              <CheckCircle sx={{ fontSize: "1rem" }} />
                            ) : (
                              <Cancel sx={{ fontSize: "1rem" }} />
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No agents found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <AgentDetailsDialog
        agent={selectedAgent}
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
          setSelectedAgent(null);
        }}
      />
    </Box>
  );
}
