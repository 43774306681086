import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
  Chip,
} from "@mui/material";
import {
  TrendingUp,
  CheckCircleOutline,
  PendingActions,
  CancelOutlined,
} from "@mui/icons-material";
import MyLineChart from "./MyLineChart";


const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default function Loans({ refresh }) {
  const [data, setData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/loans/overview");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching loans data:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  const stats = [
    {
      icon: <CheckCircleOutline />,
      label: "Approved Loans",
      value: data.lnp.approved,
      trend: 0,
      color: "#4CAF50",
    },
    {
      icon: <PendingActions />,
      label: "Pending Applications",
      value: data.lnp.current,
      trend: 0,
      color: "#FF9800",
    },
    {
      icon: <CancelOutlined />,
      label: "Rejected Applications",
      value: data.lnp.rejected,
      trend: 0,
      color: "#F44336",
    },
    {
      icon: <TrendingUp />,
      label: "Total Loans",
      value: data.lnp.total,
      color: "#2196F3",
    },
  ];

  return (
    <Grid container spacing={3}>
      {/* Stats Row */}
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <StatCard {...stat} />
        </Grid>
      ))}

      {/* Chart */}
      <Grid item xs={12}>
        <Card
          elevation={0}
          sx={{
            p: 3,
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" mb={3}>
            Loan Applications Trend
          </Typography>
          <Box height={400}>
            <MyLineChart />
          </Box>
        </Card>
      </Grid>

      {/* <Grid item xs={12}>
        <Card
          elevation={0}
          sx={{
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack spacing={2} p={3}>
            <Typography variant="h6">Recent Loan Applications</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.recent_loans?.map((loan, index) => (
                    <TableRow key={index}>
                      <TableCell>{loan.customer}</TableCell>
                      <TableCell>${loan.amount.toLocaleString()}</TableCell>
                      <TableCell>
                        {new Date(loan.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <StatusChip status={loan.status} />
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="View Details">
                          <IconButton size="small">
                            <RemoveRedEye fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Card>
      </Grid> */}
    </Grid>
  );
}
