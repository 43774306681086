import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Stack,
  Grid,
  useTheme,
  CircularProgress,
  Button,
  IconButton,
} from "@mui/material";
import {
  Search,
  Person,
  Email,
  Phone,
  Business,
  CheckCircle,
  Warning,
  Add as AddIcon,
  Refresh,
} from "@mui/icons-material";

const FilterSection = ({
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Supervisors
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefresh}
              size="small"
            >
              Refresh
            </Button>
          </Stack>
        </Box>

        <Box display="flex" gap={2} justifyContent="right" alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

export default function DCUsers() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null); // Holds selected agent data
  const [openDetailDialog, setOpenDetailDialog] = useState(false); // Dialog for agent details
  const [refresh, setRefresh] = useState(false); // For refreshing the data

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/admin?offset=${offset * 10}&department=Debt Collection`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Search functionality
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/admin?${column}=${value}&department=Debt Collection`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  // Handle row click to view agent details
  const handleRowClick = (agent) => {
    setSelectedAgent(agent);
    setOpenDetailDialog(true);
  };

  return (
    <Box mt={2}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Supervisors
      </Typography>

      <FilterSection
        column={column}
        onColumnChange={(e) => setColumn(e.target.value)}
        searchValue={searchValue}
        onSearch={handleSearch}
        onRefresh={() => setRefresh(!refresh)}
      />

      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ p: 3 }}>
          {loading ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.length > 0 ? (
                    data.data.map((agent, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(agent)}
                        hover
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Chip
                            label={offset * 10 + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Person sx={{ color: "text.secondary" }} />
                            <Typography variant="body2">
                              {agent.Name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Email
                              sx={{ color: "text.secondary", fontSize: "1rem" }}
                            />
                            <Typography variant="body2">
                              {agent.Email}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Phone
                              sx={{ color: "text.secondary", fontSize: "1rem" }}
                            />
                            <Typography variant="body2">
                              {agent.Phone}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Business
                              sx={{ color: "text.secondary", fontSize: "1rem" }}
                            />
                            <Typography variant="body2">
                              {agent.Department}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{agent.Position}</TableCell>
                        <TableCell>
                          <Chip
                            icon={
                              agent.Status ? (
                                <CheckCircle sx={{ fontSize: "1rem" }} />
                              ) : (
                                <Warning sx={{ fontSize: "1rem" }} />
                              )
                            }
                            label={agent.Status ? "Active" : "Inactive"}
                            size="small"
                            color={agent.Status ? "success" : "warning"}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No agents found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
