import React, { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Stack,
  Grid,
  useTheme,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  Add,
  Refresh,
  Search,
  CalendarToday,
  CheckCircle,
  ErrorOutline,
  Message,
  Category,
  Person,
} from "@mui/icons-material";
import dayjs from "dayjs";
import EnquiryDialog from "./EnquiryDialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const FilterSection = ({
  startDate,
  endDate,
  onDateChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
  onAdd,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Support Issues
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => onDateChange("start", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => onDateChange("end", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={(e) => onColumnChange(e.target.value)}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="category">Category</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            * Filter and search through support issues
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefresh}
              size="small"
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={onAdd}
              size="small"
            >
              New Issue
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

const EnquiryDetailsDialog = ({
  enquiry,
  open,
  onClose,
  onResolve,
  updating,
}) => {
  const [resolution, setResolution] = useState("");

  if (!enquiry) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Message color="primary" />
          <Typography variant="h6">Support Issue Details</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Issue Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Title
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {enquiry.title}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Category
                  </Typography>
                  <Chip label={enquiry.category} size="small" />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Message
                  </Typography>
                  <Typography variant="body2">{enquiry.message}</Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Customer Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {enquiry.name}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {enquiry.phone}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Created
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {dayjs(enquiry.createdAt).format("MMM D, YYYY HH:mm")}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          {!enquiry.status && (
            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Resolution
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Enter resolution details..."
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {!enquiry.status && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onResolve(resolution)}
            disabled={updating || !resolution}
          >
            {updating ? "Resolving..." : "Mark as Resolved"}
          </Button>
        )}
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function CSEnquiries(props) {
  const theme = useTheme();
  const [enquiries, setEnquiries] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [column, setColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [resolutionDialogOpen, setResolutionDialogOpen] = useState(false);
  const [resolutionText, setResolutionText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchEnquiries();
  }, [page, rowsPerPage, column, searchValue, refresh, startDate, endDate]);

  const fetchEnquiries = () => {
    setLoading(true);
    let url = `/api/enquiries?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}${searchValue ? `&${column}=${searchValue}` : ""}`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Error fetching enquiries")
      )
      .then((data) => {
        setLoading(false);
        setEnquiries(data?.data);
        setCount(data?.total);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPage(0);
  };

  const handleStatusUpdate = () => {
    setUpdating(true);
    fetch(`/api/enquiries/${selectedEnquiry.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: true,
        resolution: resolutionText,
        userId: props.user.id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enq) =>
              enq.ID === selectedEnquiry.ID
                ? {
                    ...enq,
                    status: true,
                    resolution: resolutionText,
                    userId: props.user.id,
                  }
                : enq
            )
          );
          setResolutionDialogOpen(false);
          setSelectedEnquiry(null);
        } else throw new Error("Error updating enquiry status");
      })
      .catch((e) => console.error("Failed to update enquiry status:", e))
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleRowClick = (enquiry) => {
    setSelectedEnquiry(enquiry);
  };

  const handleCloseDetailsDialog = () => {
    setSelectedEnquiry(null);
  };

  const handleOpenResolutionDialog = () => {
    setResolutionDialogOpen(true);
  };

  const handleCloseResolutionDialog = () => {
    setResolutionDialogOpen(false);
    setResolutionText("");
  };

  const handleDateChange = (type, value) => {
    if (type === "start") setStartDate(value);
    else setEndDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9, px: 2 }}>
          <Typography variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}>
          Customer Support Issues
        </Typography>
        <FilterSection
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          column={column}
          onColumnChange={setColumn}
          searchValue={searchValue}
          onSearch={handleSearch}
          onRefresh={() => setRefresh(!refresh)}
          onAdd={() => setOpen(true)}
        />

        <Card
          elevation={0}
          sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
        >
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : enquiries.length > 0 ? (
                    enquiries.map((enquiry, index) => (
                      <TableRow
                        key={enquiry.ID}
                        hover
                        onClick={() => handleRowClick(enquiry)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Chip
                            label={page * rowsPerPage + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {enquiry.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Person sx={{ color: "text.secondary" }} />
                            <Box>
                              <Typography variant="body2">
                                {enquiry.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {enquiry.phone}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={enquiry.category}
                            size="small"
                            icon={<Category sx={{ fontSize: "1rem" }} />}
                          />
                        </TableCell>
                        <TableCell>
                          {dayjs(enquiry.createdAt).format("MMM D, YYYY")}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={enquiry.status ? "Resolved" : "Pending"}
                            size="small"
                            color={enquiry.status ? "success" : "warning"}
                            icon={
                              enquiry.status ? (
                                <CheckCircle sx={{ fontSize: "1rem" }} />
                              ) : (
                                <ErrorOutline sx={{ fontSize: "1rem" }} />
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No support issues found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={Math.ceil(count / rowsPerPage)}
                page={page + 1}
                onChange={(e, value) => setPage(value - 1)}
                color="primary"
              />
            </Box>
          </Box>
        </Card>

        <EnquiryDialog
          open={open}
          onClose={() => setOpen(false)}
          refresh={refresh}
          setRefresh={setRefresh}
        />

        <EnquiryDetailsDialog
          enquiry={selectedEnquiry}
          open={Boolean(selectedEnquiry)}
          onClose={handleCloseDetailsDialog}
          onResolve={handleStatusUpdate}
          updating={updating}
        />
      </Box>
    </LocalizationProvider>
  );
}
