import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  Divider,
  Stack,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  People,
  MonetizationOn,
  TrendingUp,
  ContentCopy,
  Share,
  AccountBalance,
  Speed,
  Info,
  Euro,
} from "@mui/icons-material";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { useNavigate } from "react-router-dom";
const Dashboard = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    affiliates: 0,
    referrals: 0,
    activeReferrals: 0,
    paidCommission: 0,
    pendingCommission: 0,
    potentialCommission: 0,
    failedCommission: 0,
  });
  const [recentReferrals, setRecentReferrals] = useState([]);
  const [monthlyCommission, setMonthlyCommission] = useState([]);
  const [commissionDistribution, setCommissionDistribution] = useState([]);
  const navigate = useNavigate();
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  useEffect(() => {
    // Fetch dashboard data
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      // Fetch referral stats
      const statsResponse = await fetch(`/api/affiliates/dashboard/stats`, {
        credentials: "include",
      });
      const statsData = await statsResponse.json();

      if (statsResponse.ok) {
        setStats({
          affiliates: statsData.affiliates || 0,
          referrals: statsData.referrals || 0,
          activeReferrals: statsData.activeReferrals || 0,
          paidCommission: statsData.paidCommission || 0,
          pendingCommission: statsData.pendingCommission || 0,
          potentialCommission: statsData.potentialCommission || 0,
          failedCommission: statsData.failedCommission || 0,
        });
        setMonthlyCommission(statsData.monthlyCommission || []);

        // Transform commission distribution data
        const transformedDistribution = statsData.commissionDistribution.map(
          (item) => ({
            value: parseFloat(item.value),
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1), // Capitalize first letter
          })
        );
        setCommissionDistribution(transformedDistribution);
      }

      // Fetch recent referrals
      const referralsResponse = await fetch(
        `/api/affiliates/${affiliate.AffiliateID}/referrals`,
        {
          credentials: "include",
        }
      );
      const referralsData = await referralsResponse.json();
      if (referralsResponse.ok) {
        setRecentReferrals(referralsData.slice(0, 5)); // Get only the 5 most recent
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const StatCard = ({ title, value, icon, color, info }) => (
    <Card>
      <CardContent sx={{ px: 0, py: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              color: `${color}.main`,
              bgcolor: `${color}.lighter`,
              p: 2,
              borderRadius: 2,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

            {icon}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 0.5 }}
            >
              <Typography color="text.secondary" variant="body2">
                {title}
              </Typography>
              {info && (
                <Tooltip title={info}>
                  <Info fontSize="small" color="action" />
                </Tooltip>
              )}
            </Stack>
            <Typography variant="h5">
              {typeof value === "number" && title.includes("KSH")
                ? `KSH ${value.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}`
                : value}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "400px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt={9}>
      {/* Stats Grid */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Employees"
            value={stats.affiliates}
            icon={<People />}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Paid Commission"
            value={`KSH ${stats.paidCommission?.toLocaleString() || 0}`}
            icon={<MonetizationOn />}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Pending Commission"
            value={`KSH ${stats.pendingCommission?.toLocaleString() || 0}`}
            icon={<AccountBalance />}
            color="warning"
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Potential Commission"
            value={`KSH ${
              (
                stats.potentialCommission
              )?.toLocaleString() || 0
            }`}
            icon={<MonetizationOn />}
            color="success"
          />
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="title" gutterBottom>
              Monthly Commission
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={monthlyCommission}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <ChartTooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" name="Commission (KSH)" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="title" gutterBottom>
              Commission Distribution
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={commissionDistribution}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                    label={({ name, value, percent }) =>
                      `${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {commissionDistribution.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <ChartTooltip
                    formatter={(value) => `KSH ${value.toLocaleString()}`}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Quick Actions */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="title" gutterBottom>
          Quick Actions
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<Speed />}
              onClick={() => {
                navigate("/affiliate/performance");
                /* Implement share functionality */
              }}
            >
              Performance
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AccountBalance />}
              onClick={() => {
                navigate("/affiliate/payments");
                /* Implement withdrawal */
              }}
            >
              Payments
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<Euro />}
              onClick={() => {
                navigate("/affiliate/commissions");
                /* Implement performance view */
              }}
            >
              Commissions
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<People />}
              onClick={() => {
                navigate("/affiliate/list");
                /* Implement referrals view */
              }}
            >
              View All Affiliates
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Dashboard;
