import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Stack,
  Button,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Chip,
  Paper,
} from "@mui/material";
import {
  TrendingUp,
  Groups,
  AttachMoney,
  Assessment,
  Payments,
  People,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import CountyMap from "./CountyMap";

const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

const Affiliates = ({ stats, refresh }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState({
    topAffiliates: [],
    referralDistribution: [],
    referralType: [],
    monthlyCommission: [],
    commissionDistribution: [],
  });

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const columns = [
    { field: "name", headerName: "Affiliate Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    {
      field: "totalCommission",
      headerName: "Total Commission (KSH)",
      flex: 1,
      renderCell: (params) => parseFloat(params.value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "active" ? "success" : "error"}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchPerformanceData();
  }, [refresh]);

  const fetchPerformanceData = async () => {
    setLoading(true);
    try {
      const [performanceResponse, dashboardResponse, topAffiliatesResponse] =
        await Promise.all([
          fetch("/api/affiliates/performance-metrics"),
          fetch("/api/affiliates/dashboard/stats"),
          fetch("/api/affiliates/top-affiliates"),
        ]);

      const performanceData = await performanceResponse.json();
      const topAffiliatesData = await topAffiliatesResponse.json();
      const dashboardData = await dashboardResponse.json();

      setPerformanceData({
        referralDistribution: performanceData.referralDistribution || [],
        referralType: performanceData.referralType || [],
        monthlyCommission: dashboardData.monthlyCommission || [],
        commissionDistribution: dashboardData.commissionDistribution || [],
        topAffiliates: topAffiliatesData.data.map((item) => ({
          id: item.affiliate.AffiliateID,
          name: item.affiliate.Name,
          email: item.affiliate.Email,
          phone: item.affiliate.Phone,
          totalCommission: item.totalCommission,
          status: item.affiliate.Status,
        })),
      });
    } catch (error) {
      console.error("Error fetching performance data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Stats Cards */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={<Groups />}
            label="Total Affiliates"
            value={stats.affiliates}
            color="#8B5DFF"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={<Groups />}
            label="Total Referrals"
            value={stats.referrals}
            color="#2196F3"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={<AttachMoney />}
            label="Paid Commission"
            value={stats.paidCommission}
            color="#4CAF50"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={<AttachMoney />}
            label="Pending Commission"
            value={stats.pendingCommission}
            color="#FFA726"
          />
        </Grid>
      </Grid>

      {/* Tabs Section */}
      <Card sx={{ borderRadius: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
          <Tabs
            value={selectedTab}
            onChange={(e, v) => setSelectedTab(v)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root": {
                minHeight: 64,
                textTransform: "none",
                fontSize: "1rem",
              },
              "& .Mui-selected": {
                fontWeight: "bold",
              },
            }}
          >
            <Tab
              icon={<Assessment />}
              label="Performance"
              iconPosition="start"
            />
            <Tab icon={<Payments />} label="Commissions" iconPosition="start" />
            <Tab
              icon={<People />}
              label="Active Affiliates"
              iconPosition="start"
            />
          </Tabs>
        </Box>

        <Divider />

        <Box sx={{ p: 3 }}>
          {selectedTab === 0 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Performance Metrics
              </Typography>

              <Grid container spacing={3}>
                {/* Referral Distribution Chart */}
                <Grid item xs={12} md={8}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Referral Distribution
                    </Typography>
                    <Box height={300}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={performanceData.referralDistribution}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <ChartTooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            name="Referrals"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>

                {/* Referral Type Pie Chart */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Referral Type
                    </Typography>
                    <Box height={300}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={performanceData.referralType}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label
                          >
                            {performanceData.referralType.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              )
                            )}
                          </Pie>
                          <ChartTooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Commission History
              </Typography>

              <Grid container spacing={3}>
                {/* Monthly Commission Chart */}
                <Grid item xs={12} md={8}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Monthly Commission
                    </Typography>
                    <Box height={300}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={performanceData.monthlyCommission}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <ChartTooltip />
                          <Legend />
                          <Bar
                            dataKey="value"
                            fill="#8884d8"
                            name="Commission (KSH)"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>

                {/* Commission Distribution Pie Chart */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Commission Distribution
                    </Typography>
                    <Box height={300}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={performanceData.commissionDistribution.map(
                              (item) => ({
                                ...item,
                                value: parseInt(item.value),
                              })
                            )}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="name"
                            label={({ name, percent }) =>
                              `${(percent * 100).toFixed(0)}%`
                            }
                          >
                            {performanceData.commissionDistribution.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              )
                            )}
                          </Pie>
                          <Legend />
                          <ChartTooltip
                            formatter={(value) =>
                              `KSH ${value.toLocaleString()}`
                            }
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Top Performing Affiliates
              </Typography>

              <Box sx={{ height: 400 }}>
                {loading ? (
                  <Box display="flex" justifyContent="center" p={3}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <DataGrid
                    rows={performanceData.topAffiliates}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Card>

      <Box mt={4}>
        <CountyMap
          url="/api/counties/affiliate-distribution"
          height={600}
          title="Distribution by County"
          color="#219B9D"
        />
      </Box>
    </Box>
  );
};

export default Affiliates;
