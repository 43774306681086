import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  Alert,
  Button,
  Card,
  Grid,
  useTheme,
} from "@mui/material";
import {
  Add as AddIcon,
  Close,
  Search,
  Person,
  Email,
  Phone,
  Badge,
  CheckCircle,
  Warning,
  SupervisorAccount,
  Refresh,
} from "@mui/icons-material";

const FilterSection = ({
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Collection Agents
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefresh}
              size="small"
            >
              Refresh
            </Button>
          </Stack>
        </Box>

        <Box display="flex" gap={2} justifyContent="right" alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

const AssignSupervisorDialog = ({
  open,
  onClose,
  agent,
  agents,
  selectedSupervisor,
  onSupervisorChange,
  onSave,
  onDeactivate,
  error,
  loading,
  deactivateLoading,
}) => {
  if (!agent) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <SupervisorAccount sx={{ color: "text.secondary" }} />
          <Typography variant="h6" flexGrow={1}>
            Assign Supervisor
          </Typography>
          <IconButton onClick={onClose} size="small">
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Agent Details
            </Typography>
            <Stack direction="row" spacing={2}>
              <Chip icon={<Person />} label={agent.Name} size="small" />
              <Chip icon={<Email />} label={agent.Email} size="small" />
              <Chip icon={<Phone />} label={agent.Phone} size="small" />
            </Stack>
          </Box>

          <FormControl fullWidth>
            <InputLabel>Supervisor</InputLabel>
            <Select
              label="Supervisor"
              value={selectedSupervisor}
              onChange={onSupervisorChange}
            >
              {agents?.data?.map((agent) => (
                <MenuItem key={agent.UserID} value={agent.UserID}>
                  {agent.Name} - {agent.Phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {error && (
            <Alert
              severity={error.includes("success") ? "success" : "warning"}
              variant="outlined"
            >
              {error}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onDeactivate(!agent.Status)}
          disabled={deactivateLoading}
        >
          {deactivateLoading
            ? "Updating..."
            : agent.Status
            ? "Deactivate"
            : "Activate"}
        </Button>
        <Button variant="contained" onClick={onSave} disabled={loading}>
          {loading ? "Saving Changes..." : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function Agents() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [dloading, setDLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [agentDetails, setAgentDetails] = useState({
    Name: "",
    Email: "",
    Phone: "",
    NationalID: "",
  });
  const [agents, setAgents] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [user, setUser] = useState(null);

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/auth?offset=${offset * 10}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/admin?department=Debt Collection`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setAgents(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Search functionality
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/auth?${column}=${value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  // Handle row click to view agent details
  const handleRowClick = (agent) => {
    setSelectedAgent(agent);
    setSelectedSupervisor(agent.supervisorId);
    setAgentDetails({
      Name: agent.Name,
      Email: agent.Email,
      Phone: agent.Phone,
      NationalID: agent.NationalID,
    });
    setOpenDetailDialog(true);
  };

  // Handle agent update
  const handleSaveChanges = () => {
    if (agents == null || agents.data.length == 0) return;
    setUpdateLoading(true);

    fetch(`/api/auth/${selectedAgent.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ supervisorId: selectedSupervisor }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        else {
          const errorText = await res.text();
          throw new Error(errorText || "Failed to update agent");
        }
      })
      .then(() => {
        setError("Agent updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setUpdateLoading(false));
  };

  // Handle agent deactivation
  const handleDeactivateAgent = (status, id) => {
    setDLoading(true);
    fetch(`/api/auth/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Status: status,
      }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to update agent status");
      })
      .then(() => {
        setError("Status updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setDLoading(false));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Agents Assignment
      </Typography>

      <FilterSection
        column={column}
        onColumnChange={(e) => setColumn(e.target.value)}
        searchValue={searchValue}
        onSearch={handleSearch}
        onRefresh={() => setRefresh(!refresh)}
      />

      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>ID Number</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Supervisor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((agent, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(agent)}
                      hover
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <Chip
                          label={offset * 10 + index + 1}
                          size="small"
                          sx={{ minWidth: 40 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Person sx={{ color: "text.secondary" }} />
                          <Typography variant="body2">{agent.Name}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Email
                            sx={{ color: "text.secondary", fontSize: "1rem" }}
                          />
                          <Typography variant="body2">{agent.Email}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Phone
                            sx={{ color: "text.secondary", fontSize: "1rem" }}
                          />
                          <Typography variant="body2">{agent.Phone}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Badge
                            sx={{ color: "text.secondary", fontSize: "1rem" }}
                          />
                          <Typography variant="body2">
                            {agent.NationalID}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={
                            agent.Status ? (
                              <CheckCircle sx={{ fontSize: "1rem" }} />
                            ) : (
                              <Warning sx={{ fontSize: "1rem" }} />
                            )
                          }
                          label={agent.Status ? "Active" : "Inactive"}
                          size="small"
                          color={agent.Status ? "success" : "warning"}
                        />
                      </TableCell>
                      <TableCell>
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : user ? (
                          <Typography variant="body2">{user.Name}</Typography>
                        ) : (
                          "..."
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No agents found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <AssignSupervisorDialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        agent={selectedAgent}
        agents={agents}
        selectedSupervisor={selectedSupervisor}
        onSupervisorChange={(e) => setSelectedSupervisor(e.target.value)}
        onSave={handleSaveChanges}
        onDeactivate={handleDeactivateAgent}
        error={error}
        loading={updateLoading}
        deactivateLoading={dloading}
      />
    </Box>
  );
}
