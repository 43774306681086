import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Stack,
  Grid,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Edit,
  Delete,
  Search,
  Add,
  Refresh,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

const AffiliatesList = () => {
  const [affiliates, setAffiliates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Status: "active",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [affiliateToDelete, setAffiliateToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAffiliates = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `/api/affiliates?offset=${
          paginationModel?.page * paginationModel?.pageSize
        }&limit=${paginationModel?.pageSize}`
      );
      const data = await response.json();
      setAffiliates(data);
    } catch (error) {
      console.error("Error fetching affiliates:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliates();
  }, [paginationModel]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenDialog = (affiliate) => {
    setSelectedAffiliate(affiliate);
    if (affiliate) {
      setFormData({
        Name: affiliate.Name || "",
        Email: affiliate.Email || "",
        Phone: affiliate.Phone || "",
        Status: affiliate.Status || "active",
      });
    } else {
      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Status: "active",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAffiliate(null);
  };

  const handleDelete = async (affiliateId) => {
    setDeleteDialogOpen(false);
    setSubmitLoading(true);
    try {
      const response = await fetch(`/api/affiliates/${affiliateId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        enqueueSnackbar("Affiliate deleted successfully", {
          variant: "success",
        });
        fetchAffiliates();
      } else {
        const data = await response.json();
        enqueueSnackbar(data.message || "Failed to delete affiliate", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting affiliate:", error);
      enqueueSnackbar("Failed to delete affiliate", { variant: "error" });
    } finally {
      setSubmitLoading(false);
      setAffiliateToDelete(null);
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "active":
        return "success";
      case "pending":
        return "warning";
      case "suspended":
        return "error";
      default:
        return "default";
    }
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateAffiliate = async () => {
    setSubmitLoading(true);
    try {
      const response = await fetch(
        `/api/affiliates/${selectedAffiliate.AffiliateID}`,
        {
          credentials: "include",
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        enqueueSnackbar("Affiliate updated successfully", {
          variant: "success",
        });
        handleCloseDialog();
        fetchAffiliates();
      } else {
        enqueueSnackbar(data.message || "Failed to update affiliate", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error updating affiliate:", error);
      enqueueSnackbar("Failed to update affiliate", { variant: "error" });
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSaveAffiliate = async () => {
    setSubmitLoading(true);
    try {
      const response = await fetch("/api/affiliates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        enqueueSnackbar("Affiliate saved successfully", { variant: "success" });
        handleCloseDialog();
        fetchAffiliates();
      } else {
        enqueueSnackbar(data.message || "Failed to save affiliate", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error saving affiliate:", error);
      enqueueSnackbar("Failed to save affiliate", { variant: "error" });
    } finally {
      setSubmitLoading(false);
    }
  };

  const columns = [
    {
      field: "No",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        return (
          paginationModel?.page * paginationModel?.pageSize +
          affiliates?.data?.indexOf(params?.row) +
          1
        );
      },
    },
    { field: "Name", headerName: "Name", flex: 1 },
    {
      field: "contactInfo",
      headerName: "Contact Info",
      flex: 1,
      renderCell: (params) => (
        <Box height="100%" display="flex" alignItems="center">
          <Box height="fit-content">
            <Typography variant="body2">{params?.row?.Email}</Typography>

            <Typography variant="body2" color="textSecondary">
              {params?.row?.Phone}
            </Typography>
          </Box>
        </Box>
      ),
    },
    { field: "ReferralCode", headerName: "Code" },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          label={params?.value}
          color={getStatusColor(params?.value)}
          size="small"
        />
      ),
    },
    {
      field: "TotalReferrals",
      headerName: "Referrals",
      type: "number",
      renderCell: (params) => params?.row?.referrals?.length,
    },
    {
      field: "createdAt",
      headerName: "Joined",
      renderCell: (params) => new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", height: "100%", justifyContent: "center" }}>
          <Tooltip title="View Details">
            <IconButton
              size="small"
              onClick={() => handleOpenDialog(params?.row)}
            >
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={() => handleOpenDialog(params?.row)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setAffiliateToDelete(params?.row);
                setDeleteDialogOpen(true);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box mt={8}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h6">Affiliates Management</Typography>
      </Stack>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={affiliates?.data ?? []}
          columns={columns}
          getRowId={(row) => row.AffiliateID}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slotProps={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          autoHeight
          density="comfortable"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      </Box>

      {/* Affiliate Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedAffiliate ? "Edit Affiliate" : "New Affiliate"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Name"
                name="Name"
                value={formData.Name}
                onChange={handleFormChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                name="Email"
                type="email"
                value={formData.Email}
                onChange={handleFormChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone"
                name="Phone"
                value={formData.Phone}
                onChange={handleFormChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Status"
                name="Status"
                value={formData.Status}
                onChange={handleFormChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="suspended">Suspended</option>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={submitLoading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={
              selectedAffiliate ? handleUpdateAffiliate : handleSaveAffiliate
            }
            loading={submitLoading}
          >
            {selectedAffiliate ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Add Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete affiliate {affiliateToDelete?.Name}?
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            disabled={submitLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={() => handleDelete(affiliateToDelete?.AffiliateID)}
            loading={submitLoading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AffiliatesList;
