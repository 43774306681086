import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  IconButton,
  useTheme,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Visibility,
  Receipt,
  AccountBalance,
  AccountBalanceWallet,
  Payment,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

const DisbursementDetailsDialog = ({ disbursement, open, onClose }) => {
  if (!disbursement) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Receipt color="primary" />
          <Typography variant="h6">Disbursement Details</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          {/* Transaction Details */}
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Transaction Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Amount
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    KES {disbursement.transactionAmount.toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Receipt Number
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {disbursement.transactionReceipt}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Date
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {disbursement.transactionDate}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Type
                  </Typography>
                  <Chip
                    label={disbursement.type}
                    color={
                      disbursement.type === "Loan Disbursement"
                        ? "info"
                        : "success"
                    }
                    size="small"
                  />
                </Box>
              </Stack>
            </Card>
          </Box>

          {/* Recipient Details */}
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Recipient Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {disbursement.recipientName}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {disbursement.recipientPhone}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Registration Status
                  </Typography>
                  <Chip
                    label={
                      disbursement.recipientIsRegistered
                        ? "Registered"
                        : "Not Registered"
                    }
                    color={
                      disbursement.recipientIsRegistered ? "success" : "warning"
                    }
                    size="small"
                  />
                </Box>
              </Stack>
            </Card>
          </Box>

          {/* Balance Information */}
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Account Balances
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Utility Account
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    KES {disbursement.utilityAccountBalance.toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Working Account
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    KES {disbursement.workingAccountBalance.toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Charges Account
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    KES {disbursement.chargesAccountBalance.toLocaleString()}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function Disbursements() {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [disbursements, setDisbursements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDisbursement, setSelectedDisbursement] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchDisbursements = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `/api/payments/disbursements?offset=${
            paginationModel.page * paginationModel.pageSize
          }&limit=${paginationModel.pageSize}${
            selectedTab == 0
              ? ""
              : `&type=${
                  selectedTab == 1 ? "Loan Disbursement" : "Affiliate Payment"
                }`
          }`
        );
        const data = await response.json();
        setDisbursements(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching disbursements:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchDisbursements();
  }, [paginationModel, selectedTab]);

  const columns = [
    {
      field: "transactionAmount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          justifyContent="space-between"
        >
          <Typography>KES {(params.value - 0).toLocaleString()}</Typography>
        </Box>
      ),
    },
    {
      field: "recipientName",
      headerName: "Recipient",
      flex: 1,
    },
    {
      field: "recipientPhone",
      headerName: "Phone",
    },
    {
      field: "transactionReceipt",
      headerName: "Receipt",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Loan Disbursement" ? "info" : "success"}
          size="small"
        />
      ),
    },
    {
      field: "transactionDate",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          <Typography>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpenDetails(params.row)}
          sx={{
            color: theme.palette.primary.main,
            "&:hover": { bgcolor: theme.palette.primary.lighter },
          }}
        >
          <Visibility fontSize="small" />
        </IconButton>
      ),
    },
  ];

  const handleOpenDetails = (disbursement) => {
    setSelectedDisbursement(disbursement);
    setOpenDetails(true);
  };

  return (
    <Box sx={{ mt: 9, px: 2 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Disbursements
      </Typography>

      <Card sx={{ mb: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          sx={{
            px: 2,
            pt: 2,
            "& .MuiTab-root": {
              minHeight: 64,
              textTransform: "none",
              fontSize: "1rem",
            },
          }}
        >
          <Tab icon={<Payment />} label="All" iconPosition="start" />
          <Tab icon={<AccountBalance />} label="Loans" iconPosition="start" />
          <Tab
            icon={<AccountBalanceWallet />}
            label="Affiliate Payments"
            iconPosition="start"
          />
        </Tabs>
      </Card>

      <Card>
        <Box p={3}>
          <DataGrid
            rows={disbursements.data || []}
            columns={columns}
            pageSize={10}
            rowCount={disbursements.total || 0}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPaginationModel(newPaginationModel);
            }}
            autoHeight
            loading={loading}
            disableSelectionOnClick
          />
        </Box>
      </Card>

      <DisbursementDetailsDialog
        disbursement={selectedDisbursement}
        open={openDetails}
        onClose={() => {
          setOpenDetails(false);
          setSelectedDisbursement(null);
        }}
      />
    </Box>
  );
}
