import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  AccessTime,
  AccountBalance,
  AccountCircle,
  CalendarToday,
  Call,
  CheckCircle,
  MonetizationOn,
  Receipt,
  TrendingUp,
  Warning,
  WarningAmber,
  WhatsApp,
  TimelineRounded,
  ErrorOutline,
} from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Commissions = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [commissions, setCommissions] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [stats, setStats] = useState({
    paidCommission: 0,
    failedCommission: 0,
    potentialCommission: 0,
    pendingCommission: 0,
  });

  useEffect(() => {
    fetchCommissions();
    fetchDashboardData();
  }, [affiliate, paginationModel]);

  const fetchDashboardData = async () => {
    try {
      const statsResponse = await fetch(`/api/affiliates/dashboard/stats`, {
        credentials: "include",
      });
      const statsData = await statsResponse.json();

      if (statsResponse.ok) {
        setStats({
          totalCommission:
            statsData.paidCommission + statsData.pendingCommission || 0,
          paidCommission: statsData.paidCommission || 0,
          pendingCommission: statsData.pendingCommission || 0,
          failedCommission: statsData.failedCommission || 0,
          potentialCommission: statsData.potentialCommission || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCommissions = async () => {
    try {
      const offset = paginationModel.page * paginationModel.pageSize;
      const response = await fetch(
        `/api/affiliates/commissions?limit=${paginationModel.pageSize}&offset=${offset}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.ok) {
        setCommissions(data.data);
        setTotalRows(data.total);
      }
    } catch (error) {
      console.error("Error fetching commissions:", error);
    } finally {
      setLoading(false);
    }
  };

  const StatCard = ({ title, value, icon, color }) => (
    <Card sx={{ height: "100%", p: 1.5 }}>
      <Stack direction="row" spacing={1} alignItems="center" height="100%">
        <Box
          sx={{
            color: `${color}.main`,
            bgcolor: `${color}.lighter`,
            p: 1,
            borderRadius: 2,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="center"
          height="100%"
        >
          <Box>
            <Typography color="text.secondary" variant="body2" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5">
              KSH {value?.toLocaleString("en-US", { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Card>
  );

  const handleRowClick = (params) => {
    setSelectedCommission(params.row);
    setOpenDialog(true);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "success";
      case "pending":
        return "warning";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const needsFollowUp = (loan) => {
    if (!loan) return false;

    const dueDate = new Date(loan.repaymentDate);
    const today = new Date();
    const twoDaysFromNow = new Date();
    twoDaysFromNow.setDate(today.getDate() + 2);

    return dueDate <= twoDaysFromNow || today > dueDate;
  };

  const getLoanStatusInfo = (loan) => {
    if (!loan) return { color: "default", message: "" };

    const dueDate = new Date(loan.repaymentDate);
    const today = new Date();
    const twoDaysFromNow = new Date();
    twoDaysFromNow.setDate(today.getDate() + 2);

    if (today > dueDate) {
      return {
        color: "error",
        message: `Overdue by ${Math.floor(
          (today - dueDate) / (1000 * 60 * 60 * 24)
        )} days`,
      };
    } else if (dueDate <= twoDaysFromNow) {
      return {
        color: "warning",
        message: "Due in less than 2 days",
      };
    }
    return { color: "success", message: "On track" };
  };

  const columns = [
    {
      field: "No",
      headerName: "No",
      width: 60,
      renderCell: (params) => {
        return (
          paginationModel.page * paginationModel.pageSize +
          commissions.indexOf(params.row) +
          1
        );
      },
    },
    {
      field: "DueTo",
      headerName: "Due To",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" height="100%" gap={2}>
            <Box>
              <Typography variant="body2" fontWeight={600}>
                {params?.row?.affiliate?.Name}{" "}
              </Typography>
              <Typography variant="body2">
                {params?.row?.affiliate?.Phone}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "From",
      headerName: "From",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="center" gap={1}>
          <Typography>
            {params?.row?.Type === "Loan Commission"
              ? "Loanee: " +
                params?.row?.loan?.user?.firstName +
                " " +
                params?.row?.loan?.user?.lastName
              : "Referral: " + (params?.row?.Referrer ?? "N/A")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: 120,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="center" gap={1}>
          <Typography>
            KES{" "}
            {(params.row.Amount - 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 120,
      renderCell: (params) => (
        <Box display="flex" height="100%" alignItems="center" gap={1}>
          <Typography>
            {new Date(params.row.createdAt).toLocaleDateString()}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Box
            sx={{
              px: 2,
              py: 0.5,
              borderRadius: 1,
              typography: "body2",
              fontWeight: "medium",
              backgroundColor: getStatusBgColor(params.row.Status),
              color: `${getStatusColor(params.row.Status)}.main`,
            }}
          >
            {params.row.Status}
          </Box>
        </Box>
      ),
    },
  ];

  const getStatusBgColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "#E9FCD4";
      case "pending":
      case "potential":
        return "#FFF5CC";
      case "failed":
        return "#FFE9E9";
      default:
        return "#F5F5F5";
    }
  };

  return (
    <Box mt={9}>
      <Typography variant="h5" gutterBottom>
        Commissions
      </Typography>

      {/* Stats Grid */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Paid Commission"
            value={stats.paidCommission}
            icon={<MonetizationOn />}
            color="success"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Failed Commission"
            value={stats.failedCommission}
            icon={<ErrorOutline />}
            color="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Potential Commission"
            value={stats.potentialCommission}
            icon={<TrendingUp />}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Pending Commission"
            value={stats.pendingCommission}
            icon={<AccountBalance />}
            color="warning"
          />
        </Grid>
      </Grid>

      {/* DataGrid */}
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={commissions || []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          getRowId={(row) => row.CommissionID}
          rowCount={totalRows}
          loading={loading}
          paginationMode="server"
          autoHeight
          onRowClick={handleRowClick}
          columnVisibilityModel={{
            "loan.remainingAmount": false,
            "loan.repaymentDate": false,
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              columnsButton: true,
              filterButton: true,
              densitySelector: true,
              exportButton: true,
            },
          }}
        />
      </Box>

      {/* Commission Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedCommission && (
          <>
            <DialogTitle>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Receipt />
                </Box>
                <Typography variant="h6">
                  Commission Details - {selectedCommission.loan?.orderNumber}
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3}>
                {/* Commission Status Section - New */}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      bgcolor: "primary.light",
                      color: "primary.contrastText",
                    }}
                  >
                    <CardContent>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={3}
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Box textAlign="center">
                          <Typography variant="overline">
                            Commission Amount
                          </Typography>
                          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                            KSH{" "}
                            {Number(selectedCommission.Amount).toLocaleString()}
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ bgcolor: "primary.contrastText", opacity: 0.2 }}
                        />
                        <Box textAlign="center">
                          <Typography variant="overline">Status</Typography>
                          <Box sx={{ mt: 1 }}>
                            <Chip
                              label={selectedCommission.Status}
                              color={getStatusColor(selectedCommission.Status)}
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                px: 2,
                              }}
                            />
                          </Box>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Existing Borrower Details Section */}
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <AccountCircle color="primary" />
                        Borrower Details
                      </Typography>
                      <Stack spacing={2}>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Name
                          </Typography>
                          <Typography>
                            {selectedCommission.loan?.user?.firstName}{" "}
                            {selectedCommission.loan?.user?.lastName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Phone Number
                          </Typography>
                          <Typography>
                            {selectedCommission.loan?.user?.phoneNumber}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Existing Loan Details Section */}
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <MonetizationOn color="primary" />
                        Loan Details
                      </Typography>
                      <Stack spacing={2}>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Loan Amount
                          </Typography>
                          <Typography>
                            KSH{" "}
                            {Number(
                              selectedCommission.loan?.amount +
                                selectedCommission.loan?.interest
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Remaining Amount
                          </Typography>
                          <Typography>
                            KSH{" "}
                            {Number(
                              selectedCommission.loan?.remainingAmount
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Due Date
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <CalendarToday fontSize="small" />
                            {new Date(
                              selectedCommission.loan?.repaymentDate
                            ).toLocaleDateString()}
                            {needsFollowUp(selectedCommission.loan) && (
                              <Chip
                                icon={
                                  getLoanStatusInfo(selectedCommission.loan)
                                    .color === "error" ? (
                                    <Warning />
                                  ) : (
                                    <AccessTime />
                                  )
                                }
                                label={
                                  getLoanStatusInfo(selectedCommission.loan)
                                    .message
                                }
                                color={
                                  getLoanStatusInfo(selectedCommission.loan)
                                    .color
                                }
                                size="small"
                              />
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Contact Section - Always visible but conditionally disabled */}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      bgcolor: needsFollowUp(selectedCommission.loan)
                        ? "warning.lighter"
                        : "grey.100",
                      transition: "background-color 0.3s",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              color: needsFollowUp(selectedCommission.loan)
                                ? "warning.dark"
                                : "text.secondary",
                            }}
                          >
                            <Warning
                              color={
                                needsFollowUp(selectedCommission.loan)
                                  ? "warning"
                                  : "disabled"
                              }
                            />
                            Contact Borrower
                          </Typography>
                          {needsFollowUp(selectedCommission.loan) && (
                            <Chip
                              icon={<AccessTime />}
                              label={
                                getLoanStatusInfo(selectedCommission.loan)
                                  .message
                              }
                              color={
                                getLoanStatusInfo(selectedCommission.loan).color
                              }
                              size="small"
                            />
                          )}
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                          {needsFollowUp(selectedCommission.loan)
                            ? "This loan requires immediate follow-up. Please contact the borrower."
                            : "Contact options are disabled as this loan is currently on track."}
                        </Typography>

                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                          sx={{
                            opacity: needsFollowUp(selectedCommission.loan)
                              ? 1
                              : 0.7,
                          }}
                        >
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<WhatsApp />}
                            href={
                              needsFollowUp(selectedCommission.loan)
                                ? `https://wa.me/${selectedCommission.loan?.user?.phoneNumber.replace(
                                    /\D/g,
                                    ""
                                  )}`
                                : undefined
                            }
                            target="_blank"
                            color="success"
                            disabled={!needsFollowUp(selectedCommission.loan)}
                            sx={{
                              "&.Mui-disabled": {
                                bgcolor: "success.light",
                                color: "white",
                                opacity: 0.5,
                              },
                            }}
                          >
                            WhatsApp
                          </Button>
                          <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<Call />}
                            href={
                              needsFollowUp(selectedCommission.loan)
                                ? `tel:${selectedCommission.loan?.user?.phoneNumber}`
                                : undefined
                            }
                            color="primary"
                            disabled={!needsFollowUp(selectedCommission.loan)}
                          >
                            Call
                          </Button>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Commission Timeline - New */}
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <TimelineRounded color="primary" />
                        Commission Timeline
                      </Typography>
                      <Timeline>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{ bgcolor: "primary.main" }} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle2">
                              Loan Created
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {new Date(
                                selectedCommission.loan?.createdAt
                              ).toLocaleString()}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor:
                                  selectedCommission.Status?.toLowerCase() ===
                                  "paid"
                                    ? "success.main"
                                    : selectedCommission.Status?.toLowerCase() ===
                                      "pending"
                                    ? "warning.main"
                                    : selectedCommission.Status?.toLowerCase() ===
                                      "failed"
                                    ? "error.main"
                                    : "grey.500",
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle2">
                              Commission {selectedCommission.Status}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {new Date(
                                selectedCommission.updatedAt
                              ).toLocaleString()}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Commissions;
