import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  Stack,
  Alert,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  TableSortLabel,
  Collapse,
  Chip,
} from "@mui/material";
import {
  Save,
  Refresh,
  Help,
  History,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CompareArrows,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const ChangeValue = styled("span")(({ theme, changed }) => ({
  color: changed ? theme.palette.success.main : theme.palette.text.primary,
  backgroundColor: changed ? theme.palette.success.light : "transparent",
  padding: changed ? theme.spacing(0.5, 1) : 0,
  borderRadius: changed ? theme.shape.borderRadius : 0,
}));

const DetailDialog = ({ open, onClose, previousValue, newValue }) => {
  // Helper function to format values for display
  const formatValue = (value) => {
    if (typeof value === "boolean") {
      return value ? "Enabled" : "Disabled";
    }
    if (typeof value === "number") {
      return value.toString();
    }
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return value;
  };

  // Helper function to determine if a nested value has changed
  const hasValueChanged = (prev, next) => {
    return JSON.stringify(prev) !== JSON.stringify(next);
  };

  // Component for rendering a section of changes
  const ChangeSection = ({ title, prevObj, newObj }) => {
    const keys = Object.keys(prevObj || {});

    // Helper to format section values
    const formatSectionValue = (value, key) => {
      if (!value) return "-";

      switch (key) {
        case "CommissionRates":
          return Object.entries(value)
            .map(([tier, rate]) => `${tier}: ${rate}%`)
            .join(", ");

        case "AffiliateCommission":
          return [
            `Referral: ${value.Referral} KES`,
            `L1: ${value.Level1Percentage}%`,
            `L2: ${value.Level2Percentage}%`,
            `L3: ${value.Level3Percentage}%`,
          ].join("\n");

        case "RefferedCustomerLoanRates":
          return Object.entries(value)
            .map(
              ([tier, rates]) =>
                `${tier}:\n  Premium: ${rates.Premium}%\n  Regular: ${rates.Regular}%`
            )
            .join("\n");

        case "RefferedCustomerRepaymentTerms":
          return Object.entries(value)
            .map(
              ([tier, terms]) =>
                `${tier}:\n  Premium: ${terms.Premium} days\n  Regular: ${terms.Regular} days`
            )
            .join("\n");

        case "PayoutSettings":
          return [
            `Minimum Amount: ${value.minimumAmount} KES`,
            `Frequency: ${value.payoutFrequency}`,
            `Processing Time: ${value.processingDays} days`,
          ].join("\n");

        case "UserBenefits":
          return [
            `First Loan Bonus: ${value.firstLoanBonus} KES`,
            `Loyalty Bonus: ${value.loyaltyBonus} KES`,
            `Max Bonus: ${value.maxBonusLimit} KES`,
          ].join("\n");

        case "SystemRules":
          return Object.entries(value)
            .map(
              ([rule, enabled]) =>
                `${rule.replace(/([A-Z])/g, " $1").trim()}: ${
                  enabled ? "✓" : "✗"
                }`
            )
            .join("\n");

        case "SystemNotifications":
          return [
            `Email: ${value.email || "-"}`,
            `Phone: ${value.phone || "-"}`,
          ].join("\n");

        case "WaylandAppVersion":
          return [
            `iOS: ${value.ios.version} (${value.ios.code})`,
            `Android: ${value.android.version} (${value.android.code})`,
          ].join("\n");

        default:
          return typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : value.toString();
      }
    };

    // Helper to compare nested values
    const getChangedFields = (prev, next, key) => {
      if (!prev || !next) return new Set();
      const changed = new Set();

      switch (key) {
        case "CommissionRates":
        case "SystemRules":
          Object.keys(next).forEach((k) => {
            if (prev[k] !== next[k]) changed.add(k);
          });
          break;

        case "RefferedCustomerLoanRates":
        case "RefferedCustomerRepaymentTerms":
          Object.keys(next).forEach((tier) => {
            if (prev[tier]?.Premium !== next[tier]?.Premium)
              changed.add(`${tier}.Premium`);
            if (prev[tier]?.Regular !== next[tier]?.Regular)
              changed.add(`${tier}.Regular`);
          });
          break;

        case "WaylandAppVersion":
          ["ios", "android"].forEach((platform) => {
            if (prev[platform]?.version !== next[platform]?.version)
              changed.add(`${platform}.version`);
            if (prev[platform]?.code !== next[platform]?.code)
              changed.add(`${platform}.code`);
          });
          break;

        default:
          if (JSON.stringify(prev) !== JSON.stringify(next)) {
            changed.add(key);
          }
      }

      return changed;
    };

    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom color="primary">
          {title}
        </Typography>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Setting</TableCell>
                <TableCell>Previous Value</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((key) => {
                const prevValue = prevObj[key];
                const newValue = newObj[key];
                const changedFields = getChangedFields(
                  prevValue,
                  newValue,
                  key
                );
                const changed = changedFields.size > 0;

                return (
                  <TableRow
                    key={key}
                    sx={{
                      backgroundColor: changed ? "action.hover" : "inherit",
                    }}
                  >
                    <TableCell>
                      {key.replace(/([A-Z])/g, " $1").trim()}
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        component="pre"
                        sx={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "monospace",
                          fontSize: "0.75rem",
                          m: 0,
                          color: changed ? "error.main" : "text.primary",
                        }}
                      >
                        {formatSectionValue(prevValue, key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        component="pre"
                        sx={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "monospace",
                          fontSize: "0.75rem",
                          m: 0,
                          color: changed ? "success.main" : "text.primary",
                        }}
                      >
                        {formatSectionValue(newValue, key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {changed ? (
                        <Tooltip title={Array.from(changedFields).join(", ")}>
                          <Chip
                            size="small"
                            color="primary"
                            label={`${changedFields.size} change${
                              changedFields.size > 1 ? "s" : ""
                            }`}
                          />
                        </Tooltip>
                      ) : (
                        <Chip
                          size="small"
                          variant="outlined"
                          label="Unchanged"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // Group settings into sections
  const sections = [
    {
      title: "Commission & Rates",
      data: {
        CommissionRates: previousValue?.CommissionRates,
        AffiliateCommission: previousValue?.AffiliateCommission,
        RefferedCustomerLoanRates: previousValue?.RefferedCustomerLoanRates,
      },
      newData: {
        CommissionRates: newValue?.CommissionRates,
        AffiliateCommission: newValue?.AffiliateCommission,
        RefferedCustomerLoanRates: newValue?.RefferedCustomerLoanRates,
      },
    },
    {
      title: "Terms & Payouts",
      data: {
        RefferedCustomerRepaymentTerms:
          previousValue?.RefferedCustomerRepaymentTerms,
        PayoutSettings: previousValue?.PayoutSettings,
      },
      newData: {
        RefferedCustomerRepaymentTerms:
          newValue?.RefferedCustomerRepaymentTerms,
        PayoutSettings: newValue?.PayoutSettings,
      },
    },
    {
      title: "User Benefits & Rules",
      data: {
        UserBenefits: previousValue?.UserBenefits,
        SystemRules: previousValue?.SystemRules,
      },
      newData: {
        UserBenefits: newValue?.UserBenefits,
        SystemRules: newValue?.SystemRules,
      },
    },
    {
      title: "System Configuration",
      data: {
        SystemNotifications: previousValue?.SystemNotifications,
        WaylandAppVersion: previousValue?.WaylandAppVersion,
      },
      newData: {
        SystemNotifications: newValue?.SystemNotifications,
        WaylandAppVersion: newValue?.WaylandAppVersion,
      },
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: { minHeight: "70vh" },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CompareArrows />
          <Typography variant="h6">Detailed Changes Comparison</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <ChangeSection
              key={section.title}
              title={section.title}
              prevObj={section.data}
              newObj={section.newData}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const HistoryRow = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{record.date}</TableCell>
        <TableCell>{record.user}</TableCell>
        <TableCell>
          <Button
            variant="text"
            color="primary"
            onClick={() => setDetailOpen(true)}
          >
            {record.category}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Changes
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {record.changes}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DetailDialog
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        previousValue={record.previousValue}
        newValue={record.newValue}
      />
    </>
  );
};

const SettingsHistoryDialog = ({ open, onClose, history }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Settings Change History</DialogTitle>
    <DialogContent dividers>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Date</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Changes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((record, index) => (
              <HistoryRow key={record.id || index} record={record} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const Settings = () => {
  const [settings, setSettings] = useState({
    CommissionRates: {
      Bronze: 5,
      Silver: 7,
      Gold: 10,
    },
    RefferedCustomerLoanRates: {
      Bronze: {
        Premium: 25,
        Regular: 20,
      },
      Silver: {
        Premium: 20,
        Regular: 18,
      },
      Gold: {
        Premium: 18,
        Regular: 15,
      },
    },
    RefferedCustomerRepaymentTerms: {
      Bronze: {
        Premium: 21,
        Regular: 14,
      },
      Silver: {
        Premium: 21,
        Regular: 14,
      },
      Gold: {
        Premium: 21,
        Regular: 14,
      },
    },
    SystemNotifications: {
      email: "",
      phone: "",
    },
    PayoutSettings: {
      minimumAmount: 10,
      payoutFrequency: "anytime",
      processingDays: 1,
    },
    AffiliateCommission: {
      Referral: 50,
      Level1Percentage: 10,
      Level2Percentage: 5,
      Level3Percentage: 2,
    },
    UserBenefits: {
      firstLoanBonus: 20,
      loyaltyBonus: 10,
      maxBonusLimit: 50,
    },
    SystemRules: {
      autoApproveAffiliates: false,
      requireKYC: true,
      allowMultipleReferralCodes: false,
      enableFraudDetection: true,
    },
    WaylandAppVersion: {
      ios: {
        code: 1,
        version: "1.0.0",
      },
      android: {
        code: 1,
        version: "1.0.0",
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [settingsHistory, setSettingsHistory] = useState([]);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/affiliates/settings");
      const data = await response.json();

      if (response.ok) {
        setSettings({
          CommissionRates: data.CommissionRates,
          RefferedCustomerLoanRates: data.RefferedCustomerLoanRates,
          RefferedCustomerRepaymentTerms: data.RefferedCustomerRepaymentTerms,
          SystemNotifications: data.SystemNotifications,
          PayoutSettings: data.PayoutSettings,
          AffiliateCommission: data.AffiliateCommission,
          UserBenefits: data.UserBenefits,
          SystemRules: data.SystemRules,
          WaylandAppVersion: data.WaylandAppVersion,
        });
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      enqueueSnackbar("Failed to load settings", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchHistory = async () => {
    setHistoryLoading(true);
    try {
      const response = await fetch("/api/affiliates/settings/history");
      const data = await response.json();

      if (response.ok) {
        setSettingsHistory(data);
      }
    } catch (error) {
      console.error("Error fetching history:", error);
      enqueueSnackbar("Failed to load history", { variant: "error" });
    } finally {
      setHistoryLoading(false);
    }
  };

  const handleSettingChange = (category, field, value, subCategory = null) => {
    setSettings((prev) => {
      if (subCategory) {
        return {
          ...prev,
          [category]: {
            ...prev[category],
            [subCategory]: {
              ...prev[category][subCategory],
              [field]: value,
            },
          },
        };
      }
      return {
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value,
        },
      };
    });
  };

  const handleSave = async () => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Save Changes?",
      text: "Are you sure you want to update the affiliate settings?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Save Changes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#9e9e9e",
    });

    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: "Saving Changes...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch("/api/affiliates/settings", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CommissionRates: settings.CommissionRates,
            RefferedCustomerLoanRates: settings.RefferedCustomerLoanRates,
            RefferedCustomerRepaymentTerms:
              settings.RefferedCustomerRepaymentTerms,
            SystemNotifications: settings.SystemNotifications,
            PayoutSettings: settings.PayoutSettings,
            AffiliateCommission: settings.AffiliateCommission,
            UserBenefits: settings.UserBenefits,
            SystemRules: settings.SystemRules,
            WaylandAppVersion: settings.WaylandAppVersion,
          }),
        });

        if (response.ok) {
          // Show success message
          await Swal.fire({
            title: "Success!",
            text: "Settings have been updated successfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to save settings");
        }
      } catch (error) {
        console.error("Error saving settings:", error);
        // Show error message
        await Swal.fire({
          title: "Error!",
          text: "Failed to save settings. Please try again.",
          icon: "error",
        });
      }
    }
  };

  const handleViewHistory = () => {
    setShowHistoryDialog(true);
    fetchHistory();
  };

  return (
    <Box mt={9}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            background: "linear-gradient(45deg, #2196F3, #3f51b5)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            mb: 4,
          }}
        >
          System Settings
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<History />}
            onClick={handleViewHistory}
          >
            View History
          </Button>
          <Button variant="contained" startIcon={<Save />} onClick={handleSave}>
            Save Changes
          </Button>
        </Stack>
      </Stack>

      <Grid container spacing={3}>
        {/* Commission Rates Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">Affiliate Commission Rates</Typography>
                <Tooltip title="Set commission rates for different affiliate tiers">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                {Object.entries(settings.CommissionRates).map(
                  ([tier, rate]) => (
                    <Grid item xs={12} key={tier}>
                      <TextField
                        fullWidth
                        label={`${tier} Tier Rate (%)`}
                        type="number"
                        value={rate}
                        onChange={(e) =>
                          handleSettingChange(
                            "CommissionRates",
                            tier,
                            Number(e.target.value)
                          )
                        }
                        size="small"
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Commission Rates Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">Affiliate Referral Rates</Typography>
                <Tooltip title="Set commission rates for different affiliate tiers">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                {Object.entries(settings.AffiliateCommission).map(
                  ([tier, rate]) => (
                    <Grid item xs={12} key={tier}>
                      <TextField
                        fullWidth
                        label={`${
                          tier == "Referral"
                            ? "Refferal Rate (Ksh)"
                            : tier == "Level1Percentage"
                            ? "Commission Rate from Level 1 Referral (%)"
                            : tier == "Level2Percentage"
                            ? "Commission Rate from Level 2 Referral (%)"
                            : tier == "Level3Percentage"
                            ? "Commission Rate from Level 3 Referral (%)"
                            : ""
                        }`}
                        type="number"
                        value={rate}
                        onChange={(e) =>
                          handleSettingChange(
                            "AffiliateCommission",
                            tier,
                            Number(e.target.value)
                          )
                        }
                        size="small"
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Repayment Terms Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">
                  Referred Customer Repayment Terms
                </Typography>
                <Tooltip title="Set repayment terms for referred customers">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              {Object.entries(settings.RefferedCustomerRepaymentTerms).map(
                ([tier, terms]) => (
                  <Box key={tier} mb={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {tier} Tier
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(terms).map(([type, days]) => (
                        <Grid item xs={6} key={`${tier}-${type}`}>
                          <TextField
                            fullWidth
                            label={`${type} Term (days)`}
                            type="number"
                            value={days}
                            onChange={(e) =>
                              handleSettingChange(
                                "RefferedCustomerRepaymentTerms",
                                type,
                                Number(e.target.value),
                                tier
                              )
                            }
                            size="small"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Loan Rates Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">
                  Referred Customer Loan Rates
                </Typography>
                <Tooltip title="Set loan rates for referred customers based on tier">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              {Object.entries(settings.RefferedCustomerLoanRates).map(
                ([tier, rates]) => (
                  <Box key={tier} mb={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {tier} Tier
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(rates).map(([type, rate]) => (
                        <Grid item xs={6} key={`${tier}-${type}`}>
                          <TextField
                            fullWidth
                            label={`${type} Rate (%)`}
                            type="number"
                            value={rate}
                            onChange={(e) =>
                              handleSettingChange(
                                "RefferedCustomerLoanRates",
                                type,
                                Number(e.target.value),
                                tier
                              )
                            }
                            size="small"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* System Notifications Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">System Notifications</Typography>
                <Tooltip title="Configure system notification recipients">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={settings.SystemNotifications.email}
                    onChange={(e) =>
                      handleSettingChange(
                        "SystemNotifications",
                        "email",
                        e.target.value
                      )
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    value={settings.SystemNotifications.phone}
                    onChange={(e) =>
                      handleSettingChange(
                        "SystemNotifications",
                        "phone",
                        e.target.value
                      )
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Payout Settings Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">Affiliate Payout Settings</Typography>
                <Tooltip title="Configure payout rules and thresholds">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Minimum Payout Amount (KSH)"
                    type="number"
                    value={settings.PayoutSettings.minimumAmount}
                    onChange={(e) =>
                      handleSettingChange(
                        "PayoutSettings",
                        "minimumAmount",
                        Number(e.target.value)
                      )
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Processing Day of Month"
                    type="number"
                    value={settings.PayoutSettings.processingDays}
                    onChange={(e) =>
                      handleSettingChange(
                        "PayoutSettings",
                        "processingDays",
                        Number(e.target.value)
                      )
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* User Benefits Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">User Benefits</Typography>
                <Tooltip title="Configure benefits for users who register with affiliate codes">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Loan Bonus (%)"
                    type="number"
                    value={settings.UserBenefits.firstLoanBonus}
                    onChange={(e) =>
                      handleSettingChange(
                        "UserBenefits",
                        "firstLoanBonus",
                        Number(e.target.value)
                      )
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Loyalty Bonus (%)"
                    type="number"
                    value={settings.UserBenefits.loyaltyBonus}
                    onChange={(e) =>
                      handleSettingChange(
                        "UserBenefits",
                        "loyaltyBonus",
                        Number(e.target.value)
                      )
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Maximum Bonus Limit (%)"
                    type="number"
                    value={settings.UserBenefits.maxBonusLimit}
                    onChange={(e) =>
                      handleSettingChange(
                        "UserBenefits",
                        "maxBonusLimit",
                        Number(e.target.value)
                      )
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* System Rules Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">System Rules</Typography>
                <Tooltip title="Configure system-wide rules and security settings">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.SystemRules.autoApproveAffiliates}
                        onChange={(e) =>
                          handleSettingChange(
                            "SystemRules",
                            "autoApproveAffiliates",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Auto-approve New Affiliates"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.SystemRules.requireKYC}
                        onChange={(e) =>
                          handleSettingChange(
                            "SystemRules",
                            "requireKYC",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Require KYC Verification"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.SystemRules.enableFraudDetection}
                        onChange={(e) =>
                          handleSettingChange(
                            "SystemRules",
                            "enableFraudDetection",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Enable Fraud Detection"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* App Version Card */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">App Versions</Typography>
                <Tooltip title="Manage app version requirements">
                  <IconButton size="small">
                    <Help />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    iOS
                  </Typography>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Version Code"
                      type="number"
                      value={settings.WaylandAppVersion.ios.code}
                      onChange={(e) =>
                        handleSettingChange(
                          "WaylandAppVersion",
                          "code",
                          Number(e.target.value),
                          "ios"
                        )
                      }
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Version String"
                      value={settings.WaylandAppVersion.ios.version}
                      onChange={(e) =>
                        handleSettingChange(
                          "WaylandAppVersion",
                          "version",
                          e.target.value,
                          "ios"
                        )
                      }
                      size="small"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Android
                  </Typography>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Version Code"
                      type="number"
                      value={settings.WaylandAppVersion.android.code}
                      onChange={(e) =>
                        handleSettingChange(
                          "WaylandAppVersion",
                          "code",
                          Number(e.target.value),
                          "android"
                        )
                      }
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Version String"
                      value={settings.WaylandAppVersion.android.version}
                      onChange={(e) =>
                        handleSettingChange(
                          "WaylandAppVersion",
                          "version",
                          e.target.value,
                          "android"
                        )
                      }
                      size="small"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Settings History Dialog */}
      <SettingsHistoryDialog
        open={showHistoryDialog}
        onClose={() => setShowHistoryDialog(false)}
        history={settingsHistory}
      />
    </Box>
  );
};

export default Settings;
