import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Grid,
  useTheme,
  CircularProgress,
} from "@mui/material";
import {
  Email,
  Message,
  Phone,
  WhatsApp,
  Search,
  Person,
  AccountBalance,
  CreditScore,
  Warning,
  CheckCircle,
  Badge,
} from "@mui/icons-material";
import dayjs from "dayjs";

const FilterSection = ({
  status,
  onStatusChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Customers
        </Typography>

        <Box display="flex" justifyContent="right" gap={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel>Customer Type</InputLabel>
              <Select
                value={status}
                onChange={onStatusChange}
                label="Customer Type"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Person
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="All">All Customers</MenuItem>
                <MenuItem value="true">Premium</MenuItem>
                <MenuItem value="false">Regular</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID Number</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

const CustomerDetailsDialog = ({ customer, open, onClose }) => {
  const theme = useTheme();

  if (!customer) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Person sx={{ color: "text.secondary" }} />
          <Typography variant="h6">Customer Details</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6" gutterBottom>
              {customer.firstName} {customer.lastName}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Chip
                icon={<Badge />}
                label={`ID: ${customer.idCardNumber}`}
                size="small"
              />
              <Chip icon={<Email />} label={customer.email} size="small" />
              <Chip
                icon={<Phone />}
                label={customer.phoneNumber
                  .replace("+", "")
                  .replace("254", "0")}
                size="small"
              />
            </Stack>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Credit Information
            </Typography>
            <Chip
              icon={<CreditScore />}
              label={`Credit Limit: KES ${customer.CreditScore?.creditLimit.toLocaleString()}`}
              color="primary"
              variant="outlined"
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Recent Loans
            </Typography>
            <Stack spacing={1}>
              {customer.loans.map((loan, index) => (
                <Card key={index} variant="outlined" sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Chip
                      size="small"
                      label={dayjs(loan.repaymentDate).format("MMM D, YYYY")}
                    />
                    <Typography color="primary" fontWeight="medium">
                      KES {loan.paymentAmount.toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {loan.overdueDays} days overdue
                    </Typography>
                    <Chip
                      size="small"
                      color={loan.status === "Paid" ? "success" : "warning"}
                      label={loan.status}
                      icon={
                        loan.status === "Paid" ? <CheckCircle /> : <Warning />
                      }
                    />
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function DCCustomers() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const checkOverdue = (loans) => {
    return loans.some((loan) => loan.overdueDays > 0);
  };

  // Function to check if any loan is defaulted (more than 60 days since repayment)
  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Paid" && daysSinceRepayment > 60;
    });
  };

  const handleOpenDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };

  return (
    <Box sx={{ mt: 9, px: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Customer Management
      </Typography>

      <FilterSection
        status={status}
        onStatusChange={(e) => {
          setOffset(0);
          setStatus(e.target.value);
        }}
        column={column}
        onColumnChange={(e) => setColumn(e.target.value)}
        searchValue={searchValue}
        onSearch={handleSearch}
      />

      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>ID Number</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Credit Limit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : data?.data?.length > 0 ? (
                  data.data.map((customer, index) => (
                    <CustomerRow
                      key={customer.id}
                      customer={customer}
                      index={index}
                      offset={offset}
                      onSelect={() => handleOpenDetails(customer)}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No customers found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <CustomerDetailsDialog
        customer={selectedUser}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
    </Box>
  );
}

const CustomerRow = ({ customer, index, offset, onSelect }) => {
  const overdue = customer.loans.some((loan) => loan.overdueDays > 0);

  return (
    <TableRow
      hover
      onClick={() => onSelect(customer)}
      sx={{ cursor: "pointer" }}
    >
      <TableCell>
        <Chip
          label={offset * 10 + index + 1}
          size="small"
          sx={{ minWidth: 40 }}
        />
      </TableCell>
      <TableCell>
        <Chip
          label={customer.isVerified ? "Premium" : "Regular"}
          size="small"
          color={customer.isVerified ? "success" : "warning"}
          icon={customer.isVerified ? <CheckCircle /> : <Warning />}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Person sx={{ color: "text.secondary" }} />
          <Box>
            <Typography variant="body2">
              {customer.firstName} {customer.lastName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {customer.email}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>{customer.idCardNumber}</TableCell>
      <TableCell>
        <Chip
          icon={<AccountBalance sx={{ fontSize: "1rem" }} />}
          label={customer.loans.length}
          size="small"
        />
      </TableCell>
      <TableCell>
        <Chip
          icon={overdue ? <Warning /> : <CheckCircle />}
          label={overdue ? "Overdue" : "No Overdue"}
          size="small"
          color={overdue ? "error" : "success"}
        />
      </TableCell>
      <TableCell>
        <Chip
          icon={<CreditScore sx={{ fontSize: "1rem" }} />}
          label={`KES ${customer.CreditScore?.creditLimit.toLocaleString()}`}
          size="small"
          color="primary"
        />
      </TableCell>
    </TableRow>
  );
};
