import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Tabs,
  Tab,
  Stack,
  Grid,
  useTheme,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import {
  DateRange,
  Money,
  SupportAgent,
  Refresh,
  Search,
  AccountBalance,
  Person,
  CalendarToday,
  Schedule,
  Warning,
  CheckCircle,
} from "@mui/icons-material";
import { CurrencyDollar, User } from "@phosphor-icons/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const FilterSection = ({
  column,
  onColumnChange,
  searchValue,
  onSearch,
  startDate,
  endDate,
  onDateChange,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Loans
        </Typography>

        <Box display="flex" justifyContent="space-between" gap={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => onDateChange("start", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => onDateChange("end", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={onColumnChange}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID Number</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

const StatusTabs = ({ value, onChange }) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        mb: 3,
        "& .MuiTab-root": {
          minHeight: 48,
          textTransform: "capitalize",
        },
      }}
    >
      <Tab
        label="Active"
        value="Approved"
        icon={<CheckCircle sx={{ fontSize: "1.2rem" }} />}
        iconPosition="start"
      />
      <Tab
        label="Extension"
        value="Extension"
        icon={<Schedule sx={{ fontSize: "1.2rem" }} />}
        iconPosition="start"
      />
      <Tab
        label="Overdue"
        value="Overdue"
        icon={<Warning sx={{ fontSize: "1.2rem" }} />}
        iconPosition="start"
      />
      <Tab
        label="Defaulted"
        value="Defaulted"
        icon={<Warning sx={{ fontSize: "1.2rem" }} />}
        iconPosition="start"
      />
      <Tab
        label="Paid"
        value="Paid"
        icon={<CheckCircle sx={{ fontSize: "1.2rem" }} />}
        iconPosition="start"
      />
    </Tabs>
  );
};

export default function DCLoans(props) {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Approved");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    let url = `/api/loans?offset=${offset * 10}&supervisorId=${
      props?.user?.UserID
    }&includeUsers=true&includePayments=true&includeFollowUps=true&${
      selected == "Approved"
        ? `loanStatus=Approved&loanStatus=Extension`
        : `loanStatus=${selected}`
    }`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&supervisorId=${
          props?.user?.UserID
        }&includeUsers=true&includePayments=trueincludeFollowUps=true&${
          selected == "Approved"
            ? `loanStatus=Approved&loanStatus=Extension`
            : `loanStatus=${selected}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const handleTabChange = (event, newValue) => {
    setOffset(0);
    setSelected(newValue);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleDateChange = (type, value) => {
    if (type === "start") setStartDate(value);
    else setEndDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9, px: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            background: "linear-gradient(45deg, #2196F3, #3f51b5)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            mb: 4,
          }}
        >
          Loan Management
        </Typography>

        <StatusTabs value={selected} onChange={handleTabChange} />

        <FilterSection
          column={column}
          onColumnChange={(e) => setColumn(e.target.value)}
          searchValue={searchValue}
          onSearch={handleSearch}
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />

        <Card
          elevation={0}
          sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
        >
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Loan Amount</TableCell>
                    <TableCell>Order No</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Supervisor</TableCell>
                    <TableCell>Agent</TableCell>
                    <TableCell>Overdue Days</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  ) : data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <LoanRow
                        key={item.id}
                        item={item}
                        index={index}
                        offset={offset}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No loans found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
              {data && (
                <Pagination
                  count={Math.ceil(data.total / 10)}
                  page={offset + 1}
                  onChange={(e, value) => setOffset(value - 1)}
                  color="primary"
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </LocalizationProvider>
  );
}

const LoanRow = ({ item, index, offset, refresh, setRefresh }) => {
  const [user, setUser] = useState(null);
  const [agent, setAgent] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);

  const [loading, setLoading] = useState(false);
  const [aloading, setALoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (item) {
      fetch(`/api/users/me/${item.userId}`, { credentials: "include" })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
      if (item.assignedAgentId) {
        fetch(`/api/auth/${item.assignedAgentId}`, { credentials: "include" })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error();
          })
          .then((data) => {
            setAgent(data);
          })
          .catch((e) => {});
      }
      if (item.supervisorId) {
        fetch(`/api/admin?id=${item.supervisorId}`, { credentials: "include" })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error();
          })
          .then((data) => {
            if (data?.data?.length > 0) {
              setSupervisor(data.data[0]);
            }
          })
          .catch((e) => {});
      }
    }
  }, [item, refresh]);

  function getAgents() {
    if (item) {
      fetch(`/api/auth`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setLoading(false);
          setAgents(data);
          setOpenAssignDialog(true);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  const handleAssignAgent = () => {
    if (!selectedLoan || !selectedAgentId) return;
    setALoading(true);
    fetch(`/api/loans/${selectedLoan.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assignedAgentId: selectedAgentId }),
    })
      .then((res) => {
        if (res.ok) {
          setError("Agent assignment succefful!");
          setTimeout(() => {
            setOpen(false);
            setRefresh(!refresh); // Refresh loan data
            setOpenAssignDialog(false); // Close the dialog
          }, 1000);
        } else {
          setError("Agent assignment failed!");
        }
      })
      .catch((error) => {
        setError("Agent assignment failed!");
      })
      .finally(() => {
        setALoading(false);
      });
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setOpen(true);
          setSelectedLoan(item);
        }}
        key={index}
      >
        <TableCell padding="checkbox">
          <Chip label={offset * 10 + index + 1} />
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {user?.firstName ? user?.firstName : "..."}{" "}
            {user?.lastName ? user?.lastName : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={
                "KSh " +
                (item.remainingAmount - 0).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item.orderNumber}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip label={item.repaymentDate} />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {supervisor?.Name ? supervisor?.Name : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {agent?.Name ? agent?.Name : "..."} -{" "}
            {agent?.Phone ? agent?.Phone : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={item.overdueDays}
            />
          </Typography>
        </TableCell>
      </TableRow>
      <LoanDetailsPopup
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        getAgents={() => {
          getAgents();
        }}
        agent={agent}
        user={user}
        loan={item}
        loading={loading}
        setLoading={setLoading}
      />
      {agents && (
        <AssignAgentDialog
          open={openAssignDialog}
          onClose={() => setOpenAssignDialog(false)}
          agents={agents}
          selectedAgentId={selectedAgentId}
          setSelectedAgentId={setSelectedAgentId}
          onSubmit={handleAssignAgent}
          error={error}
          loading={aloading}
        />
      )}
    </>
  );
};

const LoanDetailsPopup = ({
  open,
  onClose,
  loan,
  user,
  agent,
  getAgents,
  loading,
  setLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Loan Details</DialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <User size={64} color="#0033A0" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <CurrencyDollar color="#0033A0" size={64} />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              <Chip
                color={
                  loan.overdueDays == 0 && loan.status != "Defaulted"
                    ? "success"
                    : loan.overdueDays < 60 && loan.status != "Defaulted"
                    ? "warning"
                    : "error"
                }
                size="small"
                label={
                  "KSh " +
                  (loan.remainingAmount - 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }
              />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Date Due: <Chip size="small" label={loan.repaymentDate} />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Days Overdue:{" "}
              <Chip
                size="small"
                color={
                  loan.overdueDays == 0
                    ? "success"
                    : loan.overdueDays < 60
                    ? "warning"
                    : "error"
                }
                label={loan.overdueDays}
              />
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <SupportAgent
              color="warning"
              sx={{ fontSize: "64px", margin: "auto" }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Grid2 size={{ xs: 12, md: 10 }}>
              <Typography variant="body2" gutterBottom>
                {agent?.Name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Phone}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Email}
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Extensions</Typography>
            <Divider />
            {loan.extensions.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.date} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amount - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Payments</Typography>
            <Divider />
            {loan.payments.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.paymentDate} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amountPaid - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Follow Ups</Typography>
            <Divider />
            {loan.LoanFollowUps.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",

                    mt: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <Chip
                      size="small"
                      label={
                        item.createdAt?.toLocaleString()?.split("T")[0] +
                        " " +
                        item.createdAt
                          ?.toLocaleString()
                          ?.split("T")[1]
                          ?.slice(0, 5)
                      }
                    />
                    <Typography
                      sx={{ m: "auto", display: "block" }}
                      variant="body2"
                    >
                      <Chip
                        size="small"
                        color={
                          item.feedback == "Abusive"
                            ? "error"
                            : item.feedback == "Responsive" ||
                              item.feedback == "Promised to Pay"
                            ? "success"
                            : "warning"
                        }
                        label={item.feedback}
                      />
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography
                    sx={{ mt: "8px", fontStyle: "italic" }}
                    variant="body1"
                  >
                    {item.feedbackReason}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
        <Button
          onClick={() => {
            getAgents();
            setLoading(true);
          }}
          variant="contained"
          color="primary"
        >
          {loading ? "Fetching..." : "Assign Agent"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AssignAgentDialog = ({
  open,
  onClose,
  agents,
  selectedAgentId,
  setSelectedAgentId,
  onSubmit,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Assign Agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Agent</InputLabel>
          <Select
            label="Agent"
            value={selectedAgentId}
            onChange={(e) => setSelectedAgentId(e.target.value)}
          >
            {agents.data.map((agent) => (
              <MenuItem key={agent.UserID} value={agent.UserID}>
                {agent.Name} - {agent.Phone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained">
          {loading ? "Assigning..." : "Assign"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
