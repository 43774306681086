import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  IconButton,
  useTheme,
  Grid,
} from "@mui/material";
import {
  Refresh,
  Search,
  Visibility,
  AccountCircle,
  Receipt,
  CalendarToday,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Component to fetch and display user details
const UserDetails = ({ userId }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch(`/api/users/me/${userId}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to fetch user details");
      })
      .then((data) => setUser(data))
      .catch((error) => console.error(error));
  }, [userId]);

  if (!user) return "Loading...";
  return `${user.firstName} ${user.lastName}`;
};

const PaymentDetailsDialog = ({ payment, open, onClose }) => {
  if (!payment) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Receipt color="primary" />
          <Typography variant="h6">Payment Details</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Payment Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Amount Paid
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    KES {payment.amountPaid.toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Payment Date
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {dayjs(payment.paymentDate).format("MMM D, YYYY")}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Receipt Number
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {payment.mpesaReceiptNumber}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              User Information
            </Typography>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {payment.user?.firstName} {payment.user?.lastName}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {payment.user?.email || "N/A"}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {payment.user?.phoneNumber || "N/A"}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.secondary">
                    ID Number
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {payment.user?.idCardNumber || "N/A"}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add this new component for the filters section
const FilterSection = ({
  startDate,
  endDate,
  onDateChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.secondary">
          Filter Payments
        </Typography>

        <Box
          display="flex"
          gap={2}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => onDateChange("start", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => onDateChange("end", date)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <CalendarToday
                        sx={{
                          color: "text.secondary",
                          mr: 1,
                          fontSize: "1.2rem",
                        }}
                      />
                    ),
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Search by</InputLabel>
              <Select
                value={column}
                onChange={(e) => onColumnChange(e.target.value)}
                label="Search by"
                startAdornment={
                  <Box component="span" sx={{ pl: 1 }}>
                    <Search
                      sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                    />
                  </Box>
                }
              >
                <MenuItem value="amountPaid">Amount Paid</MenuItem>
                <MenuItem value="paymentDate">Payment Date</MenuItem>
                <MenuItem value="mpesaReceiptNumber">Receipt Number</MenuItem>
                <MenuItem value="loan.orderNumber">Order Number</MenuItem>
                <MenuItem value="loan.status">Loan Status</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                  />
                ),
              }}
            />
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            * Select filters to narrow down payment records
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh Results
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

export default function Payments() {
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("amountPaid");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let url = `/api/payments?offset=${offset * 10}&limit=10${
      searchValue ? `&${column}=${searchValue}` : ""
    }`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Error fetching payments");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, searchValue, column, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchValue(value);
    setOffset(0);
  };

  const handleOpenDetails = (payment) => {
    fetch(`/api/users/me/${payment.loan.userId}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to fetch user details");
      })
      .then((userData) => {
        setSelectedPayment({ ...payment, user: userData });
        setOpenDetails(true);
      })
      .catch((error) => console.error("Error fetching user details:", error));
  };

  const handleDateChange = (type, value) => {
    if (type === "start") setStartDate(value);
    else setEndDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9, px: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            background: "linear-gradient(45deg, #2196F3, #3f51b5)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            mb: 4,
          }}
        >
          Payment History
        </Typography>

        <FilterSection
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          column={column}
          onColumnChange={setColumn}
          searchValue={searchValue}
          onSearch={handleSearch}
          onRefresh={() => setRefresh(!refresh)}
        />

        <Card
          elevation={0}
          sx={{ borderRadius: 3, border: `1px solid ${theme.palette.divider}` }}
        >
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Amount Paid</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Receipt Number</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          <Chip
                            label={offset * 10 + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            <AccountCircle sx={{ color: "text.secondary" }} />
                            <UserDetails userId={item.loan.userId} />
                          </Box>
                        </TableCell>
                        <TableCell>
                          KES {item.amountPaid.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {dayjs(item.paymentDate).format("MMM D, YYYY")}
                        </TableCell>
                        <TableCell>{item.mpesaReceiptNumber}</TableCell>
                        <TableCell>{item.loan?.orderNumber || "N/A"}</TableCell>
                        <TableCell>
                          <Chip
                            label={item.loan?.status || "Unknown"}
                            size="small"
                            color={
                              item.loan?.status === "Paid"
                                ? "success"
                                : "warning"
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            onClick={() => handleOpenDetails(item)}
                            sx={{
                              color: theme.palette.primary.main,
                              "&:hover": {
                                bgcolor: theme.palette.primary.lighter,
                              },
                            }}
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No payments found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
              {data && (
                <Pagination
                  count={Math.ceil(data.total / 10)}
                  page={offset + 1}
                  onChange={(e, value) => setOffset(value - 1)}
                  color="primary"
                />
              )}
            </Box>
          </Box>
        </Card>

        <PaymentDetailsDialog
          payment={selectedPayment}
          open={openDetails}
          onClose={() => {
            setOpenDetails(false);
            setSelectedPayment(null);
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}
