import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import dayjs from "dayjs";
import { DateRange, Money, SupportAgent } from "@mui/icons-material";
import { CurrencyDollar, User } from "@phosphor-icons/react";

export default function DCAssigned(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [agents, setAgents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Approved");
  const [selectedAgentId, setSelectedAgentId] = useState("");

  useEffect(() => {
    fetch(`/api/auth?supervisorId=${props?.user?.UserID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        setLoading(false);
        setAgents(data);
        setSelectedAgentId(data.data[0].UserID);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  // Fetch loans from the API
  useEffect(() => {
    if (selectedAgentId) {
      setLoading(true);
      fetch(
        `/api/loans?offset=${
          offset * 10
        }&assignedAgentId=${selectedAgentId}&includeUsers=true&includePayments=true&includeFollowUps=true`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [offset, refresh, selectedAgentId]);

  return (
    <Box mt={9}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Loan Follow Ups
      </Typography>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: "0px 10px 30px #60606040",
          p: "1em",
        }}
        component={Card}
      >
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5"></Typography>
          </Box>
          <FormControl
            size="small"
            sx={{ minWidth: { md: "200px", xs: "100%" } }}
          >
            <InputLabel size="small">Agent</InputLabel>
            <Select
              label="Agent"
              value={selectedAgentId}
              onChange={(e) => setSelectedAgentId(e.target.value)}
            >
              {agents?.data?.map((agent) => (
                <MenuItem key={agent.UserID} value={agent.UserID}>
                  {agent.Name} - {agent.Phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Loan</TableCell>
                  <TableCell>Order No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Agent</TableCell>
                  <TableCell>Overdue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.data?.length > 0 ? (
                  data.data.map((item, index) => {
                    return (
                      <LoanBody
                        key={index}
                        index={index}
                        offset={offset}
                        item={item}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>No Loans Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const LoanBody = ({ item, index, offset, refresh, setRefresh }) => {
  const [user, setUser] = useState(null);
  const [agent, setAgent] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);

  const [loading, setLoading] = useState(false);
  const [aloading, setALoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (item) {
      fetch(`/api/users/me/${item.userId}`, { credentials: "include" })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
      if (item.assignedAgentId) {
        fetch(`/api/auth/${item.assignedAgentId}`, { credentials: "include" })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error();
          })
          .then((data) => {
            setAgent(data);
          })
          .catch((e) => {});
      }
    }
  }, [item, refresh]);

  function getAgents() {
    if (item) {
      fetch(`/api/auth`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setLoading(false);
          setAgents(data);
          setOpenAssignDialog(true);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <TableRow
        onClick={() => {
          setOpen(true);
          setSelectedLoan(item);
        }}
        key={index}
      >
        <TableCell padding="checkbox">
          <Chip label={offset * 10 + index + 1} />
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {user?.firstName ? user?.firstName : "..."}{" "}
            {user?.lastName ? user?.lastName : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={
                "KSh " +
                (item.remainingAmount - 0).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item.orderNumber}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip label={item.repaymentDate} />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {agent?.Name ? agent?.Name : "..."} -{" "}
            {agent?.Phone ? agent?.Phone : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={item.overdueDays}
            />
          </Typography>
        </TableCell>
      </TableRow>
      <LoanDetailsPopup
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        getAgents={() => {
          getAgents();
        }}
        agent={agent}
        user={user}
        loan={item}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

const LoanDetailsPopup = ({ open, onClose, loan, user, agent }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Loan Details</DialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <User size={64} color="#0033A0" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <CurrencyDollar color="#0033A0" size={64} />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              <Chip
                color={
                  loan.overdueDays == 0 && loan.status != "Defaulted"
                    ? "success"
                    : loan.overdueDays < 60 && loan.status != "Defaulted"
                    ? "warning"
                    : "error"
                }
                size="small"
                label={
                  "KSh " +
                  (loan.remainingAmount - 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }
              />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Date Due: <Chip size="small" label={loan.repaymentDate} />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Days Overdue:{" "}
              <Chip
                size="small"
                color={
                  loan.overdueDays == 0
                    ? "success"
                    : loan.overdueDays < 60
                    ? "warning"
                    : "error"
                }
                label={loan.overdueDays}
              />
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <SupportAgent
              color="warning"
              sx={{ fontSize: "64px", margin: "auto" }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Grid2 size={{ xs: 12, md: 10 }}>
              <Typography variant="body2" gutterBottom>
                {agent?.Name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Phone}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Email}
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Extensions</Typography>
            <Divider />
            {loan.extensions.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.date} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amount - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Payments</Typography>
            <Divider />
            {loan.payments.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.paymentDate} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amountPaid - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Follow Ups</Typography>
            <Divider />
            {loan.LoanFollowUps.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",

                    mt: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <Chip
                      size="small"
                      label={
                        item.createdAt?.toLocaleString()?.split("T")[0] +
                        " " +
                        item.createdAt
                          ?.toLocaleString()
                          ?.split("T")[1]
                          ?.slice(0, 5)
                      }
                    />
                    <Typography
                      sx={{ m: "auto", display: "block" }}
                      variant="body2"
                    >
                      <Chip
                        size="small"
                        color={
                          item.feedback == "Abusive"
                            ? "error"
                            : item.feedback == "Responsive" ||
                              item.feedback == "Promised to Pay"
                            ? "success"
                            : "warning"
                        }
                        label={item.feedback}
                      />
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography
                    sx={{ mt: "8px", fontStyle: "italic" }}
                    variant="body1"
                  >
                    {item.feedbackReason}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
