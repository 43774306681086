import React, { useEffect, useState } from "react";
import {
  MoneyOff,
  TrendingUp,
  CalendarToday,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BarChart } from "recharts";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Rectangle,
} from "recharts";
import CountyMap from "../../Pages/Home/CountyMap";
import { CartesianGrid, XAxis, YAxis, Bar } from "recharts";

const StatCard = ({ icon, label, value, color }) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value.count - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: value.count >= 0 ? "success.main" : "error.main",
              transform: value.count >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={value.amount >= 0 ? "success.main" : "error.main"}
          >
            Ksh{" "}
            {(value.amount - 0).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

const PieChartCard = ({ label, data, color }) => {
  const total = data.map((d) => d.value).reduce((a, b) => a + b, 0);

  const getColor = (index) => {
    const colors = [
      "#3D3BF3",
      "#219B9D",
      "#FF8000",
      "#8B5DFF",
      "#3F7D58",
      "#FF0000",
      "#00FF00",
      "#0000FF",
      "#FFA500",
      "#800080",
      "#008000",
      "#000080",
      "#800000",
      "#808000",
      "#008080",
      "#C0C0C0",
      "#FFFFFF",
      "#000000",
    ];
    return colors[index % colors.length];
  };

  return (
    <Grid item xs={12} md={4}>
      <Card
        elevation={0}
        sx={{
          p: 3,
          height: "100%",
          borderRadius: 3,
          border: `1px solid ${color}20`,
          background: `linear-gradient(135deg, ${color}05 0%, #ffffff 100%)`,
        }}
      >
        <Stack spacing={0}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>

          <Typography variant="title" color={color} fontWeight="bold">
            {`${total.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })} value`}
          </Typography>

          <Box sx={{ height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={75}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(index)} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) => value.toLocaleString()}
                  contentStyle={{ borderRadius: 8 }}
                />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  formatter={(value, entry) => (
                    <span style={{ color: "text.secondary" }}>
                      {value}:{" "}
                      {((entry.payload.value / total) * 100).toFixed(1)}%
                    </span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Stack>
      </Card>
    </Grid>
  );
};

const ChartCard = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="subtitle1" mb={1}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};

const MonthlyDistributionChart = ({ data }) => {
  return (
    <Grid item xs={12}>
      <ChartCard title="Monthly Loan Distribution">
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="active"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Bar
              dataKey="overdue"
              fill="#82ca9d"
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </ChartCard>
    </Grid>
  );
};


export default function DCDashboard() {
  const [metrics, setMetrics] = useState({
    totalLoans: { count: 0, amount: 0 },
    activeLoans: { count: 0, amount: 0 },
    overdueLoans: { count: 0, amount: 0 },
    defaultedLoans: { count: 0, amount: 0 },
    loanAmountDistribution: [],
    monthlyDistribution: [],
    loanStatusByVerification: [],
    premiumLoanStats: [],
    regularLoanStats: [],
  });
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [dloading, setDLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchMetrics();
  }, []);

  useEffect(() => {
    if (metrics) {
      setDLoading(true);
      fetchMetrics();
    }
  }, [start, end]);

  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        `/api/loans/debt-collection?start=${start}&end=${end}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();

      if (response.ok) {
        setMetrics({
          totalLoans: data?.totalLoans || { count: 0, amount: 0 },
          activeLoans: data?.activeLoans || { count: 0, amount: 0 },
          overdueLoans: data?.overdueLoans || { count: 0, amount: 0 },
          defaultedLoans: data?.defaultedLoans || { count: 0, amount: 0 },
          loanAmountDistribution: data?.loanAmountDistribution || [],
          monthlyDistribution: data?.monthlyDistribution || [],
          loanStatusByVerification: data?.loanStatusByVerification || [],
          premiumLoanStats: data?.premiumLoanStats || [],
          regularLoanStats: data?.regularLoanStats || [],
        });
      } else {
        console.error("Error fetching metrics:", data);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setLoading(false);
      setDLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 8, px: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "primary.main",
              background: "linear-gradient(45deg, #2196F3, #3f51b5)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Debt Collection Dashboard
          </Typography>

          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start Date"
              value={dayjs(start)}
              onChange={(date) => setStart(date.format("YYYY-MM-DD"))}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(end)}
              onChange={(date) => setEnd(date.format("YYYY-MM-DD"))}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
          </Stack>
        </Stack>

        {dloading && (
          <Box display="flex" justifyContent="center" mb={3}>
            <CircularProgress size={24} />
          </Box>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <StatCard
              icon={<MoneyOff />}
              label="Total Loans"
              value={metrics.totalLoans}
              color="#8B5DFF"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StatCard
              icon={<MoneyOff sx={{ color: "#3D3BF3" }} />}
              label="Active Loans"
              value={metrics.activeLoans}
              color="#3D3BF3"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StatCard
              icon={<MoneyOff sx={{ color: "#219B9D" }} />}
              label="Overdue"
              value={metrics.overdueLoans}
              color="#219B9D"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StatCard
              icon={<MoneyOff sx={{ color: "#FF8000" }} />}
              label="Defaulted"
              value={metrics.defaultedLoans}
              color="#FF8000"
            />
          </Grid>

          <PieChartCard
            label="Loan Status - Regular"
            total={metrics.totalLoans}
            data={metrics.regularLoanStats || []}
            color="#2196F3"
          />

          <PieChartCard
            label="Loan Status - Premium"
            total={metrics.totalLoans}
            data={metrics.premiumLoanStats || []}
            color="#219B9D"
          />

          <PieChartCard
            label="Loan Status All"
            total={metrics.totalLoans}
            data={metrics.loanStatusByVerification || []}
            color="#219B9D"
          />
          
          <MonthlyDistributionChart data={metrics.monthlyDistribution || []} />

          <Grid item xs={12} md={4}>
            <CountyMap
              url="/api/counties/user-approved-loans"
              height={300}
              title="Active Loans"
              color="#3F7D58"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CountyMap
              url="/api/counties/user-overdue-loans"
              height={300}
              title="Overdue Loans"
              color="#219B9D"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CountyMap
              url="/api/counties/user-defaulted-loans"
              height={300}
              title="Defaulted Loans"
              color="#FF8000"
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}
