import React, { useEffect, useState } from "react";
import { ArrowForwardIos, LineAxis, Payment, People, TrendingUp } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Activity } from "@phosphor-icons/react";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";


const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

const ActivityCard = ({ label, total, l1, l2, v1, v2, color }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" color="text.secondary">
          {label}
        </Typography>

        <Typography variant="h4" sx={{ color: color, fontWeight: 600 }}>
          {total.toLocaleString()}
        </Typography>

        <Stack spacing={3}>
          <Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body2" color="text.secondary">
                {l1}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {v1.toLocaleString()} ({((v1 / total) * 100).toFixed(1)}%)
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={(v1 / total) * 100}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: `${color}20`,
                "& .MuiLinearProgress-bar": {
                  bgcolor: color,
                  borderRadius: 4,
                },
              }}
            />
          </Box>

          <Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body2" color="text.secondary">
                {l2}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {v2.toLocaleString()} ({((v2 / total) * 100).toFixed(1)}%)
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={(v2 / total) * 100}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: "#FF800020",
                "& .MuiLinearProgress-bar": {
                  bgcolor: "#FF8000",
                  borderRadius: 4,
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default function Overview({ refresh }) {
  const [data, setData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/loans/overview");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {/* Stats Row */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          label="Customers"
          value={data.customers.total}
          current={data.customers.current}
          last={data.customers.last}
          icon={<People />}
          color="#8B5DFF"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          label="Payouts"
          value={data.payouts.total}
          current={data.payouts.current}
          last={data.payouts.last}
          color="#3D3BF3"
          icon={<Payment />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          label="Paid In"
          value={data.paidin.total}
          current={data.paidin.current}
          last={data.paidin.last}
          color="#219B9D"
          icon={<Payment />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          label="Profits"
          value={data.paidin.total - data.payouts.total}
          current={data.paidin.current - data.payouts.current}
          last={data.paidin.last - data.payouts.last}
          color="#FF8000"
          icon={<Payment />}
        />
      </Grid>

      {/* Charts Row */}
      <Grid item xs={12} md={8}>
        <Card
          elevation={0}
          sx={{
            p: 3,
            height: "100%",
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <MyLineChart />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card
          elevation={0}
          sx={{
            p: 3,
            height: "100%",
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <MyPieChart data={data.ln_status} height={370} />
        </Card>
      </Grid>

      {/* Activity Cards Row */}
      <Grid item xs={12} md={4}>
        <ActivityCard
          label="Customer Support"
          total={data.cs_support.total}
          l1="Open"
          l2="Resolved"
          v1={data.cs_support.open}
          v2={data.cs_support.resolved}
          color="#219B9D"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ActivityCard
          label="Loans"
          total={data.lnp.total}
          l1="Active"
          l2="Rejected"
          v1={data.lnp.approved}
          v2={data.lnp.rejected}
          color="#219B9D"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ActivityCard
          label="Communication"
          total={data.com.total}
          l1="Email"
          l2="SMS"
          v1={data.com.email}
          v2={data.com.sms}
          color="#219B9D"
        />
      </Grid>
    </Grid>
  );
}
