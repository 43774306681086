import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  useTheme,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Avatar,
  Grid,
} from "@mui/material";
import {
  Email,
  Message,
  Phone,
  WhatsApp,
  Search,
  Person,
  Badge,
  LocationOn,
  Work,
  AccountBalance,
  Refresh,
  CheckCircle,
  Warning,
  Close,
  CalendarToday,
  AttachMoney,
  Schedule,
  ContactEmergency,
} from "@mui/icons-material";
import dayjs from "dayjs";

const FilterSection = ({
  status,
  onStatusChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Customers
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh
          </Button>
        </Box>

        <Stack direction="row" spacing={2}>
          <FormControl size="small" fullWidth>
            <InputLabel>Customer Type</InputLabel>
            <Select
              value={status}
              onChange={onStatusChange}
              label="Customer Type"
              startAdornment={
                <Box component="span" sx={{ pl: 1 }}>
                  <Person
                    sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                  />
                </Box>
              }
            >
              <MenuItem value="All">All Customers</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel>Search by</InputLabel>
            <Select
              value={column}
              onChange={onColumnChange}
              label="Search by"
              startAdornment={
                <Box component="span" sx={{ pl: 1 }}>
                  <Search
                    sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                  />
                </Box>
              }
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                />
              ),
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`customer-tabpanel-${index}`}
    sx={{ pt: 2 }}
  >
    {value === index && children}
  </Box>
);

const CustomerDetailsDialog = ({ customer, open, onClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  if (!customer) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
    >
      <DialogTitle sx={{ p: 2, bgcolor: "background.neutral" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              sx={{
                width: 56,
                height: 56,
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {customer.firstName?.[0]}
            </Avatar>
            <Box>
              <Typography variant="h6">
                {customer.firstName} {customer.lastName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID: {customer.idCardNumber}
              </Typography>
            </Box>
            <Chip
              label={customer.blacklisted ? "Blacklisted" : "Active"}
              color={customer.blacklisted ? "error" : "success"}
              size="small"
            />
          </Stack>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(e, v) => setTabValue(v)}
          sx={{ px: 2 }}
        >
          <Tab
            icon={<Person sx={{ fontSize: "1.2rem" }} />}
            label="Details"
            iconPosition="start"
          />
          <Tab
            icon={<AccountBalance sx={{ fontSize: "1.2rem" }} />}
            label="Loans"
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <DialogContent sx={{ p: 2 }}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                }}
              >
                <Stack spacing={2}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Personal Information
                  </Typography>

                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Email sx={{ color: "text.secondary" }} />
                      <Typography>{customer.email}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                      <Phone sx={{ color: "text.secondary" }} />
                      <Typography>
                        {customer.phoneNumber
                          .replace("+", "")
                          .replace("254", "0")}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                      <LocationOn sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography>
                          {customer.county}, {customer.subcounty},{" "}
                          {customer.ward}
                        </Typography>
                        <Typography>{customer.detailedAddress}</Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                      <Work sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography>{customer.typeOfWork}</Typography>
                        <Typography>{customer.monthlyIncome}</Typography>
                        <Typography>{customer.payDay}</Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                      <Badge sx={{ color: "text.secondary" }} />
                      <Typography>{customer.gender}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                      <ContactEmergency sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography>
                          {customer.emergencyContact1.name}
                        </Typography>
                        <Typography>
                          {customer.emergencyContact1.relationship}
                        </Typography>
                        <Typography>
                          {customer.emergencyContact1.contact}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <ContactEmergency sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography>
                          {customer.emergencyContact2.name}
                        </Typography>
                        <Typography>
                          {customer.emergencyContact2.relationship}
                        </Typography>
                        <Typography>
                          {customer.emergencyContact2.contact}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                }}
              >
                <Stack spacing={2}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Credit Information
                  </Typography>

                  <Stack spacing={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Credit Limit
                      </Typography>
                      <Typography variant="h6">
                        KES{" "}
                        {customer.CreditScore?.creditLimit?.toLocaleString() ||
                          0}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Interest Rate
                      </Typography>
                      <Typography variant="h6">
                        {(
                          (customer.CreditScore?.interestRate || 0) * 100
                        ).toFixed(0)}
                        %
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Credit Score
                      </Typography>
                      <Typography variant="h6">
                        {customer.CreditScore?.score?.toFixed(0) || 0}%
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Manual Credit Limit
                      </Typography>
                      <Typography variant="h6">
                        KES{" "}
                        {customer.CreditScore?.manualCreditLimit?.toLocaleString() ||
                          0}
                      </Typography>
                    </Box>

                    <Box> 
                      <Typography variant="caption" color="text.secondary">
                        Manual Override 
                      </Typography>
                      <Typography variant="h6">
                        {customer.CreditScore?.manualOverride
                          ? "Yes"
                          : "No"}
                      </Typography>
                    </Box>  
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Last Calculated
                      </Typography>
                      <Typography variant="h6">
                        {dayjs(customer.CreditScore?.lastCalculated).format(
                          "DD MMM YYYY"
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Account Status
                      </Typography>
                      <Chip
                        icon={
                          customer.isVerified ? <CheckCircle /> : <Warning />
                        }
                        label={customer.isVerified ? "Premium" : "Regular"}
                        color={customer.isVerified ? "success" : "warning"}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Stack spacing={2}>
            {customer.loans.map((loan, index) => (
              <Card
                key={index}
                elevation={0}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: loan.overdueDays > 0 ? "error.light" : "divider",
                  borderRadius: 2,
                  position: "relative",
                  overflow: "visible",
                }}
              >
                {loan.overdueDays > 0 && (
                  <Chip
                    label={`${loan.overdueDays} days overdue`}
                    color="error"
                    size="small"
                    sx={{
                      position: "absolute",
                      top: -10,
                      right: 12,
                    }}
                  />
                )}

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <Typography variant="caption" color="text.secondary">
                        Principal Amount
                      </Typography>
                      <Typography variant="h6">
                        KES {loan?.amount?.toLocaleString()}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Total Payable
                      </Typography>
                      <Typography variant="body2" color="primary">
                        KES {loan?.paymentAmount?.toLocaleString()}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <Typography variant="caption" color="text.secondary">
                        Dates
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CalendarToday
                          sx={{ fontSize: "1rem", color: "text.secondary" }}
                        />
                        <Box>
                          <Typography variant="body2">
                            Issued:{" "}
                            {dayjs(loan.createdAt).format("DD MMM YYYY")}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Due:{" "}
                            {dayjs(loan.repaymentDate).format("DD MMM YYYY")}
                          </Typography>
                        </Box>
                      </Stack>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Interest & Penalties
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2">
                          Interest: {loan?.interest?.toLocaleString() || 0}
                        </Typography>
                        <Typography variant="body2" color="error.main">
                          Penalties: {loan?.penalties?.toLocaleString() || 0}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1} alignItems="flex-end">
                      <Typography variant="caption" color="text.secondary">
                        Payment Status
                      </Typography>
                      <Chip
                        label={loan.status}
                        color={
                          loan.status === "Paid"
                            ? "success"
                            : loan.status === "Active"
                            ? "primary"
                            : "warning"
                        }
                        size="small"
                      />
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Remaining Balance
                      </Typography>
                      <Typography
                        variant="h6"
                        color={
                          loan.remainingAmount > 0
                            ? "error.main"
                            : "success.main"
                        }
                      >
                        KES {loan?.remainingAmount?.toLocaleString()}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Stack>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default function RMCustomers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const checkOverdue = (loans) => {
    return loans.some((loan) => loan.overdueDays > 0);
  };

  // Function to check if any loan is defaulted (more than 60 days since repayment)
  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Paid" && daysSinceRepayment > 60;
    });
  };

  const handleOpenDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };

  return (
    <Box mt={9}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Customer Management
      </Typography>
      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ p: 3 }}>
          <FilterSection
            status={status}
            onStatusChange={(e) => setStatus(e.target.value)}
            column={column}
            onColumnChange={(e) => setColumn(e.target.value)}
            searchValue={searchValue}
            onSearch={handleSearch}
            onRefresh={() => setRefresh(!refresh)}
          />

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>ID No</TableCell>
                    <TableCell>Loans</TableCell>
                    <TableCell>Overdue</TableCell>
                    <TableCell>Limit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleOpenDetails(item)}
                        hover
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Chip
                            label={offset * 10 + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            icon={
                              item.isVerified ? (
                                <CheckCircle sx={{ fontSize: "1rem" }} />
                              ) : (
                                <Warning sx={{ fontSize: "1rem" }} />
                              )
                            }
                            label={item.isVerified ? "Premium" : "Regular"}
                            size="small"
                            color={item.isVerified ? "success" : "warning"}
                          />
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Person sx={{ color: "text.secondary" }} />
                            <Typography variant="body2">
                              {item.firstName} {item.lastName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{item.idCardNumber}</TableCell>
                        <TableCell>
                          <Chip
                            label={item.loans.length}
                            size="small"
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={checkOverdue(item.loans) ? "Yes" : "No"}
                            size="small"
                            color={
                              checkOverdue(item.loans) ? "error" : "success"
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            KES{" "}
                            {item.CreditScore?.creditLimit?.toLocaleString() ??
                              "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography color="text.secondary">
                          No customers found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <CustomerDetailsDialog
        customer={selectedUser}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
    </Box>
  );
}
