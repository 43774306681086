import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Pagination,
  Grid,
  Chip,
  useTheme,
} from "@mui/material";
import {
  Email,
  Sms,
  Message,
  CalendarToday,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import dayjs from "dayjs";

const MessageCard = ({ message, expanded, onToggle }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          borderColor: "primary.main",
          boxShadow: `0 4px 12px ${theme.palette.primary.main}15`,
        },
      }}
    >
      {/* Message Header */}
      <Box
        sx={{
          p: 2,
          cursor: "pointer",
          bgcolor: expanded ? "background.neutral" : "transparent",
          borderRadius: "8px 8px 0 0",
        }}
        onClick={onToggle}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Message sx={{ color: "primary.main" }} />
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Subject
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {message.Subject}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarToday sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Sent On
                </Typography>
                <Typography variant="body2">
                  {dayjs(message.createdAt).format("DD MMM YYYY HH:mm")}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1} alignItems="flex-end">
              <Stack direction="row" spacing={1}>
                {message.SMS && (
                  <Chip
                    size="small"
                    icon={<Sms sx={{ fontSize: "1rem" }} />}
                    label="SMS"
                    color="primary"
                    variant="outlined"
                  />
                )}
                {message.Email && (
                  <Chip
                    size="small"
                    icon={<Email sx={{ fontSize: "1rem" }} />}
                    label="Email"
                    color="info"
                    variant="outlined"
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/* Message Content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box sx={{ p: 2, bgcolor: "background.neutral" }}>
          <Stack spacing={2}>
            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Message Content
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  p: 2,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  border: "1px solid",
                  borderColor: "divider",
                }}
              >
                {message.Message}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Delivery Details
              </Typography>
              <Grid container spacing={2}>
                {message.SMS && (
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Sms sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          SMS Status
                        </Typography>
                        <Typography variant="body2">
                          {message.SMSStatus || "Sent"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                )}
                {message.Email && (
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Email sx={{ color: "text.secondary" }} />
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Email Status
                        </Typography>
                        <Typography variant="body2">
                          {message.EmailStatus || "Sent"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Collapse>
    </Card>
  );
};

export default function Activities({ user }) {
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const offset = paginationModel.page * paginationModel.pageSize;
        const response = await fetch(
          `/api/messages?userId=${user.id}&offset=${offset}&limit=${paginationModel.pageSize}`
        );
        if (response.ok) {
          const data = await response.json();
          setMessages(data.data);
          setTotalMessages(data.total);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [user.id, paginationModel]);

  const handleExpandClick = (messageId) => {
    setExpandedMessageId((prevId) => (prevId === messageId ? null : messageId));
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Communication History
      </Typography>

      <Stack spacing={2}>
        {messages.map((message) => (
          <MessageCard
            key={message.ID}
            message={message}
            expanded={expandedMessageId === message.ID}
            onToggle={() => handleExpandClick(message.ID)}
          />
        ))}

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(totalMessages / paginationModel.pageSize)}
            page={paginationModel.page + 1}
            onChange={(event, value) =>
              setPaginationModel({ ...paginationModel, page: value - 1 })
            }
          />
        </Box>
      </Stack>
    </Box>
  );
}
