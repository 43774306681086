import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
} from "@mui/material";
import {
  People,
  Person,
  Stars,
  HourglassEmpty,
  TrendingUp,
} from "@mui/icons-material";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";
import CountyMap from "./CountyMap";

const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

const DistributionCard = ({ title, data, height = 300 }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        py: 3,
        height: "100%",
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <MyPieChart title={title} data={data} height={height} />
    </Card>
  );
};

export default function Customers({ refresh }) {
  const [data, setData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/users/summary", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching user summary:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  const stats = [
    {
      icon: <People />,
      label: "Total Users",
      value: data.users.total,
      current: data.users.current,
      last: data.users.last,
      color: "#2196F3",
    },
    {
      icon: <Person />,
      label: "Regular Users",
      value: data.regular.total,
      current: data.regular.current,
      last: data.regular.last,
      color: "#4CAF50",
    },
    {
      icon: <Stars />,
      label: "Premium Users",
      value: data.premium.total,
      current: data.premium.current,
      last: data.premium.last,
      color: "#FF9800",
    },
    {
      icon: <HourglassEmpty />,
      label: "Dormant Users",
      value: data.dormant.total,
      current: data.dormant.current,
      last: data.dormant.last,
      color: "#F44336",
    },
  ];

  return (
    <Grid container spacing={3}>
      {/* Stats Cards */}
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <StatCard {...stat} />
        </Grid>
      ))}

      {/* Distribution Charts */}
      <Grid item xs={12} md={4}>
        <DistributionCard
          title="User Type Distribution"
          data={[
            { name: "Premium", value: data.premium.total },
            { name: "Regular", value: data.regular.total },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DistributionCard
          title="Gender Distribution"
          data={data.genderDistribution}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DistributionCard
          title="Employment Distribution"
          data={data.employmentDistribution}
        />
      </Grid>

      {/* Location Charts */}
      <Grid item xs={12}>
        <Card
          elevation={0}
          sx={{
            py: 3,
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" mx={3}>
            Performance by Month
          </Typography>
          <Box>
            <MyLineChart data={data.countyDistribution} />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CountyMap
          url="/api/counties/user-distribution"
          height={600}
          title="Distribution by County"
          color="#219B9D"
        />
      </Grid>
    </Grid>
  );
}
