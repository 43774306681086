import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";
import {
  Search,
  Person,
  AccountBalance,
  Edit,
  CheckCircle,
  Warning,
  Refresh,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const FilterSection = ({
  status,
  onStatusChange,
  column,
  onColumnChange,
  searchValue,
  onSearch,
  onRefresh,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Credit Limits
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh
          </Button>
        </Box>

        <Stack direction="row" spacing={2}>
          <FormControl size="small" fullWidth>
            <InputLabel>Customer Type</InputLabel>
            <Select
              value={status}
              onChange={onStatusChange}
              label="Customer Type"
              startAdornment={
                <Box component="span" sx={{ pl: 1 }}>
                  <Person
                    sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                  />
                </Box>
              }
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel>Search by</InputLabel>
            <Select
              value={column}
              onChange={onColumnChange}
              label="Search by"
              startAdornment={
                <Box component="span" sx={{ pl: 1 }}>
                  <Search
                    sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                  />
                </Box>
              }
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }}
                />
              ),
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

const ChangeLimitDialog = ({ open, onClose, customer, onSave, loading }) => {
  const [newLimit, setNewLimit] = useState(
    customer?.CreditScore?.creditLimit || 0
  );
  const [rate, setRate] = useState(customer?.CreditScore?.interestRate || 0.25);
  const [term, setTerm] = useState(customer?.CreditScore?.term || 21);

  useEffect(() => {
    setNewLimit(customer?.CreditScore?.creditLimit || 0);
    setRate(customer?.CreditScore?.interestRate || 0.25);
    setTerm(customer?.CreditScore?.term || 21);
  }, [customer]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AccountBalance sx={{ color: "primary.main" }} />
          <Typography variant="h6">Change Credit Limit</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Stack spacing={3}>
          <Alert severity="info">
            Current Limit: KES{" "}
            {customer?.CreditScore?.creditLimit?.toLocaleString()}
          </Alert>

          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="New Limit"
                type="number"
                fullWidth
                value={newLimit}
                onChange={(e) => setNewLimit(Number(e.target.value))}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <TextField
                label="Rate"
                type="number"
                fullWidth
                value={rate}
                onChange={(e) => setRate(Number(e.target.value))}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <TextField
                label="Term"
                type="number"
                fullWidth
                value={term}
                onChange={(e) => setTerm(Number(e.target.value))}
              />
            </Grid2>
          </Grid2>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={() =>
            onSave(customer.id, {
              newLimit: newLimit,
              rate: rate,
              term: term,
            })
          }
          variant="contained"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function RMCreditLimits() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openChangeLimit, setOpenChangeLimit] = useState(false);
  const [newLimit, setNewLimit] = useState(0);
  const [rate, setRate] = useState(0.25);
  const [term, setTerm] = useState(21);
  const [saving, setSaving] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    setData(null);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setData(null);
      setLoading(true);
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => setData(data))
        .catch(() => setData(null))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRefresh(!refresh);
    }
  };

  const handleOpenChangeLimit = (user) => {
    if (!user.firstName) {
      Swal.fire({
        icon: "warning",
        title: "Customer Profile Incomplete",
        text: "This customer needs to complete their profile before changing credit limit.",
        confirmButtonColor: "#2196F3",
      });
      return;
    }
    setSelectedUser(user);
    setNewLimit(user.CreditScore?.creditLimit || 0);
    setTerm(user.CreditScore?.term || 21);
    setRate(user.CreditScore?.interestRate || 0.25);
    setOpenChangeLimit(true);
  };

  const handleSaveLimit = async (id, data) => {
    console.log(data);
    if (data.newLimit <= 0 || data.newLimit > 250000) {
      Swal.fire({
        icon: "error",
        title: "Invalid Credit Limit",
        text: "Credit limit cannot be negative or greater than 250,000.",
      });
    }
    const result = await Swal.fire({
      title: "Update Credit Limit?",
      text: `Are you sure you want to update the credit limit to KES ${data.newLimit?.toLocaleString()}, ${(data.rate*100)?.toFixed(0)}% interest rate and ${data.term} days term for ${selectedUser.firstName} ${selectedUser.lastName}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Update",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#9e9e9e",
    });

    if (result.isConfirmed) {
      setSaving(true);

      Swal.fire({
        title: "Updating Credit Limit...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await fetch(`/api/creditscores/${id}/manual`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            creditLimit: data.newLimit,
            manualCreditLimit: data.newLimit,
            interestRate: data.rate,
            term: data.term,
            manualOverride: true,
          }),
        });

        if (!response.ok) throw new Error("Failed to update credit limit");

        await Swal.fire({
          icon: "success",
          title: "Updated Successfully",
          text: "Credit limit has been updated",
          timer: 2000,
          showConfirmButton: false,
        });

        setOpenChangeLimit(false);
        setRefresh(!refresh);
      } catch (error) {
        console.error("Error updating credit limit:", error);

        await Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "Failed to update credit limit. Please try again.",
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Box mt={9}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          background: "linear-gradient(45deg, #2196F3, #3f51b5)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          mb: 4,
        }}
      >
        Credit Limits
      </Typography>
      <Card
        elevation={0}
        sx={{ borderRadius: 3, border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ p: 3 }}>
          <FilterSection
            status={status}
            onStatusChange={(e) => setStatus(e.target.value)}
            column={column}
            onColumnChange={(e) => setColumn(e.target.value)}
            searchValue={searchValue}
            onSearch={handleSearch}
            onRefresh={() => setRefresh(!refresh)}
          />

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>ID No</TableCell>
                    <TableCell>Loans</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Term</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Limit</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <TableRow key={index} hover sx={{ cursor: "pointer" }}>
                        <TableCell>
                          <Chip
                            label={offset * 10 + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Person sx={{ color: "text.secondary" }} />
                            <Typography variant="body2">
                              {item.firstName} {item.lastName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.idCardNumber}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={item.loans.length}
                            size="small"
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            icon={
                              item.isVerified ? (
                                <CheckCircle sx={{ fontSize: "1rem" }} />
                              ) : (
                                <Warning sx={{ fontSize: "1rem" }} />
                              )
                            }
                            label={item.isVerified ? "Premium" : "Regular"}
                            size="small"
                            color={item.isVerified ? "success" : "warning"}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={`${item.CreditScore?.term || 0} days`}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={`${(
                              item.CreditScore?.interestRate * 100 || 0
                            ).toFixed(0)}%`}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            KES{" "}
                            {item.CreditScore?.creditLimit?.toLocaleString() ||
                              0}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            startIcon={<Edit />}
                            variant="outlined"
                            size="small"
                            onClick={() => handleOpenChangeLimit(item)}
                          >
                            Change
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography color="text.secondary">
                          No customers found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>

      <ChangeLimitDialog
        open={openChangeLimit}
        onClose={() => setOpenChangeLimit(false)}
        customer={selectedUser}
        onSave={handleSaveLimit}
        loading={saving}
      />

      {/* User Details Dialog */}
      {selectedUser && (
        <Dialog
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          fullWidth
        >
          <DialogTitle>User Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={1}>
              <Typography variant="h6">{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
              <Typography variant="body2">
                ID: {selectedUser.idCardNumber}
              </Typography>
              <Typography variant="body2">
                Email: {selectedUser.email}
              </Typography>
              <Typography variant="body2">
                Phone:{" "}
                {selectedUser.phoneNumber.replace("+", "").replace("254", "0")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Credit Limit:
                {(selectedUser.CreditScore?.creditLimit - 0).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </Typography>
              <Typography sx={{ pt: 2 }} variant="h6" fontSize="medium">
                Recent Loans
              </Typography>
              <Divider />
              {selectedUser.loans.map((loan, index) => (
                <Card
                  key={index}
                  sx={{
                    mt: 1,
                    mb: 1,
                    boxShadow: "0px 4px 16px #60606040",
                    p: 1,
                  }}
                >
                  <Box display="flex" gap={2}>
                    <Chip
                      sx={{ fontSize: "small" }}
                      label={loan.repaymentDate}
                    />
                    <Typography flexGrow={1} margin="auto">
                      {(loan.paymentAmount - 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                    <Typography margin="auto" variant="body2">
                      {loan.overdueDays} days overdue
                    </Typography>
                    <Chip
                      color={loan.status == "Paid" ? "success" : "warning"}
                      label={loan.status}
                    />
                  </Box>
                </Card>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDetails(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
