import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Divider,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import "../App.css";
import LoanAmountChart from "./Home/MyLineChart";
import {
  CreditCard,
  Dashboard,
  Person,
  Refresh,
  TrendingUp,
  Groups,
  AttachMoney,
  AccountBalance,
} from "@mui/icons-material";
import Overview from "./Home/Overview";
import Loans from "./Home/Loans";
import Customers from "./Home/Customers";
import Affiliates from "./Home/Affiliates";

const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: `1px solid ${color}20`,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 8px 24px ${color}15`,
        },
      }}
    >
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Typography variant="h5" fontWeight="bold" color={color}>
          {(value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <TrendingUp
            sx={{
              fontSize: 16,
              color: growth >= 0 ? "success.main" : "error.main",
              transform: growth >= 0 ? "none" : "rotate(180deg)",
            }}
          />
          <Typography
            variant="caption"
            color={growth >= 0 ? "success.main" : "error.main"}
          >
            {Math.abs(growth).toFixed(1)}% vs last month
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default function Home() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState({
    affiliates: 0,
    referrals: 0,
    activeReferrals: 0,
    paidCommission: 0,
    pendingCommission: 0,
  });
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    // Fetch dashboard data
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      // Fetch referral stats
      const statsResponse = await fetch(`/api/affiliates/dashboard/stats`, {
        credentials: "include",
      });
      const statsData = await statsResponse.json();

      if (statsResponse.ok) {
        setStats({
          affiliates: statsData.affiliates || 0,
          referrals: statsData.referrals || 0,
          activeReferrals: statsData.activeReferrals || 0,
          paidCommission: statsData.paidCommission || 0,
          pendingCommission: statsData.pendingCommission || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
    }
  };

  return (
    <Box sx={{ mt: 8, px: 2 }}>
      <Box display="flex" gap={1} mb={4}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            background: "linear-gradient(45deg, #2196F3, #3f51b5)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Dashboard
        </Typography>
        <Box flexGrow={1}></Box>
        <IconButton
          onClick={() => setRefresh(!refresh)}
          sx={{
            backgroundColor: "primary.light",
            height: "44px",
            width: "44px",
            "&:hover": {
              backgroundColor: "primary.main",
              color: "white",
            },
          }}
        >
          <Refresh />
        </IconButton>
      </Box>
      <Card sx={{ mb: 4, borderRadius: 2 }}>
        <CardContent>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              "& .MuiTab-root": {
                minHeight: 64,
                textTransform: "none",
                fontSize: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Tab  icon={<Dashboard />} label="Overview" />
            <Tab icon={<CreditCard />} label="Loans" />
            <Tab icon={<Person />} label="Customers" />
            <Tab icon={<Groups />} label="Affiliates" />
          </Tabs>
        </CardContent>
      </Card>

      <Box>
        {selectedTab === 0 && <Overview refresh={refresh} />}
        {selectedTab === 1 && <Loans refresh={refresh} />}
        {selectedTab === 2 && <Customers refresh={refresh} />}
        {selectedTab === 3 && <Affiliates stats={stats} refresh={refresh} />}
      </Box>
    </Box>
  );
}
