import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Chip,
  CircularProgress,
  Pagination,
  Grid,
  useTheme,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  AccountBalance,
  CalendarToday,
  Receipt,
  Payment,
  CheckCircle,
  Warning,
  AccessTime,
} from "@mui/icons-material";
import dayjs from "dayjs";

const PaymentCard = ({ payment, expanded, onToggle }) => {
  const theme = useTheme();
  const isOverdue = payment.loan?.overdueDays > 0;

  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          borderColor: "primary.main",
          boxShadow: `0 4px 12px ${theme.palette.primary.main}15`,
        },
      }}
    >
      {/* Payment Header */}
      <Box
        sx={{
          p: 2,
          cursor: "pointer",
          bgcolor: expanded ? "background.neutral" : "transparent",
          borderRadius: "8px 8px 0 0",
        }}
        onClick={onToggle}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Payment sx={{ color: "primary.main" }} />
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Amount Paid
                </Typography>
                <Typography variant="h6" color="success.main">
                  KES {payment.amountPaid.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Receipt sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Receipt Number
                </Typography>
                <Typography variant="body2">
                  {payment.mpesaReceiptNumber}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarToday sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Payment Date
                </Typography>
                <Typography variant="body2">
                  {dayjs(payment.createdAt).format("DD MMM YYYY")}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/* Loan Details Collapse */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box sx={{ p: 2, bgcolor: "background.neutral" }}>
          <Typography variant="subtitle2" gutterBottom>
            Loan Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Stack spacing={1}>
                <Typography variant="caption" color="text.secondary">
                  Order Number
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  {payment.loan.orderNumber}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Principal Amount
                </Typography>
                <Typography variant="body2">
                  KES {payment.loan.amount.toLocaleString()}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Stack spacing={1}>
                <Typography variant="caption" color="text.secondary">
                  Due Date
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AccessTime
                    sx={{ fontSize: "1rem", color: "text.secondary" }}
                  />
                  <Typography variant="body2">
                    {dayjs(payment.loan.repaymentDate).format("DD MMM YYYY")}
                  </Typography>
                </Stack>
                {isOverdue && (
                  <Chip
                    size="small"
                    icon={<Warning sx={{ fontSize: "1rem" }} />}
                    label={`${payment.loan.overdueDays} days overdue`}
                    color="error"
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Stack spacing={1} alignItems="flex-end">
                <Typography variant="caption" color="text.secondary">
                  Remaining Balance
                </Typography>
                <Typography
                  variant="h6"
                  color={
                    payment.loan.remainingAmount > 0
                      ? "error.main"
                      : "success.main"
                  }
                >
                  KES {payment.loan.remainingAmount.toLocaleString()}
                </Typography>
                <Chip
                  size="small"
                  icon={
                    payment.loan.status === "Paid" ? (
                      <CheckCircle />
                    ) : (
                      <Warning />
                    )
                  }
                  label={payment.loan.status}
                  color={payment.loan.status === "Paid" ? "success" : "warning"}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Card>
  );
};

export default function Payments({ user }) {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [expandedPaymentId, setExpandedPaymentId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const offset = paginationModel.page * paginationModel.pageSize;
      const response = await fetch(
        `/api/payments?userId=${user.id}&offset=${offset}&limit=${paginationModel.pageSize}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch payments");
      const { data, total } = await response.json();
      setPayments(data);
      setTotalPayments(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [user.id, paginationModel]);

  const handleExpandClick = (paymentId) => {
    setExpandedPaymentId((prevId) => (prevId === paymentId ? null : paymentId));
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Payment History
      </Typography>

      {loading ? (
        <Box sx={{ display: "grid", placeItems: "center", height: "50vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={2}>
          {payments.map((payment) => (
            <PaymentCard
              key={payment.id}
              payment={payment}
              expanded={expandedPaymentId === payment.id}
              onToggle={() => handleExpandClick(payment.id)}
            />
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(totalPayments / paginationModel.pageSize)}
              page={paginationModel.page + 1}
              onChange={(event, value) =>
                setPaginationModel({ ...paginationModel, page: value - 1 })
              }
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
}
