import React, { useState } from "react";
import { Box, Button, Card } from "@mui/material";
import Agents from "./Agents";
import DCUsers from "./DCUsers";

export default function DCUsersHome() {
  const [selected, setSelected] = useState("Supervisors");

  return (
    <Box mt={9}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: { xs: 2, md: 1 } }}>
        <Button
          variant={selected === "Supervisors" ? "contained" : "outlined"}
          onClick={() => setSelected("Supervisors")}
          color="primary"
          sx={{ textTransform: "capitalize" }}
        >
          Supervisors
        </Button>
        <Button
          variant={selected === "Assignment" ? "contained" : "outlined"}
          onClick={() => setSelected("Assignment")}
          color="primary"
          sx={{ textTransform: "capitalize" }}
        >
          Agent Assignment
        </Button>
      </Box>
      {selected == "Supervisors" && <DCUsers />}
      {selected == "Assignment" && <Agents />}
    </Box>
  );
}
