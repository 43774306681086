import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Pagination,
  Chip,
  Grid,
  useTheme,
} from "@mui/material";
import {
  QuestionAnswer,
  CalendarToday,
  Category,
  Person,
  Phone,
  Email,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckCircle,
  Schedule,
} from "@mui/icons-material";
import dayjs from "dayjs";

const EnquiryCard = ({ enquiry, expanded, onToggle }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          borderColor: "primary.main",
          boxShadow: `0 4px 12px ${theme.palette.primary.main}15`,
        },
      }}
    >
      {/* Enquiry Header */}
      <Box
        sx={{
          p: 2,
          cursor: "pointer",
          bgcolor: expanded ? "background.neutral" : "transparent",
          borderRadius: "8px 8px 0 0",
        }}
        onClick={onToggle}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <QuestionAnswer sx={{ color: "primary.main" }} />
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Subject
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {enquiry.title}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Category sx={{ color: "text.secondary" }} />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Category
                </Typography>
                <Typography variant="body2">{enquiry.category}</Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1} alignItems="flex-end">
              <Chip
                size="small"
                icon={enquiry.status ? <CheckCircle /> : <Schedule />}
                label={enquiry.status ? "Resolved" : "Pending"}
                color={enquiry.status ? "success" : "warning"}
              />
              <Typography variant="caption" color="text.secondary">
                {dayjs(enquiry.createdAt).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/* Enquiry Details */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box sx={{ p: 2, bgcolor: "background.neutral" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Contact Information
                </Typography>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Person
                    sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                  />
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Name
                    </Typography>
                    <Typography variant="body2">{enquiry.name}</Typography>
                  </Box>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Phone sx={{ color: "text.secondary", fontSize: "1.2rem" }} />
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Phone
                    </Typography>
                    <Typography variant="body2">{enquiry.phone}</Typography>
                  </Box>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Email sx={{ color: "text.secondary", fontSize: "1.2rem" }} />
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Email
                    </Typography>
                    <Typography variant="body2">{enquiry?.user?.email}</Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Enquiry Details
                </Typography>

                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Message
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {enquiry.message}
                  </Typography>
                </Box>

                {enquiry.resolution && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Resolution
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 0.5,
                        p: 1.5,
                        bgcolor: "success.lighter",
                        borderRadius: 1,
                        color: "success.darker",
                      }}
                    >
                      {enquiry.resolution}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Card>
  );
};

export default function Support({ user }) {
  const [enquiries, setEnquiries] = useState([]);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [expandedEnquiryId, setExpandedEnquiryId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const offset = paginationModel.page * paginationModel.pageSize;
        const response = await fetch(
          `/api/enquiries?userId=${user.id}&offset=${offset}&limit=${paginationModel.pageSize}`
        );
        if (response.ok) {
          const data = await response.json();
          setEnquiries(data.data);
          setTotalEnquiries(data.total);
        }
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      }
    };

    fetchEnquiries();
  }, [user.id, paginationModel]);

  const handleExpandClick = (enquiryId) => {
    setExpandedEnquiryId((prevId) => (prevId === enquiryId ? null : enquiryId));
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Support Enquiries
      </Typography>

      <Stack spacing={2}>
        {enquiries.map((enquiry) => (
          <EnquiryCard
            key={enquiry.ID}
            enquiry={enquiry}
            expanded={expandedEnquiryId === enquiry.ID}
            onToggle={() => handleExpandClick(enquiry.ID)}
          />
        ))}

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(totalEnquiries / paginationModel.pageSize)}
            page={paginationModel.page + 1}
            onChange={(event, value) =>
              setPaginationModel({ ...paginationModel, page: value - 1 })
            }
          />
        </Box>
      </Stack>
    </Box>
  );
}
