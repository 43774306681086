import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Alert,
  Grid2,
  Button,
  Stack,
  Chip,
  Snackbar,
} from "@mui/material";
import dayjs from "dayjs";
import { ContactEmergency, Person } from "@mui/icons-material";

export default function UserDetails({ user, setRefresh, refresh }) {
  const [loading, setLoading] = useState(false);
  const [rloading, setRLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [overdue, setOverdue] = useState(0);
  const [defaulted, setDefaulted] = useState(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // "error", "warning", "info", "success"
  const [message, setMessage] = useState("");

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Stack>
        <Grid2 container spacing={2}>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <Person fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="h6" sx={{ margin: "auto" }} flexGrow={1}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Chip
                color={user?.isVerified ? "success" : "warning"}
                label={user?.isVerified ? "Premium" : "Regular"}
              />
            </Box>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.alternativePhone}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" color="warning" label={user?.gender} />
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" label={user?.dateOfBirth} />
            </Typography>
            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 4 }}
              variant="title"
              gutterBottom
            >
              Credit Score
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Manual Override: </strong>{" "}
              {user?.CreditScore.manualOverride ? "Yes" : "No"}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>
                Manual Override Limit:{" "}
              </strong>{" "}
              {(user?.CreditScore.manualCreditLimit - 0)?.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Credit Limit: </strong>{" "}
              {(user?.CreditScore.creditLimit - 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Credit Score: </strong>{" "}
              {user?.CreditScore.score.toFixed(0)}%
            </Typography>

            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 4 }}
              variant="title"
              gutterBottom
            >
              Work Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Employment Type: </strong>{" "}
              {user?.typeOfWork}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Monthly Income: </strong>{" "}
              {user?.monthlyIncome}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Pay Day: </strong>{" "}
              {user?.payDay}
            </Typography>
            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 2 }}
              variant="title"
              gutterBottom
            >
              Location Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>County: </strong>{" "}
              {user?.county}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>SubCounty: </strong>{" "}
              {user?.subcounty}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Ward: </strong> {user?.ward}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Detailed Address: </strong>{" "}
              {user?.detailedAddress}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Next of Kin</Typography>
            <Divider />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <ContactEmergency color="primary" fontSize="large" />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.relationship}
            </Typography>
            <Chip label="1" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.relationship}
            </Typography>
            <Chip label="2" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>
        </Grid2>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
