import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Alert,
  Grid2,
  Button,
  Stack,
  Chip,
  Snackbar,
  Card,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ContactEmergency,
  Person,
  Email,
  Phone,
  LocationOn,
  Work,
  AccountBalance,
  CalendarToday,
  CheckCircle,
  Warning,
  People,
  CardMembership,
} from "@mui/icons-material";
import CreditScore from "./CreditScore";

const InfoItem = ({ icon, label, value }) => {
  const Icon = icon;
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon sx={{ color: "text.secondary", fontSize: "1.2rem" }} />
      <Box>
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </Box>
    </Stack>
  );
};

export default function UserDetails({ loanData, user, setRefresh, refresh }) {
  const [loading, setLoading] = useState(false);
  const [rloading, setRLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [overdue, setOverdue] = useState(0);
  const [defaulted, setDefaulted] = useState(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user?.loans) {
      setOverdue(checkOverdue(user.loans));
      setDefaulted(checkDefaulted(user.loans));
    }
  }, [user]);

  const checkOverdue = (loans) => {
    return loans.some((loan) => {
      return loan.status === "Overdue" || loan.overdueDays > 0;
    });
  };

  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Defaulted" || daysSinceRepayment > 60;
    });
  };

  // Function to update loan status
  const updateLoanStatus = async (loanId, status) => {
    if (status == "Approved") setUpdating(true);
    else setRLoading(true);
    try {
      const response = await fetch(`/api/loans/${loanId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
        credentials: "include",
      });
      const data = await response.json();

      if (response.ok) {
        setSeverity("success");
        setMessage(data.message);
        setOpen(true);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 3000);
      } else {
        setSeverity("error");
        setMessage(data.message);
        setOpen(true);
      }
    } catch (error) {
      setSeverity("error");
      setMessage("Oops could not update status!");
      setOpen(true);
    } finally {
      setUpdating(false);
      setRLoading(false);
    }
  };

  const handleVerification = async () => {
    try {
      const response = await fetch(`/api/users/${user.id}/verify`, {
        method: "POST",
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to update verification status");
      setRefresh(!refresh);
    } catch (error) {
      setError("Failed to update verification status. Please try again.");
    }
  };

  if (loading || !user) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack spacing={3}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Personal Information</Typography>
          {user && (
            <Chip
              variant="outlined"
              startIcon={user.isVerified ? <Warning /> : <CheckCircle />}
              onClick={handleVerification}
              color={user.isVerified ? "warning" : "success"}
              label={user.isVerified ? "Premium" : "Regular"}
            />
          )}
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <InfoItem
                icon={Person}
                label="Full Name"
                value={`${user.firstName || ""} ${user.lastName || ""}`}
              />
              <InfoItem
                icon={Email}
                label="Gender"
                value={user.gender || "N/A"}
              />
              <InfoItem
                icon={Email}
                label="Date of Birth"
                value={user.dateOfBirth || "N/A"}
              />
              <InfoItem
                icon={Email}
                label="Age"
                value={
                  user.dateOfBirth
                    ? `${dayjs().diff(dayjs(user.dateOfBirth), "years")} years`
                    : "N/A"
                }
              />
              <InfoItem
                icon={Email}
                label="Email Address"
                value={user.email || "N/A"}
              />
              <InfoItem
                icon={CardMembership}
                label="ID Number"
                value={user.idCardNumber || "N/A"}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <InfoItem
                icon={Phone}
                label="MPESA Names"
                value={user.MPESA_Names || "N/A"}
              />
              <InfoItem
                icon={Phone}
                label="Phone Number"
                value={
                  user.phoneNumber?.replace("+", "").replace("254", "0") ||
                  "N/A"
                }
              />
              <InfoItem
                icon={Phone}
                label="Alternative Number"
                value={
                  user.alternativePhone?.replace("+", "").replace("254", "0") ||
                  "N/A"
                }
              />
              <InfoItem
                icon={LocationOn}
                label="Location"
                value={
                  user.county + ", " + user.subcounty + ", " + user.ward ||
                  "N/A"
                }
              />
              <InfoItem
                icon={LocationOn}
                label="Detailed Address"
                value={user.detailedAddress || "N/A"}
              />
              <InfoItem
                icon={Work}
                label="Monthly Income"
                value={`KES ${(user.monthlyIncome || 0).toLocaleString()}`}
              />
              <InfoItem
                icon={CalendarToday}
                label="Joined"
                value={
                  user.createdAt
                    ? dayjs(user.createdAt).format("DD MMM YYYY")
                    : "N/A"
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box>
        <Typography variant="h6" mb={2}>
          Next of Kin
        </Typography>
        <Grid container spacing={3}>
          {/* Emergency Contact 1 */}
          <Grid item xs={12} md={6}>
            <Card
              elevation={0}
              sx={{
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              <Stack spacing={2}>
                <Typography variant="subtitle1" color="text.secondary">
                  Primary Contact
                </Typography>
                <InfoItem
                  icon={Person}
                  label="Name"
                  value={user.emergencyContact1?.name || "N/A"}
                />
                <InfoItem
                  icon={Phone}
                  label="Phone"
                  value={
                    user.emergencyContact1?.contact
                      ?.replace("+", "")
                      .replace("254", "0") || "N/A"
                  }
                />
                <InfoItem
                  icon={People}
                  label="Relationship"
                  value={user.emergencyContact1?.relationship || "N/A"}
                />
              </Stack>
            </Card>
          </Grid>

          {/* Emergency Contact 2 */}
          <Grid item xs={12} md={6}>
            <Card
              elevation={0}
              sx={{
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              <Stack spacing={2}>
                <Typography variant="subtitle1" color="text.secondary">
                  Secondary Contact
                </Typography>
                <InfoItem
                  icon={Person}
                  label="Name"
                  value={user.emergencyContact2?.name || "N/A"}
                />
                <InfoItem
                  icon={Phone}
                  label="Phone"
                  value={
                    user.emergencyContact2?.contact
                      ?.replace("+", "")
                      .replace("254", "0") || "N/A"
                  }
                />
                <InfoItem
                  icon={People}
                  label="Relationship"
                  value={user.emergencyContact2?.relationship || "N/A"}
                />
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box>
        <Typography variant="h6" mb={2}>
          Credit Score
        </Typography>
        <CreditScore userId={user.id} />
      </Box>
    </Stack>
  );
}
