import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Stack,
  useTheme,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Search,
  AccountBalance,
  CalendarToday,
  Warning,
  CheckCircle,
  Refresh,
} from "@mui/icons-material";
import dayjs from "dayjs";

const FilterSection = ({ column, onColumnChange, searchValue, onSearch, onRefresh }) => {
  const theme = useTheme();
  
  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.neutral,
      }}
    >
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            Filter Loans
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={onRefresh}
            size="small"
          >
            Refresh
          </Button>
        </Box>

        <Stack direction="row" spacing={2}>
          <FormControl size="small" fullWidth>
            <InputLabel>Search by</InputLabel>
            <Select
              value={column}
              onChange={onColumnChange}
              label="Search by"
              startAdornment={
                <Box component="span" sx={{ pl: 1 }}>
                  <Search sx={{ color: "text.secondary", fontSize: "1.2rem" }} />
                </Box>
              }
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <Search sx={{ color: "text.secondary", mr: 1, fontSize: "1.2rem" }} />
              ),
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

const LoanStatusTabs = ({ value, onChange }) => {
  return (
    <Tabs 
      value={value} 
      onChange={onChange}
      sx={{
        mb: 3,
        '& .MuiTab-root': {
          textTransform: 'capitalize',
          minWidth: 100,
        },
      }}
    >
      <Tab 
        label="Active" 
        value="Active"
        icon={<CheckCircle sx={{ fontSize: '1.2rem' }} />}
        iconPosition="start"
      />
      <Tab 
        label="Extension" 
        value="Extension"
        icon={<CalendarToday sx={{ fontSize: '1.2rem' }} />}
        iconPosition="start"
      />
      <Tab 
        label="Paid" 
        value="Paid"
        icon={<AccountBalance sx={{ fontSize: '1.2rem' }} />}
        iconPosition="start"
      />
      <Tab 
        label="Overdue" 
        value="Overdue"
        icon={<Warning sx={{ fontSize: '1.2rem' }} />}
        iconPosition="start"
      />
      <Tab 
        label="Defaulted" 
        value="Defaulted"
        icon={<Warning sx={{ fontSize: '1.2rem' }} />}
        iconPosition="start"
      />
    </Tabs>
  );
};

export default function RMLoans() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Active");

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/loans?offset=${
        offset * 10
      }&includeUsers=true&includePayments=true&loanStatus=${
        selected == "Active" ? "Approved" : selected
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=true&loanStatus=${selected}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelected(newValue);
  };

  return (
    <Box mt={9}>
      <LoanStatusTabs value={selected} onChange={handleTabChange} />

      <Card elevation={0} sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider' }}>
        <Box sx={{ p: 3 }}>
          <FilterSection
            column={column}
            onColumnChange={(e) => setColumn(e.target.value)}
            searchValue={searchValue}
            onSearch={handleSearch}
            onRefresh={() => setRefresh(!refresh)}
          />

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Overdue</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Penalties</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <TableRow 
                        key={index}
                        hover
                        sx={{ 
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                        }}
                      >
                        <TableCell>
                          <Chip 
                            label={offset * 10 + index + 1}
                            size="small"
                            sx={{ minWidth: 40 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.User.firstName} {item.User.lastName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.User.phoneNumber.replace("+", "").replace("254", "0")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack spacing={1}>
                            <Typography variant="caption" color="text.secondary">
                              Issued: {dayjs(item.createdAt).format("DD MMM YYYY")}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              Repayment: {dayjs(item.repaymentDate).format("DD MMM YYYY")}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={item.overdueDays}
                            size="small"
                            color={item.overdueDays > 0 ? "error" : "success"}
                          />
                        </TableCell>
                        <TableCell>
                          <Stack spacing={1}>
                            <Typography variant="caption" color="text.secondary">
                              Principal: {item.amount.toLocaleString()}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              Interest: {item.interest.toLocaleString()}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {item.penalties.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {(item.paymentAmount - item.remainingAmount).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Chip
                            color={item.remainingAmount === 0 ? "success" : "warning"}
                            label={item.remainingAmount.toLocaleString()}
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography color="text.secondary">No loans found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box display="flex" justifyContent="center" mt={3}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
