import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CircularProgress,
  IconButton,
  Divider,
  Chip,
  Stack,
  Alert,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  Person,
  AccountBalance,
  Payments,
  CheckCircle,
  Warning,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UserDetails from "./Tabs/UserDetails";
import Loans from "./Tabs/Loans";
import PaymentsTab from "./Tabs/Payments";

const CustomerHeader = ({ user }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
      <Person sx={{ fontSize: "2rem", color: "primary.main" }} />
      <Box>
        <Typography variant="h6">
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ID: {user?.idCardNumber} | Phone:{" "}
          {user?.phoneNumber?.replace("+", "").replace("254", "0")}
        </Typography>
      </Box>
      <Box flexGrow={1} />
      <Chip
        icon={
          user?.isVerified ? (
            <CheckCircle sx={{ fontSize: "1rem" }} />
          ) : (
            <Warning sx={{ fontSize: "1rem" }} />
          )
        }
        label={user?.isVerified ? "Premium Customer" : "Regular Customer"}
        color={user?.isVerified ? "success" : "warning"}
      />
    </Stack>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ mt: 3 }}>
      {value === index && children}
    </Box>
  );
};

const TABS = [
  { label: "Details", icon: <Person sx={{ fontSize: "1.2rem" }} /> },
  { label: "Loans", icon: <AccountBalance sx={{ fontSize: "1.2rem" }} /> },
  { label: "Payments", icon: <Payments sx={{ fontSize: "1.2rem" }} /> },
];

export default function RMCustomerManagement() {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userId = window.location.pathname.split("/")[3];

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(
        `/api/users?id=${userId}&includeLoans=true&includeCreditScore=true&includePayments=true`,
        { credentials: "include" }
      );
      if (!response.ok) throw new Error("Failed to fetch user details");
      const data = await response.json();
      setUser(data?.data[0]);
    } catch (error) {
      setError("Failed to load customer details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId, refresh]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt={9}>
      <Card
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 3,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Stack spacing={2}>
          {/* Header */}
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton
              onClick={() => navigate("/rm/customers")}
              sx={{
                bgcolor: theme.palette.background.neutral,
                "&:hover": { bgcolor: theme.palette.action.hover },
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h5">Customer Management</Typography>
          </Box>

          <Divider />

          {error ? (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          ) : (
            <>
              {/* Customer Info */}
              <CustomerHeader user={user} />

              {/* Tabs */}
              <Tabs
                value={selectedTab}
                onChange={(e, value) => setSelectedTab(value)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  "& .MuiTab-root": {
                    textTransform: "none",
                    minHeight: 48,
                    px: 2,
                  },
                }}
              >
                {TABS.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    icon={tab.icon}
                    iconPosition="start"
                  />
                ))}
              </Tabs>

              {/* Tab Panels */}
              <TabPanel value={selectedTab} index={0}>
                <UserDetails
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <Loans user={user} />
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                <PaymentsTab user={user} />
              </TabPanel>
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
}
