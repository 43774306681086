import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  Stack,
  Chip,
  Divider,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  Check,
  Close,
  AccountBalance,
  CalendarToday,
  AttachMoney,
  Timer,
  Phone,
  Message,
  WhatsApp,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const Application = ({ loanData, user, refresh, setRefresh }) => {
  const [updating, setUpdating] = useState(false);
  const [rloading, setRLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const handleStatusUpdate = async (loanId, status) => {
    let confirmResult;

    if (status === "Approved") {
      confirmResult = await Swal.fire({
        title: "Approve Loan Application?",
        html: `
          <div style="text-align: left">
            <p><strong>Principal:</strong> KSH ${(
              loanData?.amount - 0
            )?.toLocaleString()}</p>
            <p><strong>Interest:</strong> KSH ${(
              loanData?.interest - 0
            )?.toLocaleString()}</p>
            <p><strong>Total Repayment:</strong> KSH ${(
              loanData?.paymentAmount - 0
            )?.toLocaleString()}</p>
            <p><strong>Due Date:</strong> ${dayjs(
              loanData?.repaymentDate
            ).format("YYYY MMM, D")}</p>
          </div>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return updateLoanStatus(loanId, status);
        },
      });
    } else if (status === "Rejected") {
      confirmResult = await Swal.fire({
        title: "Reject Loan Application",
        input: "textarea",
        inputLabel: "Reason for rejection",
        inputPlaceholder: "Enter the reason for rejection...",
        inputAttributes: {
          "aria-label": "Rejection reason",
        },
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Reject",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason for rejection!";
          }
        },
        preConfirm: (reason) => {
          return updateLoanStatus(loanId, status, reason);
        },
      });
    } else if (status === "Pending") {
      confirmResult = await Swal.fire({
        title: "Revoke Current Status?",
        text: "This will return the loan application to pending status",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffc107",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Yes, Revoke",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return updateLoanStatus(loanId, status);
        },
      });
    }
  };

  const updateLoanStatus = async (loanId, status, reason = null) => {
    if (status === "Approved") setUpdating(true);
    else setRLoading(true);

    try {
      const response = await fetch(`/api/loans/${loanId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status,
          rejectionReason: reason,
        }),
        credentials: "include",
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to update loan status");
      }

      await Swal.fire({
        title: getStatusTitle(status),
        text: data.message || getStatusMessage(status),
        icon: "success",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setRefresh(!refresh);
      return true;
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to update loan status",
        icon: "error",
      });
      return false;
    } finally {
      setUpdating(false);
      setRLoading(false);
    }
  };

  const getStatusTitle = (status) => {
    switch (status) {
      case "Approved":
        return "Loan Approved!";
      case "Rejected":
        return "Loan Rejected!";
      case "Pending":
        return "Status Revoked!";
      default:
        return "Updated!";
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "Approved":
        return "The loan application has been approved successfully.";
      case "Rejected":
        return "The loan application has been rejected.";
      case "Pending":
        return "The loan application has been returned to pending status.";
      default:
        return "The loan status has been updated.";
    }
  };

  const handleCall = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const handleSMS = (phone) => {
    window.location.href = `sms:${phone}`;
  };

  const handleWhatsApp = (phone) => {
    // Remove any non-numeric characters and ensure it starts with the country code
    const formattedPhone = phone.replace(/\D/g, "");
    window.open(`https://wa.me/${formattedPhone}`, "_blank");
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Loan Details Card */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Loan Details
            </Typography>
            <Stack spacing={2}>
              <Box display="flex" gap={1}>
                <Alert sx={{ flexGrow: 1 }}>Principal</Alert>
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{ m: "auto", flexGrow: 1 }}
                >
                  KSH{" "}
                  {(loanData?.amount - 0)?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Alert sx={{ flexGrow: 1 }}>Interest</Alert>
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{ m: "auto", flexGrow: 1 }}
                >
                  KSH{" "}
                  {(loanData?.interest - 0)?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Alert sx={{ flexGrow: 1 }}>Repayment</Alert>
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{ m: "auto", flexGrow: 1 }}
                >
                  KSH{" "}
                  {(loanData?.paymentAmount - 0)?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
              <Typography variant="body2">
                Loan Date:{" "}
                {dayjs(loanData?.createdAt).format("YYYY MMM, D - HH:mm:ss")}
              </Typography>
              <Typography variant="body2">
                Due Date: {dayjs(loanData?.repaymentDate).format("YYYY MMM, D")}
              </Typography>
            </Stack>
          </Paper>
        </Grid>

        {/* Status and Actions Card */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Application Status
            </Typography>
            <Box mb={2}>
              <Chip
                label={loanData?.status}
                color={
                  loanData?.status === "Pending"
                    ? "warning"
                    : loanData?.status === "Approved"
                    ? "success"
                    : "error"
                }
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Stack spacing={2}>
              {loanData?.status === "Pending" && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleStatusUpdate(loanData.id, "Approved")}
                    disabled={updating}
                    startIcon={<Check />}
                  >
                    {updating ? (
                      <>
                        <CircularProgress
                          size={20}
                          color="inherit"
                          sx={{ mr: 1 }}
                        />
                        Approving...
                      </>
                    ) : (
                      "Approve"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleStatusUpdate(loanData.id, "Rejected")}
                    disabled={rloading}
                    startIcon={<Close />}
                  >
                    {rloading ? (
                      <>
                        <CircularProgress
                          size={20}
                          color="inherit"
                          sx={{ mr: 1 }}
                        />
                        Rejecting...
                      </>
                    ) : (
                      "Reject"
                    )}
                  </Button>
                </>
              )}
              {(loanData?.status === "Rejected" ||
                loanData?.status === "Waiting") && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleStatusUpdate(loanData.id, "Pending")}
                  disabled={updating}
                >
                  {updating ? (
                    <>
                      <CircularProgress
                        size={20}
                        color="inherit"
                        sx={{ mr: 1 }}
                      />
                      Revoking...
                    </>
                  ) : (
                    "Revoke"
                  )}
                </Button>
              )}
            </Stack>
          </Paper>
        </Grid>

        {/* New Referrer Section */}
        {user?.referrer && (
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom color="primary">
                Referrer Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Box display="flex" gap={1}>
                      <Alert severity="info" sx={{ flexGrow: 1 }}>
                        Name
                      </Alert>
                      <Typography
                        textAlign="center"
                        variant="body1"
                        sx={{ m: "auto", flexGrow: 1, fontWeight: "medium" }}
                      >
                        {user?.referrer?.Name || "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Alert severity="info" sx={{ flexGrow: 1 }}>
                        Phone
                      </Alert>
                      <Typography
                        textAlign="center"
                        variant="body1"
                        sx={{ m: "auto", flexGrow: 1, fontWeight: "medium" }}
                      >
                        {user?.referrer?.Phone || "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Alert severity="info" sx={{ flexGrow: 1 }}>
                        Referral Code
                      </Alert>
                      <Typography
                        textAlign="center"
                        variant="body1"
                        sx={{ m: "auto", flexGrow: 1, fontWeight: "medium" }}
                      >
                        {user?.referrer?.ReferralCode || "N/A"}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      Contact Options
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<Phone />}
                      onClick={() => handleCall(user?.referrer?.Phone)}
                      fullWidth
                      sx={{
                        bgcolor: "success.main",
                        "&:hover": { bgcolor: "success.dark" },
                      }}
                    >
                      Call Referrer
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Message />}
                      onClick={() => handleSMS(user?.referrer?.Phone)}
                      fullWidth
                      sx={{
                        bgcolor: "info.main",
                        "&:hover": { bgcolor: "info.dark" },
                      }}
                    >
                      Send SMS
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<WhatsApp />}
                      onClick={() => handleWhatsApp(user?.referrer?.Phone)}
                      fullWidth
                      sx={{
                        bgcolor: "#25D366",
                        "&:hover": { bgcolor: "#128C7E" },
                      }}
                    >
                      WhatsApp
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Application;
