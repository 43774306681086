import React, { useEffect, useState } from "react";
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  TextField,
  LinearProgress,
  Divider,
  Stack,
  Grid,
  useTheme,
} from "@mui/material";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  ArrowForwardIos,
  MoneyOff,
  TrendingUp,
  Person,
  AccountBalance,
  Assessment,
  CalendarToday,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CountyMap from "../../Pages/Home/CountyMap";

const StatCard = ({ icon, label, value, current, last, color }) => {
  const growth = current && last ? ((current - last) / last) * 100 : 0;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        elevation={0}
        sx={{
          p: 2,
          height: "100%",
          borderRadius: 3,
          background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
          border: `1px solid ${color}20`,
          transition: "transform 0.2s",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: `0 8px 24px ${color}15`,
          },
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 40,
                width: 40,
                borderRadius: 2,
                bgcolor: `${color}15`,
                color: color,
              }}
            >
              {icon}
            </Box>
            <Typography variant="body2" color="text.secondary">
              {label}
            </Typography>
          </Box>

          <Typography variant="h5" fontWeight="bold" color={color}>
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>

          <Box display="flex" alignItems="center" gap={1}>
            <TrendingUp
              sx={{
                fontSize: 16,
                color: growth >= 0 ? "success.main" : "error.main",
                transform: growth >= 0 ? "none" : "rotate(180deg)",
              }}
            />
            <Typography
              variant="caption"
              color={growth >= 0 ? "success.main" : "error.main"}
            >
              {Math.abs(growth).toFixed(1)}% vs last month
            </Typography>
          </Box>
        </Stack>
      </Card>
    </Grid>
  );
};

const ChartCard = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="subtitle1" mb={1}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};

const PieChartCard = ({ label, total, l1, l2, v1, v2, color }) => {
  const data = [
    { name: l1, value: v1 },
    { name: l2, value: v2 },
  ];

  const COLORS = [color, `${color}80`];

  return (
    <Grid item xs={12} md={4}>
      <Card
        elevation={0}
        sx={{
          p: 3,
          height: "100%",
          borderRadius: 3,
          border: `1px solid ${color}20`,
          background: `linear-gradient(135deg, ${color}05 0%, #ffffff 100%)`,
        }}
      >
        <Stack spacing={0}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>

          <Typography variant="title" color={color} fontWeight="bold">
            {(v1 + v2).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>

          <Box sx={{ height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={75}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) => value.toLocaleString()}
                  contentStyle={{ borderRadius: 8 }}
                />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  formatter={(value, entry) => (
                    <span style={{ color: "text.secondary" }}>
                      {value}:{" "}
                      {((entry.payload.value / (v1 + v2)) * 100).toFixed(1)}%
                    </span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Stack>
      </Card>
    </Grid>
  );
};

export default function RMDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [dloading, setDLoading] = useState(false);
  const [overview, setOverview] = useState({
    customers: {
      total: 0,
      current: 0,
      last: 0,
    },
    payouts: {
      total: 0,
      current: 0,
      last: 0,
    },
    paidin: {
      total: 0,
      current: 0,
      last: 0,
    },
    cs_support: {
      total: 0,
      current: 0,
      last: 0,
      open: 0,
      resolved: 0,
    },
    com: {
      total: 0,
      current: 0,
      last: 0,
      email: 0,
      sms: 0,
    },
    lnp: {
      total: 0,
      current: 0,
      last: 0,
      approved: 0,
      rejected: 0,
    },
    ln_status: [],
  });
  const [customers, setCustomers] = useState({
    users: {
      total: 0,
      current: 0,
      last: 0,
    },
    regular: {
      total: 0,
      current: 0,
      last: 0,
    },
    premium: {
      total: 0,
      current: 3,
      last: 0,
    },
    dormant: {
      total: 0,
      current: 0,
      last: 0,
    },
    genderDistribution: [],
    employmentDistribution: [],
    countyDistribution: [],
    subCountyDistribution: [],
  });
  const [loans, setLoans] = useState({
    principal: {
      total: 0,
      current: 0,
      last: 0,
      repay: 0,
    },
    paid: {
      total: 0,
      current: 0,
      last: 0,
    },
    income: {
      total: 0,
      current: 0,
      last: 0,
    },
    defaulted: {
      total: 0,
      current: 0,
      last: 0,
    },
  });

  useEffect(() => {
    setLoading(true);
    fetchMetrics();
  }, []);

  useEffect(() => {
    if (metrics) {
      setDLoading(true);
      fetchMetrics();
    }
  }, [start, end]);

  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        `/api/loans/risk-management?start=${start}&end=${end}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setMetrics(data);
    } catch (error) {
    } finally {
      setLoading(false);
      setDLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/loans/overview", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setOverview(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/users/summary", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setCustomers(result);
      } catch (error) {
        console.error("Error fetching user summary:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/loans/loan-dashboard-summary", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setLoans(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9, px: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "primary.main",
              background: "linear-gradient(45deg, #2196F3, #3f51b5)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Risk Management Dashboard
          </Typography>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start Date"
              value={dayjs(start)}
              onChange={(date) => setStart(date.format("YYYY-MM-DD"))}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(end)}
              onChange={(date) => setEnd(date.format("YYYY-MM-DD"))}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <CalendarToday sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  },
                },
              }}
            />
          </Stack>
        </Stack>

        {/* Loading indicator */}
        {loading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        )}

        {/* Dashboard content */}
        {!loading && (
          <Grid container spacing={3}>
            {/* Stats Cards */}
            <StatCard
              icon={<AccountBalance />}
              label="Principal"
              value={loans.principal.total}
              current={loans.principal.current}
              last={loans.principal.last}
              color="#8B5DFF"
            />

            <StatCard
              icon={<MoneyOff />}
              label="Defaulted"
              value={loans.defaulted.total}
              current={loans.defaulted.current}
              last={loans.defaulted.last}
              color="#FF4842"
            />

            <StatCard
              icon={<TrendingUp />}
              label="Income"
              value={loans.income.total}
              current={loans.income.current}
              last={loans.income.last}
              color="#00AB55"
            />

            <StatCard
              icon={<Person />}
              label="Customers"
              value={customers.users.total}
              current={customers.users.current}
              last={customers.users.last}
              color="#1890FF"
            />

            {/* Activity Cards */}
            <PieChartCard
              label="Loan Processing"
              total={overview.lnp.total}
              l1="Approved"
              l2="Rejected"
              v1={parseInt(overview.lnp.approved)}
              v2={parseInt(overview.lnp.rejected)}
              color="#2196F3"
            />

            <PieChartCard
              label="Loan Summary"
              total={loans.principal.repay}
              l1="Paid"
              l2="Defaulted"
              v1={parseInt(loans.paid.total)}
              v2={parseInt(loans.defaulted.total)}
              color="#219B9D"
            />

            <PieChartCard
              label="Customers"
              total={customers.users.total}
              l1="Regular"
              l2="Premium"
              v1={parseInt(customers.regular.total)}
              v2={parseInt(customers.premium.total)}
              color="#219B9D"
            />

            {/* Charts */}
            <Grid item xs={12} md={6}>
              <ChartCard title="Loan Amount Distribution">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart
                    data={[
                      {
                        name: "Active",
                        Premium:
                          metrics?.verifiedActiveLoanAmount[0]?.total || 0,
                        Regular:
                          metrics?.notVerifiedActiveLoanAmount[0]?.total || 0,
                      },
                      {
                        name: "Extended",
                        Premium:
                          metrics?.verifiedPendingLoanAmount[0]?.total || 0,
                        Regular:
                          metrics?.notVerifiedPendingLoanAmount[0]?.total || 0,
                      },
                      {
                        name: "Overdue",
                        Premium:
                          metrics?.verifiedRejectedLoanAmount[0]?.total || 0,
                        Regular:
                          metrics?.notVerifiedRejectedLoanAmount[0]?.total || 0,
                      },
                    ]}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      tickFormatter={(value) => {
                        if (value >= 1000000)
                          return `${(value / 1000000).toFixed(1)}M`;
                        if (value >= 1000)
                          return `${(value / 1000).toFixed(1)}K`;
                        return value;
                      }}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Premium" fill="#8B5DFF" />
                    <Bar dataKey="Regular" fill="#FF8000" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <ChartCard title="Loan Count Distribution">
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart
                    data={[
                      {
                        name: "Active",
                        Premium:
                          metrics?.verifiedActiveLoanAmount[0]?.loanCount || 0,
                        Regular:
                          metrics?.notVerifiedActiveLoanAmount[0]?.loanCount ||
                          0,
                      },
                      {
                        name: "Extended",
                        Premium:
                          metrics?.verifiedPendingLoanAmount[0]?.loanCount || 0,
                        Regular:
                          metrics?.notVerifiedPendingLoanAmount[0]?.loanCount ||
                          0,
                      },
                      {
                        name: "Overdue",
                        Premium:
                          metrics?.verifiedRejectedLoanAmount[0]?.loanCount ||
                          0,
                        Regular:
                          metrics?.notVerifiedRejectedLoanAmount[0]
                            ?.loanCount || 0,
                      },
                    ]}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Premium" stroke="#8B5DFF" />
                    <Line type="monotone" dataKey="Regular" stroke="#FF8000" />
                  </LineChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <CountyMap
                url="/api/counties/user-paid-loans"
                height={300}
                title="Paid Loans"
                color="#410445"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CountyMap
                url="/api/counties/user-approved-loans"
                height={300}
                title="Active Loans"
                color="#410445"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CountyMap
                url="/api/counties/user-rejected-loans"
                height={300}
                title="Rejected Loans"
                color="#BF3131"
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </LocalizationProvider>
  );
}
